import Axios from "axios";
import { BASE_URL } from "../../configs/constants";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import * as types from "../types";

export function getStatistic(Comuna: any, Categoria: any): AppThunk {
  return async (dispatch) => {
    dispatch({
      type: types.STATISTIC_GET_INITIAL,
    });

    try {
      if (Categoria.value && Comuna.value) {
        dispatch({
          type: types.STATISTIC_GET_SUBMITTING,
        });
        const response = await Axios.get(
          BASE_URL +
            "app/graficoMasOfertados/" +
            Comuna.value +
            "/" +
            Categoria.value
        );
        dispatch({
          type: types.STATISTIC_BUDGET_GET_SUCCESS,
          payload: response.data,
        });
      } else {
        dispatch({
          type: types.STATISTIC_GET_FORM_SUBMITTING,
        });
        const response = await Axios.get(
          BASE_URL + "app/graficoMasDemandados/" + Comuna.value
        );
        dispatch({
          type: types.STATISTIC_CATEGORIES_GET_SUCCESS,
          payload: response.data,
        });
      }
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.STATISTIC_GET_FAILURE,
        payload: error,
      });
    }
  };
}

export const getStatisticInitial = (): Action => ({
  type: types.STATISTIC_GET_INITIAL,
});

export function staticClean(): AppThunk {
  return async (dispatch) => {
    dispatch({
      type: types.STATISTIC_GET_INITIAL,
    });
  };
}

import * as types from "../types";
import { Action } from "../../models/action";
import { FormState } from "../../models/form_state";

export interface SuscriptionState {
  suscriptions: any[];
  state: FormState;
  error?: string;
  lastDoc?: any;
  totalDocs: number;
  add: {
    state: FormState;
    error?: string;
  };
}

const initialState: SuscriptionState = {
  suscriptions: [],
  state: FormState.Initial,
  totalDocs: 0,
  add: {
    state: FormState.Initial,
  },
};

export default function reducer(
  state = initialState,
  action: Action
): SuscriptionState {
  switch (action.type) {
    case types.SUSCRIPTION_GET_INITIAL:
      return initialState;
    case types.SUSCRIPTION_GET_SUBMITTING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.SUSCRIPTION_GET_SUCCESS:
      return {
        ...state,
        suscriptions: action.payload.suscription,
        state: FormState.Success,
        lastDoc: action.payload.lastDoc,
        totalDocs: action.payload.totalDocs,
      };
    case types.SUSCRIPTION_GET_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };
      case types.SUSCRIPTION_SUSCRIBE_USER_INITIAL:
      return {
        ...state,
        add: {
          state: FormState.Initial,
          error: undefined,
        },
      };
    case types.SUSCRIPTION_SUSCRIBE_USER_SUBMITTING:
      return {
        ...state,
        add: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.SUSCRIPTION_SUSCRIBE_USER_SUCCESS:
      return {
        ...state,
        add: {
          state: FormState.Success,
        },
      };
    case types.SUSCRIPTION_SUSCRIBE_USER_FAILURE:
      return {
        ...state,
        add: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    default:
      return state;
  }
}

import React from "react";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { PlaintiffHeader } from "../components/PlaintiffHeader";

interface Props {}

export const PlaintiffLayout: React.FC<Props> = ({ children }) => {
  return (
    <div>
      <PlaintiffHeader />
      <Header />
      <div className="h-32"/>
      {children}
      <Footer />
    </div>
  );
};

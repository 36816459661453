import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "tippy.js/animations/shift-toward.css";
import "tippy.js/dist/tippy.css";
import BarsIcon from "../assets/icons/BarsIcon";
import UserIcon from "../assets/icons/UserIcon";
import { AuthState } from "../redux/reducers/auth.reducer";
import { RootState } from "../redux/reducers/root.reducer";
import { IconButton } from "./IconButton";
import { Menu } from "./Menu";

export const Header = () => {
  const [open, setOpen] = useState(false);

  const { user } = useSelector<RootState, AuthState>(
    (state) => state.authReducer
  );

  const handleOpenMenu = () => {
    setOpen((toggle) => !toggle);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  return (
    <header className="flex w-full h-20 items-center  justify-between sm:px-14 px-2 fixed top-0 bg-white z-10">
      <Link
        to={
          user
            ? user.TipoUsuario === "Demandante"
              ? "/demandante/solicitudesRealizadas" 
              : "/solicitudesActivas"
            : "/"
        }
      >
        <div className="w-48 h-16 bg-logo bg-no-repeat" />
      </Link>
      <div className="flex flex-row">
        <IconButton
          onClick={() => {
            if (!open) {
              handleOpenMenu();
            } else {
              handleCloseMenu();
            }
          }}
        >
          <UserIcon className="h-12 w-12" />
        </IconButton>
        <IconButton
          onClick={() => {
            if (!open) {
              handleOpenMenu();
            } else {
              handleCloseMenu();
            }
          }}
        >
          <BarsIcon className="h-12 w-12" />
        </IconButton>
        <Menu
          isVisible={open}
          onClose={handleCloseMenu}
          title={"ingreso Usuario"}
        />
        
      </div>
      
    </header>
    
  );
};

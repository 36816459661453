import clsx from "clsx";
import { useFormik } from "formik";
import React, { CSSProperties, useEffect, useRef } from "react";
import { Location } from "../models/PlacesDetailsResponse";

interface Props {
  setCoords: React.Dispatch<React.SetStateAction<Location | undefined>>;
}

export const FilterOffererGeolocation: React.FC<Props> = ({ setCoords }) => {
  /* const { state: RequestsState } = useSelector<RootState, RequestsState>(
    (state) => state.requestReducer
  ); */

  const { handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      Ubicacion: "",
    },
    onSubmit: () => {},
  });

  return (
<div className="lg:absolute lg:mt-10 lg:right-0 lg:left-0 lg:m-auto lg:w-5/12  w-full mt-10 right-0 left-0 absolute px-24 " style={{zIndex: 2}}>  
 <form onSubmit={handleSubmit}>
        <AutocompletePlaces
          id="Direccion"
          placeholder="Ingrese dirección"
          onChange={(place) => {
            if (place) {
              const location = {
                lat: place.geometry!.location!.lat(),
                lng: place.geometry!.location!.lng(),
              };
              setCoords(location);
              setFieldValue("Ubicacion", location, false);
            }
          }}
        />
      </form>
      {/* <div className="ml-5">
          <Button type="submit" text="Buscar" color="secondary" />
        </div> */}
    </div>
  );
};

interface AutoProps {
  helperText?: any;
  placeholder?: string;
  helperTextColor?: string;
  helperTextClassName?: string;
  divClassName?: string;
  divStyle?: CSSProperties;
  value?: string;
  label?: string;
  error?: boolean;
  outlined?: boolean;
  isLoading?: boolean;
  className?: string;
  id?: string;
  onChange?: (place: google.maps.places.PlaceResult) => void;
}

function AutocompletePlaces({
  helperText,
  helperTextClassName,
  divStyle,
  divClassName,
  className,
  label,
  id,
  value,
  error,
  outlined = true,
  onChange,
}: AutoProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleOusideClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (window.google) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current!
      );
      autocomplete.setOptions({
        componentRestrictions: { country: "CL" },
      });
      autocomplete.addListener("place_changed", () => {
        if (onChange) {
          onChange(autocomplete.getPlace());
        }
      });
    }
    // eslint-disable-next-line
  }, [window.google]);

  return (
    <div className={divClassName} style={divStyle}>
      {label && (
        <label
          className="pl-4 pb-2 font-semibold text-deepBlue text-lg "
          htmlFor={id ? id : `input-${label}`}
        >
          {label}
        </label>
      )}
      <div
        className={clsx("rounded-full bg-white h-12 relative flex cursor-text")}
        onClick={handleOusideClick}
      >
        <input
          id={id ? id : `input-${label}`}
          ref={inputRef}
          className={clsx(
            `w-full px-4 rounded-full h-full focus:border-secondary hover:border-secondary hover:bg-secondary-light hover:bg-opacity-10 `,
            className,
            outlined
              ? "border-solid border-1.5 border-primary outline-none"
              : "border-none border-0 outline-none"
          )}
          type="text"
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              event.stopPropagation();
            }
          }}
        />
      </div>
      {helperText && error && (
        <span
          className={clsx("ml-5 font-semibold", helperTextClassName)}
          style={{ color: helperText }}
        >
          {helperText}
        </span>
      )}
    </div>
  );
}

import { FormState } from "../../models/form_state";
import * as types from "../types";

export interface AuthState {
  user?: any;
  authUser?: any;
  error?: string;
  state: FormState;
  edit: {
    state: FormState;
    emailState: FormState;
    emailError?: FormState;
    passwordState: FormState;
    passwordError?: FormState;
    error?: string;
    selectedUser?: any;
  };
  login: {
    state: FormState;
    error?: string;
  };
  offererRegister: {
    state: FormState;
    error?: string;
  };
  plaintiffRegister: {
    state: FormState;
    error?: string;
  };
  resetPassword: {
    state: FormState;
    error?: string;
  };
}
// se debe volver el estado del edit a initial siempre y cuando se salga de la pantalla.
const initialState = {
  state: FormState.Initial,
  edit: {
    state: FormState.Initial,
    emailState: FormState.Initial,
    passwordState: FormState.Initial,
  },
  offererRegister: {
    state: FormState.Initial,
  },
  plaintiffRegister: {
    state: FormState.Initial,
  },
  login: {
    state: FormState.Initial,
  },
  resetPassword: {
    state: FormState.Initial,
  },
} as AuthState;

export default function authReducer(
  state = initialState,
  actions: any
): AuthState {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        state: FormState.Success,
        authUser: actions.payload,
        login: {
          state: FormState.Success,
          error: undefined,
        },
      };
    case types.AUTH_SIGN_IN_FAILURE:
      return {
        ...state,
        login: {
          state: FormState.Failure,
          error: actions.payload,
        },
      };
    case types.AUTH_SIGN_IN_REQUEST:
      return {
        ...state,
        login: {
          state: FormState.Submitting,
          error: undefined,
        },
      };

    case types.AUTH_SIGN_OUT:
      return initialState;

    case types.AUTH_STATE_SUCCESS:
      return {
        ...state,
        user: actions.payload,
        state: FormState.Success,
      };

    case types.AUTH_STATE_REQUEST:
      return {
        ...state,
        error: undefined,
        state: FormState.Submitting,
      };

    case types.AUTH_STATE_FAILURE:
      return {
        ...state,
        user: undefined,
        state: FormState.Failure,
        error: actions.payload,
      };

    case types.REGISTER_OFFERER_FAILURE:
      return {
        ...state,
        offererRegister: {
          ...state.offererRegister,
          state: FormState.Failure,
          error: actions.payload,
        },
      };
    case types.REGISTER_OFFERER_SUCCESS:
      return {
        ...state,
        offererRegister: {
          ...state.offererRegister,
          state: FormState.Success,
        },
      };
    case types.REGISTER_OFFERER_SUBMITTING:
      return {
        ...state,
        offererRegister: {
          ...state.offererRegister,
          state: FormState.Submitting,
        },
      };
    case types.REGISTER_OFFERER_INITIAL:
      return {
        ...state,
        offererRegister: {
          ...initialState.offererRegister,
        },
      };
    case types.REGISTER_PLAINTIFF_FAILURE:
      return {
        ...state,
        plaintiffRegister: {
          ...state.plaintiffRegister,
          state: FormState.Failure,
          error: actions.payload,
        },
      };
    case types.REGISTER_PLAINTIFF_SUCCESS:
      return {
        ...state,
        plaintiffRegister: {
          ...state.plaintiffRegister,
          state: FormState.Success,
        },
      };
    case types.REGISTER_PLAINTIFF_SUBMITTING:
      return {
        ...state,
        plaintiffRegister: {
          ...state.plaintiffRegister,
          state: FormState.Submitting,
        },
      };
    case types.REGISTER_PLAINTIFF_INITIAL:
      return {
        ...state,
        plaintiffRegister: {
          ...initialState.plaintiffRegister,
        },
      };
    case types.REGISTER_UPDATE_ONE_FAILURE:
      return {
        ...state,
        edit: {
          state: FormState.Failure,
          emailState: FormState.Initial,
          passwordState: FormState.Initial,
          error: actions.payload,
        },
      };
    case types.REGISTER_UPDATE_ONE_SUCCESS:
      return {
        ...state,
        edit: {
          state: FormState.Success,
          emailState: FormState.Initial,
          passwordState: FormState.Initial,
        },
      };
    case types.REGISTER_UPDATE_ONE_SUBMMITING:
      return {
        ...state,
        edit: {
          state: FormState.Submitting,
          emailState: FormState.Initial,
          passwordState: FormState.Initial,
        },
      };
    //
    case types.REGISTER_UPDATE_EMAIL_FAILURE:
      return {
        ...state,
        edit: {
          state: FormState.Initial,
          emailState: FormState.Failure,
          passwordState: FormState.Initial,
          emailError: actions.payload,
        },
      };
    case types.REGISTER_UPDATE_EMAIL_SUCCESS:
      return {
        ...state,
        edit: {
          state: FormState.Initial,
          emailState: FormState.Success,
          passwordState: FormState.Initial,
        },
      };
    case types.REGISTER_UPDATE_EMAIL_SUBMMITING:
      return {
        ...state,
        edit: {
          state: FormState.Initial,
          emailState: FormState.Submitting,
          passwordState: FormState.Initial,
          emailError: undefined,
        },
      };
    //
    case types.REGISTER_UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        edit: {
          state: FormState.Initial,
          emailState: FormState.Initial,
          passwordState: FormState.Failure,
          passwordError: actions.payload,
        },
      };
    case types.REGISTER_UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        edit: {
          state: FormState.Initial,
          emailState: FormState.Initial,
          passwordState: FormState.Success,
        },
      };
    case types.REGISTER_UPDATE_PASSWORD_SUBMMITING:
      return {
        ...state,
        edit: {
          state: FormState.Initial,
          emailState: FormState.Initial,
          passwordState: FormState.Submitting,
          passwordError: undefined,
        },
      };
    case types.USERS_SET_SELECTED:
      return {
        ...state,
        edit: {
          ...state.edit,
          selectedUser: actions.payload,
        },
      };
    case types.AUTH_RESET_PASSWORD_INITIAL:
      return {
        ...state,
        resetPassword: {
          state: FormState.Initial,
        },
      };
    case types.AUTH_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPassword: {
          state: FormState.Submitting,
        },
      };
    case types.AUTH_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetPassword: {
          state: FormState.Success,
        },
      };
    case types.AUTH_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetPassword: {
          state: FormState.Failure,
          error: actions.payload,
        },
      };
    default:
      return state;
  }
}

import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Button } from "../components/Button";
import { TextField } from "../components/TextField";
import { FormState } from "../models/form_state";
import { updateEmailPlantiff } from "../redux/actions/auth.actions";
import { AuthState } from "../redux/reducers/auth.reducer";
import { RootState } from "../redux/reducers/root.reducer";
import { ModalMessage } from "./ModalMessage";
import { ModalMessageError } from "./ModalMessageError";

interface Props {
  helperText?: any;
  placeholder?: string;
  icon?: React.ReactNode;
  helperTextColor?: string;
  helperTextClassName?: string;
  divClassName?: string;
  label?: string;
  error?: boolean;
  outlined?: boolean;
  multiline?: boolean;
}

export const EditEmail: React.FC<Props> = ({
  helperText,
  helperTextColor,
  icon,
  helperTextClassName,
  divClassName,
  label,
  error,
  outlined = true,
  multiline = false,
  ...others
}) => {
  const dispatch = useDispatch();
  const [showModalEdit, setShowModalEdit] = React.useState(false);
  const [showModalError, setShowModalError] = React.useState(false);
  const [editable, setEditable] = useState(false);
  const { user, edit } = useSelector<RootState, AuthState>(
    (state) => state.authReducer
  );
  const {
    handleSubmit,
    values,
    handleChange,
    touched,
    errors,
    setValues,
  } = useFormik({
    initialValues: {
      Email: user?.Email,
    },
    onSubmit: (values) => {
      dispatch(updateEmailPlantiff(values.Email));
    },
    validationSchema: yup.object({
      Email: yup.string().email("Email no válido").required("Email requerido"),
    }),
  });

  useEffect(() => {
    if (edit.emailState === FormState.Success) {
      setShowModalEdit(true);
      setEditable(false);
      setTimeout(() => {
        setShowModalEdit(false);
       }, 1000);
    }
    if (edit.emailState === FormState.Failure) {
      setShowModalError(true);
      setTimeout(() => {
        setShowModalError(false);
       }, 5000);
    }
    return () => {
      edit.emailState = FormState.Initial;
    };
    // eslint-disable-next-line
  }, [edit.emailState]);
  return (
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 justify-between px-6 md:px-0 pt-6 pb-6">
          <TextField
            disabled={!editable}
            label="Email"
            placeholder="Ingre tu nuevo Email"
            id="Email"
            value={values.Email}
            onChange={handleChange}
            helperText={touched.Email && errors.Email}
            error={touched.Email && Boolean(errors.Email)}
            required
          />
          <div>
            <div className="flex justify-start ">
              {editable === true ? (
                ""
              ) : (
                <Button
                  style={{ marginRight: 3 }}
                  onClick={() => setEditable(true)}
                  text="Editar Email"
                  color="secondary"
                  className="mt-7 w-60 "
                />
              )}
              {editable === true ? (
                <>
                  <Button
                    style={{ marginRight: 3 }}
                    onClick={() => {
                      setEditable(false);
                      setValues(user.Email);
                    }}
                    text="Cancelar"
                    color="secondary"
                    className="mt-7 w-60 "
                  />
                  <Button
                    type="submit"
                    text="Guardar"
                    color="secondary"
                    className="mt-7 w-60 "
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </form>
      <ModalMessage
        title="Email actualizado Correctamente"
        open={showModalEdit}
        onClose={() => setShowModalEdit(false)}
      />
      <ModalMessageError
        title="Ha ocurrido al Actualizar Correo, Recargue e intentente nuevamente "
        open={showModalError}
        onClose={() => setShowModalError(false)}
      />
    </React.Fragment>
  );
};

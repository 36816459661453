import React from "react";
import { Link } from "react-router-dom";
import FacebookIcon from "../assets/icons/FacebookIcon";
import InstagramIcon from "../assets/icons/InstagramIcon";
import LogoDemandappIcon from "../assets/icons/LogoDemandappIcon";
import TwitterIcon from "../assets/icons/TwitterIcon";
import YoutubeIcon from "../assets/icons/YoutubeIcon";

export const Footer = () => {
  return (
    <footer className="bg-white mb-10 flex flex-col">
      <div className="sm:h-20 h-32 w-full sm:max-w-screen-sm">
        <div
          className="bg-abstract sm:h-20 h-32 w-full bg-cover absolute"
          style={{
            backgroundPositionY: "60%",
            filter:
              "invert(11%) sepia(93%) saturate(3873%) hue-rotate(229deg) brightness(92%) contrast(92%)",
          }}
        />
        <div className="absolute flex sm:flex-row flex-col items-center justify-center text-white w-full sm:h-20 h-32">
          <Link className="text-sm font-semibold" to={"/quienesSomos"}>
            Quiénes somos
          </Link>
          <div className="h-1 w-1 bg-white rounded-full sm:mx-3 my-1 sm:my-0" />
          <a
            target={"_blank"}
            href="https://firebasestorage.googleapis.com/v0/b/demandate-77297.appspot.com/o/TerminosYCondiciones%2FT%C3%A9rminos_y_Condiciones.pdf?alt=media&token=11de0f3e-30cc-4c30-9d7b-72cdcfa4c4b8"
            className="text-sm font-semibold"
          >
            Términos y Condiciones
          </a>
          <div className="h-1 w-1 bg-white rounded-full sm:mx-3 my-1 sm:my-0" />
          <Link className="text-sm font-semibold" to={"/contact"}>
            Contáctanos
          </Link>
        </div>
      </div>
      <div className="flex flex-col items-center mt-6">
        <div className="rounded-full bg-deepBlue h-16 w-16 flex items-center justify-center">
          <LogoDemandappIcon height={50} width={50} />
        </div>
        <div className="flex mt-6">
          <div className="mr-5">
            <FacebookIcon height={32} width={32} />
          </div>
          <div className="mr-5">
            <InstagramIcon height={32} width={32} />
          </div>
          <div className="mr-5">
            <TwitterIcon height={32} width={32} />
          </div>
          <div className="">
            <YoutubeIcon height={32} width={32} />
          </div>
        </div>
      </div>
      <div className="w-full bg-gray-300 my-6" style={{ height: 1 }} />
      <p className="text-xs text-gray-400 text-center">
        Todos los derechos reservados © 2024 - www.demandapp.cl - v1.1.9
      </p>
    </footer>
  );
};

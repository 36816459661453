import * as types from "../types";
import { Action } from "../../models/action";
import { FormState } from "../../models/form_state";
import { PlaintiffRequest } from "../../models/PlaintiffRequest";
import { PlaintiffOffer } from "../../models/PlaintiffOffer";

export interface RequestsState {
  state: FormState;
  requests: PlaintiffRequest[];
  requestsPremium: PlaintiffRequest[];
  order?: string;
  orderBy?: string;
  error?: string;
  totalDocs: number;
  lastDoc?: any;
  firstDoc?: any;
  add: {
    error?: string;
    state: FormState;
  };
  edit: {
    state: FormState;
    selectedSolicitud?: any;
    error?: string;
  };
  delete: {
    state: FormState;
    error?: string;
  };
  selected?: PlaintiffRequest;
  /**
   * Objeto del estado de la oferta aeptada
   */
  offer: {
    state: FormState;
    error?: string;
  };
  acceptOffer: {
    state: FormState;
    error?: string;
  };
  deleteOffer: {
    state: FormState;
    error?: string;
  };
  /**
   * Objete del estado de la oferta al ingresar en una solicitud
   */
  searchOffer: {
    state: FormState;
    error?: string;
    searchedOffer?: PlaintiffOffer | null;
  };
}
const initialState = {
  state: FormState.Initial,
  totalDocs: 0,
  requests: [],
  requestsPremium: [],
  add: {
    state: FormState.Initial,
  },
  delete: {
    state: FormState.Initial,
  },
  offer: {
    state: FormState.Initial,
  },
  acceptOffer: {
    state: FormState.Initial,
  },
  deleteOffer: {
    state: FormState.Initial,
  },
  edit: { selectedRequest: [], state: FormState.Initial },
  searchOffer: {
    state: FormState.Initial,
  },
} as RequestsState;

function requestReducer(
  state = initialState,
  action: Action<any>
): RequestsState {
  switch (action.type) {
    case types.REQUESTS_INITIAL:
      return initialState;
    case types.REQUESTS_SUBMMITING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.REQUESTS_SUCCESS:
      return {
        ...state,
        state: FormState.Success,
        requests: action.payload.requests,
        lastDoc: action.payload.lastDoc,
        firstDoc: action.payload.firstDoc,
        totalDocs: action.payload.totalDocs,
        order: action.payload.order,
        orderBy: action.payload.orderBy,
      };
    case types.REQUESTS_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };
      case types.REQUESTS_GET_PREMIUM_SUBMMITING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.REQUESTS_GET_PREMIUM_SUCCESS:
      return {
        ...state,
        state: FormState.Success,
        requestsPremium: action.payload.requestsPremium,
        lastDoc: action.payload.lastDoc,
        firstDoc: action.payload.firstDoc,
      };
    case types.REQUESTS_GET_PREMIUM_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };
    case types.REQUESTS_ADD_INITIAL:
      return {
        ...state,
        add: initialState.add,
      };
    case types.REQUESTS_ADD_SUBMMITING:
      return {
        ...state,
        add: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.REQUESTS_ADD_SUCCESS:
      return {
        ...state,
        add: {
          state: FormState.Success,
        },
      };
    case types.REQUESTS_ADD_FAILURE:
      return {
        ...state,
        add: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.REQUESTS_DELETE_SUBMMITING:
      return {
        ...state,
        delete: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.REQUESTS_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          state: FormState.Success,
        },
        requests: state.requests.filter((x) => x.id !== action.payload.id),
      };
    case types.REQUESTS_DELETE_FAILURE:
      return {
        ...state,
        delete: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.REQUESTS_GET_ONE_SUBMMITING:
      return {
        ...state,
        edit: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.REQUESTS_GET_ONE_SUCCESS:
      return {
        ...state,
        edit: {
          state: FormState.Success,
        },
        selected: action.payload,
      };
    case types.REQUESTS_GET_ONE_FAILURE:
      return {
        ...state,
        edit: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.REQUESTS_SET_TOTAL_DOCS:
      return {
        ...state,
        totalDocs: action.payload,
      };

    case types.REQUESTS_GET_OFFER_SUBMMITING:
      return {
        ...state,
        offer: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.REQUESTS_GET_OFFER_SUCCESS:
      return {
        ...state,
        offer: {
          state: FormState.Success,
        },
        selected: {
          ...state.selected!,
          OfertaAceptada: action.payload,
        },
      };
    case types.REQUESTS_GET_OFFER_FAILURE:
      return {
        ...state,
        offer: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.REQUEST_ACCEPT_OFFER_SUCCESS:
      return {
        ...state,
        acceptOffer: {
          state: FormState.Success,
        },
        offer: {
          state: FormState.Success,
        },
        selected: action.payload,
      };
    case types.REQUEST_ACCEPT_OFFER_SUBMITTING:
      return {
        ...state,
        offer: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.REQUEST_ACCEPT_OFFER_FAILURE:
      return {
        ...state,
        offer: {
          state: FormState.Failure,
          error: action.payload,
        },
      };

    case types.REQUEST_REJECT_OFFER_SUCCESS:
      return {
        ...state,
        deleteOffer: {
          state: FormState.Success,
        },
        selected: action.payload,
      };
    case types.REQUEST_REJECT_OFFER_SUBMITTING:
      return {
        ...state,
        deleteOffer: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.REQUEST_REJECT_OFFER_FAILURE:
      return {
        ...state,
        deleteOffer: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.REQUEST_REJECT_OFFER_INITIAL:
      return {
        ...state,
        deleteOffer: {
          state: FormState.Initial,
          error: undefined,
        },
      };

    case types.REQUEST_EDIT_INITIAL:
      return {
        ...state,
        edit: {
          ...initialState.edit,
        },
      };
    case types.REQUEST_EDIT_SUBMITTING:
      return {
        ...state,
        edit: {
          ...state.edit,
          state: FormState.Submitting,
        },
      };

    case types.REQUEST_EDIT_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          state: FormState.Success,
        },
      };
    case types.REQUEST_EDIT_FAILURE:
      return {
        ...state,
        edit: {
          ...state.edit,
          state: FormState.Failure,
          error: action.payload,
        },
      };

    case types.REQUEST_OFFERED_SUBMITTING:
      return {
        ...state,
        searchOffer: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.REQUEST_OFFERED_SUCCESS:
      return {
        ...state,
        searchOffer: {
          state: FormState.Success,
          searchedOffer: action.payload,
        },
      };
    case types.REQUEST_OFFERED_FAILURE:
      return {
        ...state,
        searchOffer: {
          state: FormState.Failure,
          error: action.payload,
        },
      };

    default:
      return state;
  }
}

export default requestReducer;

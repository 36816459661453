import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "../components/Button";
import { Modal } from "../components/Modal";
import { sendMailVerify } from "../redux/actions/auth.actions";

export const CodeVerify = () => {
  const [user, setUser] = useState<User | null>(null);
  const [emailVerified, setEmailVerified] = useState(false);
  const [isMounted, setIsMounted] = useState(true); // Agrega un indicador de montaje
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const auth = getAuth();
    setIsMounted(true); // Establece que el componente está montado

    // Listener para detectar cambios en el estado de autenticación
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (isMounted) {
        setUser(user);
        if (user) {
          setEmailVerified(user.emailVerified);
        }
      }
    });

    // Verificar periódicamente si el email ha sido verificado
    const checkEmailVerification = setInterval(() => {
      if (user && !emailVerified && isMounted) {
        user.reload().then(() => {
          if (user.emailVerified) {
            setEmailVerified(true);
          }
        });
      }
      if(emailVerified){
        navigate('/inicio')
        //console.log("Email verificado: ", emailVerified);
      }
    }, 3000); // Verifica cada 3 segundos

    // Cleanup on unmount
    return () => {
      unsubscribe();
      clearInterval(checkEmailVerification);
      setIsMounted(false); // Marca como desmontado
    };
  }, [user, emailVerified, isMounted]);

  // Si el correo ha sido verificado, puedes redirigir o tomar alguna acción
  //console.log("Email verificado 2: ", emailVerified);
  if (emailVerified) {
    return <Navigate to="/inicio" />;
  }

  return (
    <div className="flex flex-col mb-12 w-full">
      <div className="flex flex-col items-center">
        <p className="font-bold text-deepBlue pb-8 text-2xl pt-5">
          Verifica tu email para finalizar el registro
        </p>
        <p className="w-3/12 text-sm text-center mb-7 text-deepBlue">
          Hemos enviado un enlace de verificación a tu correo electrónico. Por
          favor, revisa tu correo electrónico y pincha en el enlace.
        </p>
        <div className="sm:w-1/4">
          <Button
            text="Reenviar enlace"
            color="secondary"
            className="w-full mt-5"
            fullWidth
            type="button"
            onClick={() => {
              dispatch(sendMailVerify());
              setShowModal(true);
            }}
          />
        </div>
      </div>
      <Modal isVisible={showModal} onClose={handleCloseModal}>
        <div className="bg-white w-full rounded-lg text-center p-5">
          <h1 className="text-lg mb-5 text-deepBlue font-bold">
            ¡Correo enviado con éxito!
          </h1>
          <p className="text-sm p-5">
            Hemos enviado un correo electrónico a la dirección proporcionada con
            un enlace para validar tu cuenta. Por favor, revisa tu bandeja de
            entrada (y la carpeta de spam, si es necesario) y haz clic en el
            enlace para completar el proceso de validación.
          </p>
          <p className="text-sm">
            Si no recibes el correo en unos minutos, puedes solicitar que te lo
            enviemos de nuevo.
          </p>
          <Button
            text={"Ok"}
            className="mt-3 bg-primary-light text-white font-semibold"
            paddingClassname="py-1 px-6"
            onClick={handleCloseModal}
          />
        </div>
      </Modal>
    </div>
  );
};
import clsx from "clsx";
import React, { useState } from "react";
import {
  ArrowDownCircle,
  ArrowLeft,
  PlusCircle as PlusCircleIcon,
} from "react-feather";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AlertState } from "../redux/reducers/alert.reducer";
import { RootState } from "../redux/reducers/root.reducer";
import { Button } from "./Button";
import { FilterOffererMenu } from "./FilterOffererMenu";
import { OrderByMenu } from "./OrderByMenu";

export const OffererHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);

  const {
    alerts,
    delete: { state: deleteState },
  } = useSelector<RootState, AlertState>((state) => state.alertReducer);

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleCloseOrderMenu = () => {
    setOpenOrder(false);
  };

  const handleOpenOrderMenu = () => {
    setOpenOrder((toggle) => !toggle);
  };
  const handleOpenMenu = () => {
    setOpen((toggle) => !toggle);
  };

  const navigation = useNavigate();
  const CreateAlert = () => {
    return (
      <React.Fragment>
        <CustomButtom
          title=""
          icon={""}
          onClick={() => {
            navigation("/oferente/CrearAlerta");
          }}
        />
      </React.Fragment>
    );
  };

  const SearchButtons = () => {
    // eslint-disable-next-line

    return (
      <React.Fragment>
        <Button
          text="Buscar por Solicitud"
          disabled={location.pathname === "/solicitudesActivas"}
          className="bg-primary-dark w-56 focus:outline-none "
          paddingClassname="px-0 py-2"
          onClick={() => navigation("/solicitudesActivas")}
        />
        <Button
          text="Buscar por Geolocalización"
          className="bg-primary-dark mt-2 md:mt-0 md:ml-4 w-56"
          disabled={location.pathname === "/oferente/ofertasCercanas"}
          paddingClassname="px-2 py-2"
          onClick={() => navigation("/oferente/ofertasCercanas")}
        />
      </React.Fragment>
    );
  };

  return (
    <div
      className={clsx(
        " py-5 flex-wrap  md:flex-wrap md:py-0 md:h-16 bg-primary relative  flex flex-row sm:px-15 px-2 w-full z-10 top-20 md:items-center",
        location.pathname !== "/solicitudesActivas" &&
          location.pathname !== "/oferente/ofertasRealizadas" &&
          location.pathname !== "/oferente/ofertasCercanas"
          ? "justify-start relative flex-nowrap"
          : "justify-between",
        location.pathname === "/oferente/alertas" && "justify-end",
        location.pathname === "/oferente/CrearAlerta" || location.pathname === "/oferente/alertas" || location.pathname === "/oferente/EditarAlerta" ? "h-20" : "h-44"
      )}
    >
      {location.pathname === "/solicitudesActivas" ||
      location.pathname === "/oferente/ofertasRealizadas" ||
      location.pathname === "/oferente/ofertasCercanas" ? (
        <React.Fragment>
          <div className="">
            {location.pathname !== "/oferente/ofertasCercanas" && (
              <CustomButtom
                title="Filtrar"
                icon={<PlusCircleIcon size={32} color="white" />}
                position="end"
                onClick={() => {
                  if (!openOrder) {
                    handleOpenMenu();
                  } else {
                    handleCloseMenu();
                  }
                }}
              />
            )}
          </div>

          <div className="hidden md:block">
            <SearchButtons />
          </div>
          <div className="">
            {location.pathname !== "/oferente/ofertasCercanas" && (
              <CustomButtom
                title="Ordenar Por"
                icon={<ArrowDownCircle size={32} color="white" />}
                position="end"
                onClick={() => {
                  if (!open) {
                    handleOpenOrderMenu();
                  } else {
                    handleCloseOrderMenu();
                  }
                }}
              />
            )}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {location.pathname === "/oferente/alertas" ? (
            alerts.length >= 3 ? (
              <>
                <CustomButtom
                  title="Cantidad máxima alcanzada"
                  position="start"
                  icon={<PlusCircleIcon size={32} color="" />}
                />
              </>
            ) : (
              <Link to={"/oferente/CrearAlerta"}>
                <CustomButtom
                  title="Crear alerta"
                  position="start"
                  icon={<PlusCircleIcon size={32} color="white" />}
                  onClick={() => {
                    navigation("/oferente/CrearAlerta");
                  }}
                />
              </Link>
            )
          ) : (
            <CustomButtom
              icon={<ArrowLeft size={50} color="white" />}
              title=""
              position="start"
              onClick={() => location.pathname === "/oferente/CrearAlerta" ? navigate(-2) : navigate(-1)}
            />
          )}
        </React.Fragment>
      )}

      {location.pathname === "/oferente/alertas"  && (
        <div className="mt-2 md:hidden flex flex-col justify-center items-center w-full">
          <CreateAlert />
        </div>
      )}
      {location.pathname !== "/oferente/CrearAlerta" && location.pathname !== "/oferente/alertas" && location.pathname !== "/oferente/EditarAlerta" && (
        <div className="mt-2 md:hidden flex flex-col justify-center items-center w-full">
          <SearchButtons />
        </div>
      )}
      {location.pathname === "/solicitudesActivas" && (
        <FilterOffererMenu
          isVisible={open}
          onClose={handleCloseMenu}
          title="filtrar"
        />
      )}
      {location.pathname === "/solicitudesActivas" && (
        <OrderByMenu
          isVisible={openOrder}
          onClose={handleCloseOrderMenu}
          title="Ordenar Por"
        />
      )}
    </div>
  );
};

interface ButtonProps {
  title: string;
  icon: any;
  position?: "start" | "end";
  onClick?: VoidFunction;
  style?: React.CSSProperties;
}

const CustomButtom: React.FC<ButtonProps> = ({
  icon,
  title,
  position = "start",
  style,
  onClick,
}) => {
  return (
    <button
      className={clsx(
        "flex items-center",
        position === "start" ? "flex-row" : "flex-row-reverse"
      )}
      onClick={onClick}
      style={style}
    >
      <span
        className={clsx(
          "text-white font-semibold text-sm",

          position === "start" ? "mr-2" : "ml-2"
        )}
      >
        {title}
      </span>
      {icon}
    </button>
  );
};

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StarRatingComponent from "react-star-rating-component";
import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";
import { FormState } from "../../../models/form_state";
import { PlaintiffOffer } from "../../../models/PlaintiffOffer";
import {
  AcceptOffer,
  PlaintiffRequest,
} from "../../../models/PlaintiffRequest";
import {
  addCalificationOfferer,
  addCalificationPlaintiff,
} from "../../../redux/actions/calification.action";
import { OfferState } from "../../../redux/reducers/offer.reducer";
import { RootState } from "../../../redux/reducers/root.reducer";
import { OffererAcceptCard } from "./OffererAcceptCard";
import { OfferLoadingCard } from "./OfferLoadingCard";
interface Props {
  selectedRequest?: PlaintiffRequest;
  selectedOffer?: PlaintiffOffer;
}

export const OfferSectionFromOfferList: React.FC<Props> = ({
  selectedRequest,
  selectedOffer,
}) => {
  const { edit: offerState } = useSelector<RootState, OfferState>(
    (state) => state.offerReducer
  );

  const [selected, setSelected] = useState<any>();
  const [calificationModal, setCalificationModal] = useState(false);
  const [calification, setCalification] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleRate = (userRequest: any) => {
    setCalificationModal(true);
    setSelected(userRequest);
  };

  const handleOnClose = () => {
    setCalificationModal(false);
  };

  const handleRateUser = (
    calification: number,
    userRequest: any,
    requestId: any
  ) => {
    if (selectedOffer?.id) {
      dispatch(
        addCalificationPlaintiff(
          calification,
          userRequest,
          requestId,
          selectedOffer.id
        )
      );
      setCalificationModal(false);
      navigate("/oferente/ofertasRealizadas");
    }
  };
  function onStarClick(nextValue: number, prevValue: number, name: string) {
    setCalification(nextValue);
  }

  const RenderContent = () => {
    if (offerState.state === FormState.Submitting) {
      return <OfferLoadingCard />;
    } else if (selectedOffer && selectedRequest) {
      return (
        <OffererAcceptCard
          userRequest={selectedRequest?.Usuario as any}
          handleRate={handleRate}
          isRated={selectedRequest.Calificado}
          offer={selectedOffer}
          isOfferAcepted={
            (selectedRequest?.OfertaAceptada as AcceptOffer)?.OfertaId ===
              selectedOffer?.id && selectedRequest?.OfertaAceptada !== null
          }
        />
      );
    } else if (
      offerState.state === FormState.Success &&
      selectedRequest &&
      selectedOffer
    ) {
      return (
        <OffererAcceptCard
          isRated={selectedRequest.Calificado}
          userRequest={selectedRequest.Usuario as any}
          handleRate={handleRate}
          offer={selectedOffer as PlaintiffOffer}
          isOfferAcepted={
            (selectedRequest?.OfertaAceptada as AcceptOffer)?.OfertaId ===
              selectedOffer.id && selectedRequest?.OfertaAceptada !== null
          }
        />
      );
    } else {
      return (
        <div>
          <React.Fragment>
            {new Array(3).fill(0).map((_, index) => (
              <OfferLoadingCard key={`loading-offers-${index}`} />
            ))}
          </React.Fragment>
        </div>
      );
    }
  };

  return (
    <div>
      <RenderContent />
      {/* MODAL PARA CALIFICAR USUARIO */}
      <div>
        <Modal
          isVisible={calificationModal}
          onClose={handleOnClose}
          isDismiseble
        >
          <div className="bg-white rounded-lg h-full shadow-lg p-5 content-between">
            <h1 className="text-lg text-deepBlue font-bold">
              {`Califica tu experiencia con ${selectedRequest?.Usuario.Nombre} ${selectedRequest?.Usuario?.Apellido}`}
            </h1>

            <div className="flex place-content-center" style={{ fontSize: 60 }}>
              <StarRatingComponent
                name="Calificar Usuario"
                starCount={5}
                starColor={"#009fda"}
                emptyStarColor={"#B6B6B6"}
                value={calification}
                editing
                onStarClick={onStarClick}
                onStarHover={onStarClick}
              />
            </div>
            <div className="flex place-content-center mt-2">
              <Button
                text="Calificar"
                className="text-sm bg-primary-light"
                onClick={() =>
                  handleRateUser(calification, selected, selectedRequest?.id)
                }
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

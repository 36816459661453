import {
  addDoc,
  collection,
  getDocs,
  limit,
  query,
  Timestamp,
} from "@firebase/firestore";
import { addDays, isAfter, isBefore } from "date-fns";
import { orderBy, where } from "firebase/firestore";
import { firestore } from "../../configs/firebase";
import { AppThunk } from "../../models/app-thunk";
import { OffererTransaction } from "../../models/OffererTransaction";
import * as types from "../types";

export function getTransactionPlaintiff(): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.TRANSACTION_GET_SUBMITTING,
    });

    const user = getState().authReducer.user;

    try {
      const docRef = collection(firestore, "Ventas");
      const q = query(
        docRef,
        where("Usuario.Id", "==", user?.id),
        where("Usuario.Tipo", "==", "Demandante"),
        where("Estado", "==", "Pagado"),
        limit(5),
        orderBy("FechaCreacion", "desc")
      );

      const [response, totalDocsResponse] = await Promise.all([
        getDocs(q),
        getDocs(q),
      ]);
      //TODO: modificar despues paginación

      const transactions = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));
      dispatch({
        type: types.TRANSACTION_GET_SUCCESS,
        payload: {
          lastDoc: response.docs[response.docs.length - 1],
          transaction: transactions,
          totalDocs: totalDocsResponse.size,
        },
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.TRANSACTION_GET_FAILURE,
        payload: error,
      });
    }
  };
}
export function getMoreTransactionPlaintiff(total: number): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.TRANSACTION_GET_SUBMITTING,
    });

    const user = getState().authReducer.user;

    try {
      const docRef = collection(firestore, "Ventas");
      const q = query(
        docRef,
        where("Usuario.Id", "==", user?.id),
        where("Usuario.Tipo", "==", "Demandante"),
        where("Estado", "==", "Pagado"),
        limit(total + 5),
        orderBy("FechaCreacion", "desc")
      );

      const [response, totalDocsResponse] = await Promise.all([
        getDocs(q),
        getDocs(q),
      ]);
      //TODO: modificar despues paginación

      const transactions = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));
      dispatch({
        type: types.TRANSACTION_GET_SUCCESS,
        payload: {
          lastDoc: response.docs[response.docs.length - 1],
          transaction: transactions,
          totalDocs: totalDocsResponse.size,
        },
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.TRANSACTION_GET_FAILURE,
        payload: error,
      });
    }
  };
}

export function addTransaction(request: any): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.TRANSACTION_ADD_SUBMITTING,
    });

    const user = getState().authReducer.user;
    const date = new Date();

    try {
      const docData = {
        NumeroTransaccion: date.getTime(),
        FechaTermino: Timestamp.fromDate(addDays(date, 7)),
        FechaCreacion: Timestamp.fromDate(date),
        Solicitud: {
          Id: request.id,
          Titulo: request.Titulo,
          Imagen: request.Imagenes[0],
          Precio: request.Precio,
        },
        Usuario: {
          Nombre: user.Nombre,
          Calificion: user.Calificion,
          Apellido: user.Apellido,
          Id: user.id,
        },
      };
      // eslint-disable-next-line
      const docRef = await addDoc(
        collection(firestore, "Transacciones"),

        docData
      );

      dispatch({
        type: types.TRANSACTION_ADD_SUCCESS,
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.REQUESTS_ADD_FAILURE,
        payload: error,
      });
    }
  };
}

export function getTransactionOfferer(): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.TRANSACTION_OFFERER_GET_SUBMITTING,
    });

    const user = getState().authReducer.user;
    try {
      const docRef = collection(firestore, "Ventas");
      const q = query(
        docRef,
        where("Usuario.Id", "==", user?.id),
        where("Usuario.Tipo", "==", "Oferente"),
        where("Estado", "==", "Pagado"),
        limit(4),
        orderBy("FechaCreacion", "desc")
      );

      const response = await getDocs(q);
      //TODO: modificar despues paginación

      const transactions: OffererTransaction[] = response.docs.map((x) => ({
        ...(x.data() as any),
        id: x.id,
      }));
      let i = 0;
      while (i <= transactions.length - 1) {
        const transaction = transactions[i];
        if (i + 1 <= transactions.length - 1) {
          const nextTransaction = transactions[i + 1];
          if (isBefore(new Date(), nextTransaction.FechaPremium.toDate())) {
            transaction.FechaPremiumAnterior = nextTransaction.FechaPremium;
          } else {
            break;
          }

          // if(isAfter(new Date(), nextTransaction.FechaPremium.toDate())){
          //   break;
          // }
        }
        i++;
      }

      dispatch({
        type: types.TRANSACTION_OFFERER_GET_SUCCESS,
        payload: { transactions: transactions, totalDocs: response.size },
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.TRANSACTION_OFFERER_GET_FAILURE,
        payload: error,
      });
    }
  };
}

export function getMoreTransactionOfferer(total: number): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.TRANSACTION_OFFERER_GET_SUBMITTING,
    });

    const user = getState().authReducer.user;
    try {
      const docRef = collection(firestore, "Ventas");
      const q = query(
        docRef,
        where("Usuario.Id", "==", user?.id),
        where("Usuario.Tipo", "==", "Oferente"),
        where("Estado", "==", "Pagado"),
        limit(total + 4),
        orderBy("FechaCreacion", "desc")
      );

      const response = await getDocs(q);
      //TODO: modificar despues paginación

      const transactions: OffererTransaction[] = response.docs.map((x) => ({
        ...(x.data() as any),
        id: x.id,
      }));
      let i = 0;
      while (i <= transactions.length - 1) {
        const transaction = transactions[i];
        if (i + 1 <= transactions.length - 1) {
          const nextTransaction = transactions[i + 1];
          if (isBefore(new Date(), nextTransaction.FechaPremium.toDate())) {
            transaction.FechaPremiumAnterior = nextTransaction.FechaPremium;
          } else {
            break;
          }
        }
        i++;
      }

      dispatch({
        type: types.TRANSACTION_OFFERER_GET_SUCCESS,
        payload: { transactions: transactions, totalDocs: response.size },
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.TRANSACTION_OFFERER_GET_FAILURE,
        payload: error,
      });
    }
  };
}

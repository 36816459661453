import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from "chart.js";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { Button } from "../../components/Button";
import { CustomSelect } from "../../components/CustomSelect";
import { communes } from "../../configs/communes";
import { FormState } from "../../models/form_states";
import { getCategories } from "../../redux/actions/categories.action";
import {
  getStatistic,
  staticClean
} from "../../redux/actions/statistic.action";
import { CategoriesState } from "../../redux/reducers/categories.reducer";
import { RootState } from "../../redux/reducers/root.reducer";
import { StatisticState } from "../../redux/reducers/statistic.reducer";
import { BudgetDashboard } from "./components/BudgetDashboard";
import { CategoriesDashboard } from "./components/CategoriesDashboard";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const StatisticDashboard2 = () => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    values,
    handleChange,
    touched,
    setFieldValue,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      Comuna: "",
      Categoria: "",
    },
    onSubmit: (values) => {
      dispatch(getStatistic(values.Comuna, values.Categoria));
    },
    validationSchema: yup.object({
      Comuna: yup.object().required("Debe ingresar una Comuna"),
    }),
  });

  const { statisticsCategories, stateForm, state } = useSelector<
    RootState,
    StatisticState
  >((stateForm) => stateForm.statisticReducer);

  const cleanForm = () => {
    resetForm({ values: { Comuna: "", Categoria: "" } });
    dispatch(staticClean());
  };

  return (
    <div>
      <form onSubmit={handleSubmit} className="mb-3 grid">
        <div className="bg-primary-50 w-2/3  my-5 flex place-content-center mx-auto rounded-lg flex-col pb-3">
          <div className="text-center pt-5">
            <h3 className="text-xl font-semibold text-deepBlue underline pb-3">
              Generar gráfico de categorías solicitadas/ofertadas
            </h3>
            <p className="text-gray-500 text-lg">
              Se generará 4 gráficos con las 5 categorías mas demandadas y
              ofertadas por comuna, como también las 5 categorías menos
              demandadas y ofertadas.
            </p>
          </div>
        </div>

        <div className="grid grid-row-2  place-content-center">
          <div className="  md:grid md:grid-cols-2  ">
            <div className="my-3 md:w-72 px-2">
              <CustomSelect
                id="Comuna"
                label="Comuna"
                className="mt-5 mr-5 w-36 pb-5 "
                placeholder="Seleccionar"
                helperText={touched.Comuna && errors.Comuna}
                error={touched.Comuna && Boolean(errors.Comuna)}
                options={communes.map((commune) => ({
                  label: commune,
                  value: commune,
                }))}
                value={values.Comuna}
                isSearchable
                onChange={(value: any) => {
                  setFieldValue("Comuna", value);
                }}
              />
            </div>
          </div>

          <div className="md:grid md:grid-cols-2">
            <Button
              type="submit"
              text="Generar"
              className="bg-primary mx-2"
              isLoading={stateForm === FormState.Submitting}
              disabled={stateForm === FormState.Submitting}
            />

            <Button
              type="button"
              text="Limpiar"
              className="bg-red-500 mx-2 "
              disabled={stateForm === FormState.Submitting}
              onClick={() => cleanForm()}
            />
          </div>
        </div>
      </form>

      {values.Comuna && stateForm === FormState.Success && (
        <CategoriesDashboard statistics={statisticsCategories} />
      )}
    </div>
  );
};

export const StatisticDashboard = () => {
  useEffect(() => {
    dispatch(getCategories());
    // eslint-disable-next-line
  }, []);

  /* const { categories } = useSelector<RootState, CategoriesState>(
    (state) => state.categoriesReducer
  ); */
  const { categories } = useSelector<RootState, CategoriesState>(
    (state) => state.categoriesReducer
  );
  const dispatch = useDispatch();
  const {
    handleSubmit,
    values,
    handleChange,
    touched,
    setFieldValue,
    errors,
    resetForm,
  } = useFormik({
    initialValues: {
      Comuna: "",
      Categoria: "",
    },
    onSubmit: (values) => {
      dispatch(getStatistic(values.Comuna, values.Categoria));
    },
    validationSchema: yup.object({
      Comuna: yup.object().required("Debe ingresar una Comuna"),
      Categoria: yup.object().required("Debe ingresar una Categoria"),
    }),
  });

  const { statisticsCategories, statisticsBudget, state } = useSelector<
    RootState,
    StatisticState
  >((state) => state.statisticReducer);

  const cleanForm = () => {
    resetForm({ values: { Comuna: "", Categoria: "" } });
    dispatch(staticClean());
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [state]);

  return (
    <div>
      <StatisticDashboard2 />
      <form onSubmit={handleSubmit} className="mb-3 grid">
        <div className="bg-primary-50 w-2/3  my-5 flex place-content-center mx-auto rounded-lg flex-col pb-3">
          <div className="text-center pt-5">
            <h3 className="text-xl font-semibold text-deepBlue underline pb-3">
              Generar gráfico media de presupuestos de categoría por comuna.
            </h3>
            <p className="text-gray-500 text-lg">
              Se generará un gráfico con el presupuesto promedio demandado y
              ofertado por categoría y comuna.
            </p>
          </div>
        </div>

        <div className="grid grid-row-2 place-content-center">
          <div className="  md:grid md:grid-cols-2  ">
            <div className="my-3 md:w-72 px-2">
              <CustomSelect
                id="Comuna"
                label="Comuna"
                className="mt-5 mr-5 w-36 pb-5 "
                placeholder="Seleccionar"
                helperText={touched.Comuna && errors.Comuna}
                error={touched.Comuna && Boolean(errors.Comuna)}
                options={communes.map((commune) => ({
                  label: commune,
                  value: commune,
                }))}
                value={values.Comuna}
                isSearchable
                onChange={(value: any) => {
                  setFieldValue("Comuna", value);
                }}
              />
            </div>

            <div className="my-3 px-2">
              <CustomSelect
                label="Categoria"
                helperText={touched.Categoria && errors.Categoria}
                error={touched.Categoria && Boolean(errors.Categoria)}
                id="Categoria"
                className="mt-5 mr-5 w-36 pb-5"
                options={categories.map((categorie) => ({
                  label: categorie.Nombre,
                  value: categorie.Nombre,
                }))}
                placeholder="Seleccionar"
                onChange={(value: any) => {
                  setFieldValue("Categoria", value);
                }}
                value={values.Categoria}
                isSearchable
                autoFocus
              />
            </div>
          </div>

          <div className="md:grid md:grid-cols-2">
            <Button
              type="submit"
              text="Generar"
              className="bg-primary mx-2"
              isLoading={state === FormState.Submitting}
              disabled={state === FormState.Submitting}
            />
            <Button
              type="reset"
              text="Limpiar"
              className="bg-red-500 mx-2 "
              disabled={state === FormState.Submitting}
              onClick={() => cleanForm()}
            />
          </div>
        </div>
      </form>

      {values.Categoria && values.Comuna && state === FormState.Success && (
        <BudgetDashboard statistics={statisticsBudget} />
      )}
    </div>
  );
};

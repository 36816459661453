import { Action } from "../../models/action";
import { CustomAlert } from "../../models/CustomAlert";
import { FormState } from "../../models/form_state";
import * as types from "../types";

export interface AlertState {
  alerts: CustomAlert[];
  state: FormState;
  error?: string;
  lastDoc?: any;
  totalDocs: number;
  add: {
    error?: string;
    state: FormState;
  };
  edit: {
    state: FormState;
    selectedAlert?: any;
    error?: string;
  };
  delete: {
    state: FormState;
    error?: string;
  };
}

const initialState: AlertState = {
  alerts: [],
  state: FormState.Initial,
  totalDocs: 0,
  add: {
    state: FormState.Initial,
  },
  delete: {
    state: FormState.Initial,
  },
  edit: {
    selectedAlert: [],
    state: FormState.Initial,
  },
};

export default function reducer(
  state = initialState,
  action: Action
): AlertState {
  switch (action.type) {
    case types.ALERT_GET_SUCCESS:
      return {
        ...state,
        alerts: action.payload.alerts,
        state: FormState.Success,
        lastDoc: action.payload.lastDoc,
        totalDocs: action.payload.totalDocs,
      };
    case types.ALERT_GET_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };
    case types.ALERT_GET_SUBMITTING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.ALERT_GET_INITIAL:
      return {
        ...state,
        state: FormState.Initial,
      };
    case types.ALERT_ADD_INITIAL:
      return {
        ...state,
        add: initialState.add,
      };
    case types.ALERT_ADD_SUBMITTING:
      return {
        ...state,
        add: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.ALERT_ADD_SUCCESS:
      return {
        ...state,
        add: {
          state: FormState.Success,
        },
      };
    case types.ALERT_ADD_FAILURE:
      return {
        ...state,
        add: {
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.ALERT_EDIT_INITIAL:
      return {
        ...state,
        edit: {
          ...initialState.edit,
        },
      };
    case types.ALERT_EDIT_SUBMITTING:
      return {
        ...state,
        edit: {
          ...state.edit,
          state: FormState.Submitting,
        },
      };
    case types.ALERT_EDIT_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          state: FormState.Success,
        },
      };
    case types.ALERT_EDIT_FAILURE:
      return {
        ...state,
        edit: {
          ...state.edit,
          state: FormState.Failure,
          error: action.payload,
        },
      };
    case types.ALERT_DELETE_SUBMITTING:
      return {
        ...state,
        delete: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.ALERT_DELETE_SUCCESS:
      return {
        ...state,
        delete: {
          state: FormState.Success,
        },
        alerts: state.alerts.filter((x) => x.Id !== action.payload.Id),
      };
    case types.ALERT_DELETE_FAILURE:
      return {
        ...state,
        delete: {
          state: FormState.Failure,
          error: action.payload
        }
      };
    case types.ALERT_GET_ONE_SUCCESS:
      return {
        ...state,
        edit: {
          state: FormState.Initial,
          selectedAlert: action.payload
        },
      };

    default:
      return state;
  }
}

import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
} from "@firebase/firestore";
import { isAfter, isBefore } from "date-fns";
import { firestore } from "../../configs/firebase";
import { AdvertisingModel } from "../../models/AdvertisingModel";
import { AppThunk } from "../../models/app-thunk";
import * as types from "../types";

export const getAdvertising = (): AppThunk => {
  return async (dispatch) => {
    dispatch({
      type: types.ADVERTISING_GET_SUBMITTING,
    });
    try {
      const catRef = collection(firestore, "Publicidad");
      const q = query(catRef, where("Activo", "==", true));
      const today = new Date();
      const response = await getDocs(q);
      const advertisings = response.docs.map((x) => ({
        ...x.data(),
        id: x.id,
      }));

      const validAdvertisings = advertisings.filter(
        (x: any) =>
          isAfter(today, new Date(x.FechaInicio)) &&
          isAfter(new Date(x.FechaTermino), today)
      );

      dispatch({
        type: types.ADVERTISING_GET_SUCCESS,
        payload: validAdvertisings,
      });
    } catch (error) {
      //console.log(error);
      dispatch({
        type: types.ADVERTISING_GET_FAILURE,
        payload: "Error inesperado",
      });
    }
  };
};

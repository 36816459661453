class ExtendableError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else { 
      this.stack = (new Error(message)).stack; 
    }
  }
}   

export class RejectedError extends ExtendableError {
  public rejectedCount: number;
  public rejectedTime: number;

  constructor(rejectedCount: number, rejectedTime: number, message: string){
    super(message);
    this.rejectedCount = rejectedCount;
    this.rejectedTime = rejectedTime;
  }
}
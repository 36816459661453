import * as types from "../types";
import { AppThunk } from "../../models/app-thunk";
import {
  doc,
  query,
  collection,
  where,
  getDocs,
  deleteDoc,
  serverTimestamp,
  addDoc,
  updateDoc,
} from "@firebase/firestore";
import { firestore } from "../../configs/firebase";
import { CustomAlert } from "../../models/CustomAlert";
import { Action } from "../../models/action";

export function getMyAlerts(): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.ALERT_GET_SUBMITTING,
    });
    const user = getState().authReducer.user;

    try {
      const userAlerts = query(
        collection(firestore, "Alertas"),
        where("Usuario.Id", "==", user?.id)
      );

      const [response, totalDocsResponse] = await Promise.all([
        getDocs(userAlerts),
        getDocs(userAlerts),
      ]);

      const alerts = response.docs.map((x) => ({ ...x.data(), Id: x.id }));

      dispatch({
        type: types.ALERT_GET_SUCCESS,
        payload: {
          lastDoc: response.docs[response.docs.length - 1],
          alerts,
          totalDocs: totalDocsResponse,
        },
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.ALERT_GET_FAILURE,
        payload: error,
      });
    }
  };
}







export function addAlert(alert: any): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.ALERT_ADD_SUBMITTING,
    });

    const user = getState().authReducer.user;
    try {
      const userAlerts = query(
        collection(firestore, "Alertas"),
        where("Usuario.Id", "==", user?.id)
      );

      
      const [response] = await Promise.all([getDocs(userAlerts)])

      const cantidadAlerta =response.docs.length;

      if(cantidadAlerta>3){

        const error="Supera las 3 alertas"

        dispatch({
          type: types.ALERT_ADD_FAILURE,
          payload: error,
        });

      }else{

const docData = {
        ...alert,
        Categorias: alert.Categorias.map((cate:any) => cate.value),
        FechaCreacion: serverTimestamp(),
        Usuario: {
          Nombre: user.Nombre,
          Apellido: user.Apellido,
          Id: user.id,
        },
      };

      await addDoc(collection(firestore, "Alertas"), docData);

      dispatch({
        type: types.ALERT_ADD_SUCCESS,
      });



      }


      
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.ALERT_ADD_FAILURE,
        payload: error,
      });
    }
  };
}


export function formStateInitial(): AppThunk{
  return async (dispatch) => {



  dispatch({
      type: types.ALERT_ADD_INITIAL,
    });

   

  
    
  
  }
}





export function deleteAlert(alert: any): AppThunk{
  return async (dispatch) => {
    dispatch({
      type: types.ALERT_DELETE_SUBMITTING
    })

    try {
      const alertRef = doc(firestore, "Alertas", alert.Id);
      await deleteDoc(alertRef)

      dispatch({
        type: types.ALERT_DELETE_SUCCESS
      })

    } catch(error: any) {
      dispatch({
        type: types.ALERT_DELETE_FAILURE
      })
    }
  }
}




export function editAlert(alert: any): AppThunk {
  return async (dispatch) => {
    dispatch({
      type: types.ALERT_EDIT_SUBMITTING,
    });
    try {
      const alertRef = doc(firestore, "Alertas", alert.Id);
      await updateDoc(alertRef, {
        Titulo: alert.Titulo,
        Categorias: alert.Categorias.map((cate:any) => cate.value),
        Region: alert.Region,
        Comuna: alert.Comuna,
        FechaActualizacion: serverTimestamp(),
      });
      
      dispatch({
        type: types.ALERT_EDIT_SUCCESS,
        payload: {
          ...alert,
        },
      });
    } catch (error: any) {

      //console.log(error);
      dispatch({
        type: types.ALERT_EDIT_FAILURE,
        payload: error,
      });
    }
  };
}






export const setSelectedAlert = (alert: CustomAlert): Action => ({
  type: types.ALERT_GET_ONE_SUCCESS,
  payload: alert,
});


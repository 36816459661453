import React from "react";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

interface Props {}

export const PublicLayout: React.FC<Props> = ({ children }) => {
  return (
    <div>
      <Header />
      <div className="mt-20"/>
      {children}
      <Footer />
    </div>
  );
};

import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";

type Excluded = "onChange" | "value";

interface Props<T>
  extends Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    Excluded
  > {
  fullWidth?: boolean;
  label?: string;
  divClassName?: string;
  divStyle?: CSSProperties;
  onClick?: VoidFunction;
  onChange?: (value: T) => void;
  value: T;
  helperText?: any;
  disabled?: boolean;
  helperTextClassName?: string;
  error?: boolean;
}

// TODO: Solucionar problema de value exterior no cambia value interior
export function Select<T>({
  fullWidth,
  label,
  id,
  divClassName,
  divStyle,
  placeholder,
  children,
  disabled,
  value,
  onChange,
  onClick,
  helperText,
  helperTextClassName,
  error = false,
  ...others
}: Props<T>) {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const [insideInputValue, setInsideInputValue] = useState<string>("");

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref?.current?.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (value) {
      React.Children.forEach(children, (child: any) => {
        if (value === child.props.value) {
          setInsideInputValue(child.props.label);
        }
      });
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div ref={ref} className={clsx(divClassName, "relative")} style={divStyle}>
      {label && (
        <label
          htmlFor={id}
          className="pl-4 pb-2 font-semibold text-deepBlue text-lg "
        >
          {label}
        </label>
      )}
      <div
        role="button"
        onClick={() => {
          if (disabled) return;

          setOpen(!open);
          if (onClick) onClick();
        }}
        className={`${
          fullWidth ? "w-full" : ""
        } relative border-1.5 border-primary rounded-full h-12 flex flex-row items-center px-4`}
        style={{ backgroundColor: disabled ? "#F2F2F2" : "#FFFFFF"}}
      >
        {placeholder && !insideInputValue && <span>{placeholder}</span>}
        {insideInputValue && (
          <span className="pr-6 overflow-ellipsis whitespace-nowrap overflow-x-hidden">
            {insideInputValue}
          </span>
        )}
        <input
          className="absolute w-full opacity-0 box-border bottom-0 left-0 pointer-events-none"
          aria-hidden="true"
          tabIndex={-1}
          value={insideInputValue}
          id={id}
          disabled={disabled}
          onChange={(event) => {}}
          {...others}
        />

        {open ? (
          <ChevronDown size={24} className="absolute right-4 text-primary" />
        ) : (
          <ChevronUp size={24} className="absolute right-4 text-primary" />
        )}
      </div>
      {helperText && error && (
        <div className="px-5">
          <span
            className={clsx(
              "font-semibold text-left",
              helperTextClassName,
              error && "text-red-500"
            )}
            style={{ color: helperText }}
          >
            {helperText}
          </span>
        </div>
      )}
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {open && (
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{ duration: 0.6, type: "spring" }}
            className={clsx(
              "w-full z-20 bg-white absolute -bottom-20 left-0 right-0 shadow-lg"
            )}
            style={{ maxHeight: 300, overflowY: "auto", overflowX: "hidden" }}
          >
            <ul className="list-none">
              {children &&
                React.Children.map(children, function (args: any) {
                  const newElement: any = React.cloneElement(args, {
                    onClick: () => {
                      setOpen(false);
                      // setInsideValue(newElement.props?.value);
                      setInsideInputValue(newElement.props?.label);
                      if (onChange) {
                        onChange(newElement.props?.value);
                      }
                    },
                  });
                  return newElement;
                })}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

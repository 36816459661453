import { Action } from "../../models/action";
import { Categories } from "../../models/Categories";
import { FormState } from "../../models/form_states";
import * as types from "../types";

export interface CategoriesState {
  categories: Categories[];
  categoriesCountRequest?: Categories[];
  state: FormState;
  error?: string;
}

const initialState = {
  categories: [],
  categoriesCountRequest: [],
  state: FormState.Initial,
} as CategoriesState;

export default function reducer(state = initialState, action: Action): CategoriesState {
  switch (action.type) {
    case types.CATEGORIES_SUCCESS:
      return {
        categories: action.payload,
        state: FormState.Success,
      };
    case types.CATEGORIES_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };
    case types.CATEGORIES_SUBMITTING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
      case types.CATEGORIES_COUNT_REQUEST_SUBMITTING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
      case types.CATEGORIES_COUNT_REQUEST_SUCCESS:
      return {
        ...state,
        categoriesCountRequest: action.payload,
        state: FormState.Success,
      };
      case types.CATEGORIES_COUNT_REQUEST_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };
    default:
      return state;
  }
}

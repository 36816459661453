import clsx from "clsx";
import { format, isAfter } from "date-fns";
import { isBefore } from "date-fns/esm";
import { motion } from "framer-motion";
import React from "react";
import { Search as SearchIcon } from "react-feather";
import { useSelector } from "react-redux";
import { IconButton } from "../../../components/IconButton";
import { OffererTransaction } from "../../../models/OffererTransaction";
import { AuthState } from "../../../redux/reducers/auth.reducer";
import { RootState } from "../../../redux/reducers/root.reducer";

interface Props {
  transaction: OffererTransaction;
  onSeeDetails: (transaction: OffererTransaction) => void;
}

export const OffererTransactionCard: React.FC<Props> = ({
  transaction,
  onSeeDetails,
}) => {
  const todayDate = new Date();
  const { user } = useSelector<RootState, AuthState>(
    (state) => state.authReducer
  );
  const backgroundColorToolBar = () => {
    if (isAfter(todayDate, transaction.FechaPremium.toDate())) {
      return "bg-red-500";
    } else {
      return "bg-green-300";
    }
  };
  

  return (
    <div className="mb-11">
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{minHeight:240}}
      className={
        "bg-white shadow-md rounded-xl overflow-hidden hover:shadow-xl ml-3 mt-3"
      }
      onMouseEnter={() => {}}
      onMouseLeave={() => {}}
    >
      <div className="w-full h-16 relative">
        <div
          className={clsx(
            "absolute top-0 left-0 right-0 bottom-0 ",
            backgroundColorToolBar()
          )}
        ></div>
      </div>
      {/* 
        Seccion inferior de la Tarjeta
      */}
      <div className="w-full flex flex-col justify-between bg-white pt-1">
        <h6 className="text-lg text-secondary font-bold mt-2 mx-4 ">
          {transaction.Suscription.Nombre}
        </h6>
        {isAfter(todayDate, transaction.FechaPremium.toDate()) ? (
          <h3 className="text-base text-red-600 ml-4 font-bold">
            {transaction.FechaPremiumAnterior ? (
              `Vigencia: ${format(
                transaction.FechaPremiumAnterior.toDate(),
                "dd/MM/yyyy"
              )} - ${format(
                transaction.FechaPremium.toDate(),
                "dd/MM/yyyy"
              )}`
            ):(
              `Vigencia: ${format(
                transaction.FechaCreacion.toDate(),
                "dd/MM/yyyy"
              )} - ${format(
                transaction.FechaPremium.toDate(),
                "dd/MM/yyyy"
              )}`
            )}
            &nbsp;
            <span className="text-red-600 font-bold"></span>
          </h3>
        ) : (
          <h3 className="text-base text-greenDem font-bold ml-4">
            {transaction.FechaPremiumAnterior ? (
              `Vigencia: ${format(
                transaction.FechaPremiumAnterior.toDate(),
                "dd/MM/yyyy"
              )} - ${format(
                transaction.FechaPremium.toDate(),
                "dd/MM/yyyy"
              )}`
            ):(
              `Vigencia: ${format(
                transaction.FechaCreacion.toDate(),
                "dd/MM/yyyy"
              )} - ${format(
                transaction.FechaPremium.toDate(),
                "dd/MM/yyyy"
              )}`
            )}
          </h3>
        )}
        <div className="mx-4 font-bold">
          <p className=" text-secondary">
            Fecha Pago:
            <span className="text-gray-400">
              &nbsp;{format(
              transaction.FechaCreacion.toDate(),
              "dd/MM/yyyy")}
            </span>{" "}
          </p>
        </div>
        <div className="mx-4 font-bold pb-3">
          <p className=" text-secondary">
            Monto:
            <span className="text-gray-400">
              &nbsp;${transaction.Suscription.Precio}
            </span>{" "}
          </p>
        </div>
      </div>
      <div
        className={clsx(
          backgroundColorToolBar(),
          "h-16 w-full flex justify-around items-center"
        )}
      >
        <IconButton onClick={() => onSeeDetails(transaction)}>
          <SearchIcon size={20} className="text-secondary" strokeWidth={2.5} />
        </IconButton>
      </div>
    </motion.div>
    </div>
  );
};

import React from "react";
import clsx from "clsx";
import Ripples from "react-ripples";

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  text: string;
  color?: "primary" | "secondary";
  textColor?: string;
  insideClassName?: string;
  paddingClassname?: string;
  fullWidth?: boolean;
  isLoading?: boolean;
  disasbled?:boolean;
}

export const Button: React.FC<Props> = ({
  text,
  color,
  className,
  type = "button",
  insideClassName,
  fullWidth = false,
  isLoading = false,
  textColor = "text-white",
  paddingClassname,
  ...others
}) => {
  return (
    <Ripples
      className={`rounded-full ${fullWidth ? "w-full" : ""} ${className}`}
      during={1000}
    >
      <button
        type={type}
        className={clsx(
          `${
            color ? " bg-" + color + " hover:bg-" + color + "-light" : ""
          } rounded-full transition-colors duration-500 overflow-hidden w-full`,
          insideClassName,
          paddingClassname ? paddingClassname : "py-3 px-8"
        )}
        {...others}
        disabled={isLoading}
      >
        {isLoading ? (
          <div className="flex items-center justify-center">
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <span className="text-white text-sm font-semibold">
              Procesando...
            </span>
          </div>
        ) : (
          <p className={textColor}>{text}</p>
        )}
      </button>
    </Ripples>
  );
};

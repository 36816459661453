import {
  BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title,
  Tooltip
} from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";
import { Statistic } from "../../../models/Statistic";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  statistics: Statistic;
}
export const BudgetDashboard: React.FC<Props> = ({ statistics }) => {
  const backgounds = [
    "rgba(255, 99, 0, 0.5)",
    "rgba(0, 99, 255, 0.5)",
    "rgba(255, 255, 0, 0.5)",
    "rgba(0, 255, 0, 0.5)",
    "rgba(255, 0, 0, 0.5)",
  ];

  const options = {
    responsive: true,
    scales: {
      y: {
        ticks: {
          precision: 0,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Promedio Presupuesto Demanda/Oferta",
        font: {
          size: 18,
        },
      },
    },
  };

  const getName = (name: string) => {
    switch (name) {
      case "PromedioOfertas":
        return "Promedio Ofertas";
      case "PromedioSolicitudes":
        return "Promedio Solicitudes";
      case "PomedioSolicitudes":
        return "Promedio Solicitudes";

      default:
        break;
    }
  };
  const data = {
    labels: Object.entries(statistics).map((v) => getName(v[0])),
    datasets: [
      {
        data: Object.entries(statistics).map((v) => v[1]),
        backgroundColor: backgounds,
      },
    ],
  };



  return (
    <div className="pt-5 px-10 grid grid-cols-1 gap-10">
      <div style={{}}>
        <Bar options={options} data={data} />
      </div>
    </div>
  );
};

import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { AuthState } from "../redux/reducers/auth.reducer";
import { RootState } from "../redux/reducers/root.reducer";
import { auth } from "../configs/firebase";

// For routes that can only be accessed by "Demandante" type authenticated users
export const AuthGuard = ({ children }: any) => {
  const user = useSelector<RootState, AuthState>((state) => state.authReducer);
  const location = useLocation();

  localStorage.setItem("lastPath", location.pathname);


  if (!user?.user || user?.user?.TipoUsuario !== "Demandante") {
    return <Navigate to="/solicitudesActivas" />;
  }

  if(auth?.currentUser){
    const mailVerified = auth?.currentUser?.emailVerified;
    if(!mailVerified){
      return <Navigate to="/auth/validateMail" />;
    }
  }

  return children;
};
import {
  collection,
  DocumentData,
  documentId,
  getDocs,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  where,
} from "firebase/firestore";
import { firestore } from "../configs/firebase";

type QD = QueryDocumentSnapshot<DocumentData>;

/**
 * Esta funcion siempre es para obtener los elementos de una coleccion de forma eficiente.
 * Siempre se debe buscar dentro del arreglo que devuelve
 *
 * @param ids Set<string> de los ids que se quiera buscar
 * @param collectionName Nombre de la coleccion
 * @param mapedFunction Funcion opcional para realizar un mapeado propio
 * @returns
 */
export async function getMultipleDocsByIds<T = any>(
  ids: Set<string>,
  collectionName: string,
  mapedFunction?: (value: QD, index: number, array: QD[]) => T
) {
  const arrayIds = Array.from(ids);
  const docsSnapsArray = await Promise.all(
    new Array(Math.ceil(arrayIds.length / 10))
      .fill(0)
      .map((_) => arrayIds.splice(0, 10))
      .map((subArrayIds) =>
        getDocs(
          query(
            collection(firestore, collectionName),
            where(documentId(), "in", subArrayIds)
          )
        )
      )
  );

  const mapedDocs: T[] = docsSnapsArray
    .reduce((acc, value) => {
      return acc.concat(value.docs);
    }, [] as QD[])
    .map(
      mapedFunction
        ? mapedFunction
        : (x) => ({ ...(x.data() as any), id: x.id })
    );
  return mapedDocs;
}

export async function getDocsByIds<T = any>(
  idsSet: Set<string>,
  collectionName: string
) {
  if (idsSet.size === 0) return [];
  const arrayIds = Array.from(idsSet);
  const response = await Promise.all(
    new Array(Math.ceil(arrayIds.length / 10))
      .fill(0)
      .map((_) => arrayIds.splice(0, 10))
      .map((arrayIds) => {
        const ref = collection(firestore, collectionName);
        const q = query(ref, where(documentId(), "in", arrayIds));
        return getDocs(q);
      })
  );
  return response
    .reduce((acc, value: QuerySnapshot) => {
      return acc.concat(value.docs);
    }, [] as QueryDocumentSnapshot[])
    .map<T>((x) => ({ ...(x.data() as any), id: x.id }));
}

export const getFileNameByFirebaseURL = (url: string) => {

  const excep = /%20/gi;
  return url
    .split(RegExp("%2..*%2F(.*?)?alt"))[1]
    .split(".")[0]
    .split("?")[0]
    .replace(excep, "_")
    .replace("%", "_")
    .slice(0, 25);
};

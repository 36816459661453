import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { AuthState } from "../redux/reducers/auth.reducer";
import { RootState } from "../redux/reducers/root.reducer";
import { auth } from "../configs/firebase";
// For routes that can only be accessed by "Oferente" type authenticated users or non-authenticated users
export const PublicRequestGuard = ({ children }: any) => {
  const user = useSelector<RootState, AuthState>((state) => state.authReducer);
  const location = useLocation();

  localStorage.setItem("lastPath", location.pathname);

  if (user?.user && user?.user?.TipoUsuario !== "Oferente") {
    return <Navigate to="/" />;
  }

  if(auth?.currentUser){
    const mailVerified = auth?.currentUser?.emailVerified;
    if(!mailVerified){
      return <Navigate to="/auth/validateMail" />;
    }
  }
  
  

  return children;
};

import * as React from "react"

function CrossIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" {...props}>
      <path
        d="M30.54 26L50.18 6.36a3.26 3.26 0 000-4.62 3.26 3.26 0 00-4.62 0L25.92 21.38 6.28 1.74a3.26 3.26 0 00-4.62 0 3.26 3.26 0 000 4.62L21.3 26 1.66 45.64a3.26 3.26 0 000 4.62c.64.64 1.47.96 2.31.96s1.67-.32 2.31-.96l19.64-19.64 19.64 19.64c.64.64 1.47.96 2.31.96s1.67-.32 2.31-.96a3.26 3.26 0 000-4.62L30.54 26z"
        fill="#fff"
      />
    </svg>
  )
}

export default CrossIcon

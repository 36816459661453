import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { Navigation, Pagination } from "swiper";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/pagination/pagination.min.css";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper.min.css";
import "../../assets/css/swiper.css";
import { Button } from "../../components/Button";
import { FormState } from "../../models/form_state";
import { PlaintiffRequest } from "../../models/PlaintiffRequest";
import { getAdvertising } from "../../redux/actions/advertising.action";
import {
  getAllRequests,
  getPremiumRequests,
  siguienteTodo
} from "../../redux/actions/requests.actions";
import { AdvertisingState } from "../../redux/reducers/advertising.reducer";
import { RequestsState } from "../../redux/reducers/requests.reducer";
import { RootState } from "../../redux/reducers/root.reducer";
import { OffererRequestCard } from "./components/OffererRequestCard";
import { AuthState } from "../../redux/reducers/auth.reducer";
import { useNavigate } from "react-router-dom";

//cambiar icono de la esquina por total de ofertas de la solicitud (solo premium)
export const OffererListRequests = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [open, setOpen] = useState(false);
  const auth = useSelector<RootState, AuthState>((state) => state.authReducer);
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [selectedRequest, setSelectedRequest] =
    useState<null | PlaintiffRequest>(null);

  const [actualWidth, setActualWidth] = useState(window.innerWidth);

  const resizeHandler = () => {
    const width = window.innerWidth;

    setActualWidth(width);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
  }, []);

  useEffect(() => {
    dispatch(getAdvertising());
  }, []);

  const { state: advertisingState, advertisings } = useSelector<
    RootState,
    AdvertisingState
  >((state) => state.advertisingReducer);
  const todayDate = new Date();
  const AdvertisingSup = advertisings.filter(
    (x) =>
      x.Vista === "Búsqueda Solicitud" &&
      x.Posicion === "superior" /* &&
      (isAfter(todayDate, new Date(x.FechaInicio)) || isEqual(todayDate, new Date(x.FechaInicio))) &&
      (isAfter(new Date(x.FechaTermino), todayDate)|| isEqual(todayDate, new Date(x.FechaTermino))) */
  );
  const AdvertisingBot = advertisings.filter(
    (x) =>
      x.Vista === "Búsqueda Solicitud" &&
      x.Posicion === "inferior"/* &&
      isAfter(todayDate, new Date(x.FechaInicio)) &&
      isAfter(new Date(x.FechaTermino), todayDate) */
  );

  const handleFeaturedClick = (request: PlaintiffRequest) => {
    setOpen(true);
    setSelectedRequest(request);
  };

  const { state, totalDocs, requests, requestsPremium, order, orderBy } = useSelector<
    RootState,
    RequestsState
  >((state) => state.requestReducer);


  useEffect(() => {
    if(auth && auth.user?.TipoUsuario === "Demandante"){
      navigate("/solicitudesActivas")
    }
    dispatch(getAllRequests());
    dispatch(getPremiumRequests());
    // eslint-disable-next-line
  }, []);

  const siguiente = (total: any) => {
    dispatch(siguienteTodo(total, order, orderBy!));
  };

  return (
    <main className="py-10 px-8 lg:container lg:mx-auto">
      <Helmet title="Resultados Encontrados" />
      <h1 className=" text-3xl font-bold text-deepBlue ">
        {`Resultados Encontrados (${totalDocs})`}
      </h1>
      {AdvertisingSup.length === 1 && (
        <a href={AdvertisingSup[0].Link} target={"_blank"}>
          {actualWidth > 400 ? (
            <img
              src={AdvertisingSup[0].Imagen.URL}
              className="mx-auto rounded-md mt-2 object-cover   md:visible lg:visible"
              style={{ width: 1309, height: 150 }}
            ></img>
          ) : (
            <img
              src={AdvertisingSup[0].ImagenMovil.URL}
              className="mx-auto rounded-md mt-7 object-cover visible sm:visible md:hidden lg:hidden"
              style={{ width: 1309, height: 150 }}
            ></img>
          )}
        </a>
      )}
      <div>
        <Swiper
          slidesPerView={1}
          spaceBetween={10}
          slidesPerGroup={1}
          loop={true}
          loopFillGroupWithBlank={true}
          pagination={{
            clickable: true,
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper"
          style={{ height: "auto" }}
        >
          <SwiperSlide>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 xl:grid-cols-4 2xl:grid-cols-5 mt-8">
              <React.Fragment>
                <div className="swiper-button-next my-24"></div>
                <div className="swiper-button-prev my-24"></div>
                {requestsPremium.map((request, index) => (
                  <OffererRequestCard
                    key={`request-${index}`}
                    request={request}
                    onFeaturedClick={handleFeaturedClick}
                  />
                ))}
              </React.Fragment>
            </div>
          </SwiperSlide>
        </Swiper>
        {state === FormState.Submitting || state === FormState.Initial ? (
          new Array(5).fill(0).map((value, i) => {
            return (
              <div
                className="h-96 bg-white shadow-md rounded-xl overflow-hidden"
                key={`skeleton-div-${i}`}
              >
                <div className="w-full h-1/2 bg-gray-200 animate-pulse"></div>

                <div className="w-full h-1/2 flex flex-col ">
                  <div className="w-3/4 h-4 pulse rounded-full bg-gray-200 mt-2 mx-4"></div>
                  <div className="mx-4 mt-10">
                    <div className="w-3/4 h-3 pulse rounded-full bg-gray-200"></div>
                    <div className="w-2/4 h-2 pulse rounded-full bg-gray-200 mt-2"></div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 xl:grid-cols-4 2xl:grid-cols-5 mt-8">
            <React.Fragment>
              {requests.map((request, index) => (
                <OffererRequestCard
                  key={`request-${index}`}
                  request={request}
                  onFeaturedClick={handleFeaturedClick}
                />
              ))}
            </React.Fragment>
          </div>
        )}
      </div>

      <div className="mt-10 flex items-center justify-center ">
        <Button
          type="submit"
          text="Cargar más solicitudes"
          color="secondary"
          className="mt-10  flex items-center justify-center "
          onClick={() => siguiente(totalDocs)}
        />
      </div>
      {AdvertisingBot.length === 1 && (
        <a href={AdvertisingBot[0].Link} target={"_blank"}>
          {actualWidth > 400 ? (
            <img
              src={AdvertisingBot[0].Imagen.URL}
              className="mx-auto rounded-md mt-5 object-cover   md:visible lg:visible"
              style={{ width: 1309, height: 150 }}
            ></img>
          ) : (
            <img
              src={AdvertisingBot[0].ImagenMovil.URL}
              className="mx-auto rounded-md mt-5 object-cover visible sm:visible md:hidden lg:hidden"
              style={{ width: 1309, height: 150 }}
            ></img>
          )}{" "}
        </a>
      )}
    </main>
  );
};

import { format } from "date-fns";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import {
  Calendar as CalendarIcon,
  MapPin,
  Tag as TagIcon,
} from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LoadingCard } from "../../components/detailsRequestComponents/LoadingCard";
import { OfferSection } from "../../components/detailsRequestComponents/OfferSection";
import { ImagesDisplay } from "../../components/ImagesDisplay";
import { MAPS_API_KEY } from "../../configs/constants";
import { FormState } from "../../models/form_state";
import { PlaintiffOffer } from "../../models/PlaintiffOffer";
import { AcceptOffer, PlaintiffRequest } from "../../models/PlaintiffRequest";
import { getAdvertising } from "../../redux/actions/advertising.action";
import { getOffer } from "../../redux/actions/offer.action";
import {
  getAcceptOffer,
  getOneRequest,
} from "../../redux/actions/requests.actions";
import { AdvertisingState } from "../../redux/reducers/advertising.reducer";
import { RequestsState } from "../../redux/reducers/requests.reducer";
import { RootState } from "../../redux/reducers/root.reducer";

export const DetailsRequest = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { selected, state } = useSelector<RootState, RequestsState>(
    (state) => state.requestReducer
  );

  useEffect(() => {
    dispatch(getAdvertising());
  }, []);

  const [actualWidth, setActualWidth] = useState(window.innerWidth);

  const resizeHandler = () => {
    const width = window.innerWidth;

    setActualWidth(width);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
  }, []);
  const { state: advertisingState, advertisings } = useSelector<
    RootState,
    AdvertisingState
  >((state) => state.advertisingReducer);
  const AdvertisingSup = advertisings.filter(
    (x) => x.Vista === "Detalle Solicitud" && x.Posicion === "superior"
  );
  const AdvertisingBot = advertisings.filter(
    (x) => x.Vista === "Detalle Solicitud" && x.Posicion === "inferior"
  );

  useEffect(() => {
    if (!selected) {
      dispatch(getOneRequest(id!));
    } else {
      if (
        selected.OfertaAceptada &&
        !(selected.OfertaAceptada as PlaintiffOffer).Usuario
      ) {
        dispatch(
          getAcceptOffer(
            selected.id,
            (selected.OfertaAceptada as AcceptOffer).OfertaId
          )
        );
      } else {
        dispatch(getOffer(selected.id));
      }
    }
    // eslint-disable-next-line
  }, [selected, id]);

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="py-10 sm:px-14 px-4"
    >
      <Helmet title="Detalle Solicitud" />
      {AdvertisingSup.length === 1 && (
        <a href={AdvertisingSup[0].Link} target={"_blank"}>
          {actualWidth > 400 ? (
            <img
              src={AdvertisingSup[0].Imagen.URL}
              className="mx-auto rounded-md mt-2 object-cover   md:visible lg:visible"
              style={{ width: 1309, height: 150 }}
            ></img>
          ) : (
            <img
              src={AdvertisingSup[0].ImagenMovil.URL}
              className="mx-auto rounded-md mt-7 object-cover visible sm:visible md:hidden lg:hidden"
              style={{ width: 1309, height: 150 }}
            ></img>
          )}{" "}
        </a>
      )}
      {selected ? (
        <div className="grid sm:grid-cols-2 gap-4 grid-cols-1">
          <div>
            <h1 className="text-secondary font-bold text-3xl mb-2">
              {selected.Titulo}
            </h1>
            <h2 className=" font-bold text-lg text-gray-400">
              <span className="text-primary text-xl text-bold">{`$${selected.Precio.toLocaleString(
                "es"
              )} `}</span>
              {`${selected.Unidad.Cantidad} ${selected.Unidad.Tipo}`}
            </h2>
            <h3 className="text-base text-gray-400 flex flex-col mb-5">
              <span className="flex items-center mr-2">
                <MapPin
                  size={13}
                  className="text-deepBlue mr-2"
                  strokeWidth={2.5}
                />
                {selected.Distancia
                  ? selected.Distancia >= 1000
                    ? `${(selected.Distancia / 1000).toFixed(1)} km`
                    : `${selected.Distancia} m`
                  : ""}
                · {selected.Region} · {selected.Comuna}
              </span>
              <span className="font-bold flex items-center">
                <TagIcon
                  size={13}
                  className="text-deepBlue mr-2"
                  strokeWidth={2.5}
                />

                {selected.Categoria}
              </span>
            </h3>

            <ImagesDisplay images={selected.Imagenes} />

            <div>
              <h4 className="text-lg font-bold text-primary mb-3">
                Ubicación:
              </h4>
              <p className="flex items-center mb-3">
                <MapPin
                  size={21}
                  className="text-deepBlue mr-2"
                  strokeWidth={2.5}
                />
                <span>{selected.Direccion}</span>
              </p>
              <img
                src={`https://maps.googleapis.com/maps/api/staticmap?language=es&markers=${
                  selected.Ubicacion.lat + "," + selected.Ubicacion.lng
                }&size=500x500&key=${MAPS_API_KEY}`}
                className="h-52 w-full object-cover rounded-xl"
                alt="google-map"
              />
            </div>

            <div className="mt-5">
              <h4 className="text-lg font-bold text-primary mb-3">
                Fecha inicio y término:
              </h4>
              <div className="flex text-base text-gray-400 font-semibold">
                <CalendarIcon
                  size={21}
                  className="text-deepBlue mr-2"
                  strokeWidth={2.5}
                />
                <p>
                  <span className="text-primary">Fecha Inicio: </span>
                  {format(new Date(selected.FechaInicio), "dd/MM/yyyy")}
                </p>
              </div>
              <div className="flex text-base text-gray-400 font-semibold mt-1">
                <CalendarIcon
                  size={21}
                  className="text-deepBlue mr-2"
                  strokeWidth={2.5}
                />
                <p>
                  <span className="text-primary">Fecha Término: </span>
                  {format(new Date(selected.FechaTermino), "dd/MM/yyyy")}
                </p>
              </div>
            </div>

            <div className="mt-5">
              <h4 className="text-lg font-bold text-primary mb-2">
                Descripción:{" "}
              </h4>
              <p className="text-base font-medium text-gray-400">
                {selected.Descripcion}
              </p>
            </div>
          </div>
          <div>
            {state === FormState.Submitting && <LoadingCard />}
            {state === FormState.Success && <OfferSection request={selected} />}
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {AdvertisingBot.length === 1 && (
        <a href={AdvertisingBot[0].Link} target={"_blank"}>
          {actualWidth > 400 ? (
            <img
              src={AdvertisingBot[0].Imagen.URL}
              className="mx-auto rounded-md mt-5 object-cover   md:visible lg:visible"
              style={{ width: 1309, height: 150 }}
            ></img>
          ) : (
            <img
              src={AdvertisingBot[0].ImagenMovil.URL}
              className="mx-auto rounded-md mt-7 object-cover visible sm:visible md:hidden lg:hidden"
              style={{ width: 1309, height: 150 }}
            ></img>
          )}{" "}
        </a>
      )}
    </motion.main>
  );
};

import { isBefore } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps";
import { useDispatch, useSelector } from "react-redux";
import { compose, withProps } from "recompose";
import { CustomOverlayMap } from "../../components/CustomOverlayMap";
import { FilterOffererGeolocation } from "../../components/FilterOffererGeolocation";
import { MAPS_API_KEY } from "../../configs/constants";
import { useLocation } from "../../hooks/useLocation";
import { FormState } from "../../models/form_state";
import { Location } from "../../models/PlacesDetailsResponse";
import { PlaintiffRequest } from "../../models/PlaintiffRequest";
import { getAllRequestsByLocation } from "../../redux/actions/requests.actions";
import { AdvertisingState } from "../../redux/reducers/advertising.reducer";
import { RequestsState } from "../../redux/reducers/requests.reducer";
import { RootState } from "../../redux/reducers/root.reducer";

export const OffererGeolocation = () => {
  const { requests, state } = useSelector<RootState, RequestsState>(
    (state) => state.requestReducer
  );

  //obtener la ubicación del usuario
  const { getLocation, currentLocation } = useLocation();
  const mapRef = useRef<GoogleMap>(null);
  const [coords, setCoords] = useState<Location>();
  const [currentZoom, setCurrentZoom] = useState(13);
  const dispatch = useDispatch();

  /* const { state: advertisingState, advertisings } = useSelector<
    RootState,
    AdvertisingState
  >((state) => state.advertisingReducer);
  const AdvertisingSup = advertisings.filter(
    (x) => x.Vista === "Búsqueda Solicitud" && x.Posicion === "superior"
  );
  const AdvertisingBot = advertisings.filter(
    (x) => x.Vista === "Búsqueda Solicitud" && x.Posicion === "inferior"
  ); */

  useEffect(() => {
    if (mapRef.current && coords) {
      dispatch(getAllRequestsByLocation(coords));
      //TODO: Loading
      //TODO: En caaso de no haber nada mostrar mensaje en algun lado
      mapRef.current.panTo(coords);
    }
    // eslint-disable-next-line
  }, [coords]);

  useEffect(() => {
    getLocation();
    // eslint-disable-next-line
  }, []);

  return (
    <main className="container mx-auto mt-auto">
      <div className="">
        <FilterOffererGeolocation setCoords={setCoords} />
        {/* {requests.length === 0 && <div className="">No se encontraron ofertas</div>} */}
        <MapContainer
          mapRef={mapRef}
          currentLocation={
            currentLocation || {
              lat: -33.4482310614341,
              lng: -70.64685716363459,
            }
          }
          currentZoom={currentZoom}
          setCurrentZoom={setCurrentZoom}
          state={state}
          requests={requests}
        ></MapContainer>
      </div>
    </main>
  );
};

interface MapProps {
  mapRef: React.RefObject<GoogleMap>;
  currentLocation: any;
  setCurrentZoom: React.Dispatch<React.SetStateAction<number>>;
  currentZoom: number;
  state: FormState;
  requests: PlaintiffRequest[];
}
const isPremium = (fechaPremium: any) =>
  fechaPremium ? isBefore(new Date(), fechaPremium.toDate()) : false;

const MapContainer = compose<any, MapProps>(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,places&key=${MAPS_API_KEY}`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `75vh` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props: MapProps) => {
  const dispatch = useDispatch()
  return (
    <GoogleMap
      defaultOptions={{
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        scrollwheel: true,
        mapTypeId: "roadmap",
        styles: [
          {
            featureType: "all",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "on",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      }}
      defaultZoom={13}
      onDragEnd={() => {
        dispatch(
          getAllRequestsByLocation({
            lat: props.mapRef.current?.getCenter().lat() || 0,
            lng: props.mapRef.current?.getCenter().lng() || 0,
          })
        );
      }}
      defaultCenter={props.currentLocation}
      onZoomChanged={() => {
        if (props.mapRef.current) {
          const z = props.mapRef.current?.getZoom();
          props.setCurrentZoom(z);
        }
      }}
      ref={props.mapRef}
      clickableIcons={false}
    >
      {props.state === FormState.Success &&
        props.currentZoom >= 14 &&
        props.requests
          .filter((r) => !isPremium(r.FechaPremium))
          .map((request: any, index: number) => {
            return (
              <CustomOverlayMap
                key={`markerRequest-${index}`}
                position={request.Ubicacion}
                zoom={props.currentZoom}
                request={request}
              />
            );
          })}
      {props.state === FormState.Success &&
        props.requests
          .filter((r) => isPremium(r.FechaPremium))
          .map((request: any, index: number) => {
            return (
              <CustomOverlayMap
                key={`markerRequest-${index}`}
                position={request.Ubicacion}
                zoom={props.currentZoom}
                request={request}
              />
            );
          })}
    </GoogleMap>
  );
});

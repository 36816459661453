import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { AuthState } from "../redux/reducers/auth.reducer";
import { RootState } from "../redux/reducers/root.reducer";

export const PublicGuard = ({ children }: any) => {
  const auth = useSelector<RootState, AuthState>((state) => state.authReducer);

  if (auth?.user) {
    return (
      <Navigate
        to={
          localStorage.getItem("lastPath") ||
          auth?.user?.TipoUsuario === "Demandante"
            ? "/demandante/solicitudesRealizadas"
            : "/solicitudesActivas"
        }
      />
    );
  }

  return children;
};

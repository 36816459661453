import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import * as yup from "yup";
import KeyOutlinedIcon from "../assets/icons/KeyOutlinedIcon";
import UserOutlinedIcon from "../assets/icons/UserOutlinedIcon";
import { FormState } from "../models/form_state";
import { resetContraseña, signIn } from "../redux/actions/auth.actions";
import { AuthState } from "../redux/reducers/auth.reducer";
import { RootState } from "../redux/reducers/root.reducer";
import { Alert } from "./Alert";
import { Button } from "./Button";
import { TextField } from "./TextField";

interface Props {}

export const LoginForm: React.FC<Props> = () => {
  const dispatch = useDispatch();

  const {
    login: { state, error },
  } = useSelector<RootState, AuthState>((state) => state.authReducer);

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues: {
      Email: "",
      Password: "",
    },
    onSubmit: (values) => {
      dispatch(signIn(values));
    },
    validationSchema: yup.object().shape({
      Email: yup
        .string()
        .required("Correo requerido")
        .email("Correo no válido"),
      Password: yup.string().required("Contraseña requerida"),
    }),
  });

  return (
    <React.Fragment>
      {error && (
        <Alert severity="error" className="mb-5">
          {error}
        </Alert>
      )}
      {show === false ? (
        <div>
          <form onSubmit={handleSubmit} className="w-full">
            <TextField
              id="Email"
              placeholder="Correo"
              type="email"
              icon={<UserOutlinedIcon height={28} width={28} />}
              divClassName="mb-5 w-full"
              outlined={false}
              value={values.Email}
              onChange={handleChange}
              helperText={touched.Email && errors.Email}
              error={touched.Email && Boolean(errors.Email)}
            />
            <TextField
              id="Password"
              placeholder="Contraseña"
              icon={<KeyOutlinedIcon height={28} width={28} />}
              type="password"
              divClassName="w-full"
              outlined={false}
              value={values.Password}
              onChange={handleChange}
              helperText={touched.Password && errors.Password}
              error={touched.Password && Boolean(errors.Password)}
            />
            <Button
              text="Ingresar"
              color="secondary"
              className="w-full mt-5"
              fullWidth
              type="submit"
              isLoading={state === FormState.Submitting}
            />
          </form>

          <Button
            text="¿Olvidaste tu contraseña?"
            className="mb-4 mt-1"
            insideClassName="py-2 px-5"
            fullWidth
            onClick={() => navigate("/recoveryPassword")}
          />

          <h4 className="text-md font-semibold text-white self-start">
            ¿No tienes cuenta?
          </h4>
          <Button
            text="Regístrate Aquí"
            className="w-full mt-3 bg-button"
            fullWidth
            onClick={handleShow}
          />
        </div>
      ) : (
        <div>
          <h1 className="text-lg text-white font-bold ml-5">
            Seleccione tipo de registro:
          </h1>

          <Link to={"/auth/regitroDemandante"}>
            <Button
              text="Demandante"
              className="w-full mt-3 bg-button"
              fullWidth
            />
          </Link>

          <Link to={"/auth/regitroOferente"}>
            <Button
              text="Ofertante"
              className="w-full mt-3 bg-button"
              fullWidth
            />
          </Link>
        </div>
      )}
    </React.Fragment>
  );
};

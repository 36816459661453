import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Edit2 as EditIcon, ThumbsDown, X } from "react-feather";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../components/Button";
import { IconButton } from "../../components/IconButton";
import { RequestDetailsSection } from "../../components/RequestDetailsSection";
import { FormState } from "../../models/form_state";
import { AcceptOffer } from "../../models/PlaintiffRequest";
import { getAdvertising } from "../../redux/actions/advertising.action";
import {
  addReporte,
  getOneOfferAndRequest,
  setSelectedOffer,
} from "../../redux/actions/offer.action";
import { AdvertisingState } from "../../redux/reducers/advertising.reducer";
import { OfferState } from "../../redux/reducers/offer.reducer";
import { RequestsState } from "../../redux/reducers/requests.reducer";
import { RootState } from "../../redux/reducers/root.reducer";
import { OfferLoadingCard } from "./components/OfferLoadingCard";
import { OfferSectionFromOfferList } from "./components/OfferSectionFromOfferList";
import { CustomSelect } from "../../components/CustomSelect";
import { Modal } from "../../components/Modal";
import { useFormik } from "formik";
import * as yup from "yup";

export const DetailsRequestFromOfferList = () => {
  const dispatch = useDispatch();

  const { offerId, requestId } = useParams();

  const {
    selected: selectedRequest,
    edit: { state: RequestState },
  } = useSelector<RootState, RequestsState>((state) => state.requestReducer);

  const { selected: selectedOffer } = useSelector<RootState, OfferState>(
    (state) => state.offerReducer
  );

  useEffect(() => {
    if (
      selectedRequest?.OfertaAceptada &&
      (selectedRequest?.OfertaAceptada as AcceptOffer).OfertaId ===
        selectedOffer.id
    ) {
      dispatch(getOneOfferAndRequest(selectedRequest!.id, selectedOffer.id));
    }
    // eslint-disable-next-line
  }, [selectedRequest]);

  useEffect(() => {
    dispatch(getAdvertising());
  }, []);

  const [actualWidth, setActualWidth] = useState(window.innerWidth);

  const resizeHandler = () => {
    const width = window.innerWidth;

    setActualWidth(width);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
  }, []);

  const { state: advertisingState, advertisings } = useSelector<
    RootState,
    AdvertisingState
  >((state) => state.advertisingReducer);
  const AdvertisingSup = advertisings.filter(
    (x) => x.Vista === "Detalle Solicitud" && x.Posicion === "superior"
  );
  const AdvertisingBot = advertisings.filter(
    (x) => x.Vista === "Detalle Solicitud" && x.Posicion === "inferior"
  );

  const navigate = useNavigate();
  const GoToEdit = () => {
    dispatch(setSelectedOffer(selectedOffer));
    navigate("/oferente/editar/" + selectedOffer.id);
  };

  const { handleSubmit, values, handleChange, touched, setFieldValue, errors } =
    useFormik({
      initialValues: {
        Reportes: "",
      },
      onSubmit: () => {
        dispatch(addReporte(values, requestId));
      },
      validationSchema: yup.object({
        Reportes: yup.object().required("Selecciona motivo"),
      }),
    });
  const [openReportarModal, setOpenReportarModal] = useState(false);

  const handleReportar = () => {
    setOpenReportarModal(true);
  };

  const handleCloseReportar = () => {
    setOpenReportarModal(false);
  };

  const reportes = [
    "Desnudos",
    "Violencia",
    "Informacion Falsa",
    "Lenguaje Ofensivo",
    "Venta no autorizada",
    "Otros",
  ];

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="py-10 sm:px-14 px-4"
    >
      <Helmet title="Detalle Solicitud" />
      {AdvertisingSup.length === 1 && (
        <a href={AdvertisingSup[0].Link} target={"_blank"}>
          {actualWidth > 400 ? (
            <img
              src={AdvertisingSup[0].Imagen.URL}
              className="mx-auto rounded-md mt-2 object-cover   md:visible lg:visible"
              style={{ width: 1309, height: 150 }}
            ></img>
          ) : (
            <img
              src={AdvertisingSup[0].ImagenMovil.URL}
              className="mx-auto rounded-md mt-7 object-cover visible sm:visible md:hidden lg:hidden"
              style={{ width: 1309, height: 150 }}
            ></img>
          )}{" "}
        </a>
      )}
      {selectedRequest ? (
        <div className="grid sm:grid-cols-2 gap-4">
          <div>
            <RequestDetailsSection request={selectedRequest} />
          </div>
          <div className="flex flex-col">
            <div className="bg-primary-50 flex flex-row rounded-xl py-3 px-3 items-center self-end">
              <div className="flex flex-row justify-center">
                <IconButton>
                  <ThumbsDown className="text-deepBlue mt-3" size={25} />
                </IconButton>
                <Button
                  text="Reportar"
                  textColor="text-deepBlue font-bold"
                  className="text-lg"
                  paddingClassname="p-2"
                  onClick={handleReportar}
                ></Button>
              </div>

              <div className="flex flex-row ml-2">
                <IconButton onClick={GoToEdit}>
                  <EditIcon className="text-deepBlue" size={26} />
                </IconButton>
                <Button
                  text="Editar"
                  textColor="text-deepBlue font-bold"
                  insideClassName="text-lg lg:block hidden"
                  paddingClassname="p-2"
                  onClick={GoToEdit}
                ></Button>
              </div>
            </div>
            {RequestState === FormState.Submitting ? (
              <OfferLoadingCard />
            ) : (
              <OfferSectionFromOfferList
                selectedRequest={selectedRequest}
                selectedOffer={selectedOffer}
              />
            )}
            <Modal
              isVisible={openReportarModal}
              onClose={() => {}}
              isDismiseble
            >
              <div className="lg:w-96 ">
                <form onSubmit={handleSubmit} className="w-96">
                  <div className="bg-white rounded-lg w-auto shadow-lg pb-5">
                    <div className="flex flex-row-reverse">
                      <IconButton onClick={handleCloseReportar}>
                        <X size={32} className="text-secondary" />
                      </IconButton>
                    </div>
                    <div className="flex flex-col items-center ">
                      <h1 className="font-bold text-2xl text-deepBlue">
                        Reportar solicitud
                      </h1>
                      {/* <h2 className="font-semibold text-primary text-lg">
                  Motivos
                </h2> */}

                      {/* <p className="font-bold text-4xl mt-4 text-deepBlue">Nada</p> */}

                      <CustomSelect
                        className="w-60 text-center"
                        label="Motivos"
                        id="Reportes"
                        placeholder="Selecciona una categoria"
                        options={reportes.map((motivo) => ({
                          label: motivo,
                          value: motivo,
                        }))}
                        onChange={(value: any) => {
                          setFieldValue("Reportes", value);
                        }}
                        helperText={touched.Reportes && errors.Reportes}
                        error={touched.Reportes && Boolean(errors.Reportes)}
                        value={values.Reportes}
                        isSearchable
                        autoFocus
                      />

                      {/*  <img
                  src="/assets/img/logo-web-pay-plus.png"
                  alt="imagen-webpay"
                  className="w-2/4"
                /> */}
                      <Button
                        type="submit"
                        text="Enviar"
                        className="bg-primary w-2/4 mt-6"
                        onClick={handleCloseReportar}
                        /* isLoading={isLoadingPayment} */
                      />
                    </div>
                  </div>
                </form>
              </div>
            </Modal>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      {AdvertisingBot.length === 1 && (
        <a href={AdvertisingBot[0].Link} target={"_blank"}>
          {actualWidth > 400 ? (
            <img
              src={AdvertisingBot[0].Imagen.URL}
              className="mx-auto rounded-md mt-2 mb-5 object-cover   md:visible lg:visible"
              style={{ width: 1309, height: 150 }}
            ></img>
          ) : (
            <img
              src={AdvertisingBot[0].ImagenMovil.URL}
              className="mx-auto rounded-md mt-7 mb-5 object-cover visible sm:visible md:hidden lg:hidden"
              style={{ width: 1309, height: 150 }}
            ></img>
          )}{" "}
        </a>
      )}
    </motion.main>
  );
};

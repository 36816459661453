import { useFormik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import regionesComunas from "../assets/RegionesComunas.json";
import { Alert } from "../components/Alert";
import { AutocompletePlaces } from "../components/AutocompletePlaces";
import { Button } from "../components/Button";
import { MenuItem } from "../components/MenuItem";
import { Modal } from "../components/Modal";
import { Select } from "../components/Select";
import { TextField } from "../components/TextField";
import { FormState } from "../models/form_state";
import { getAdvertising } from "../redux/actions/advertising.action";
import { registerPlaintiff } from "../redux/actions/auth.actions";
import { AdvertisingState } from "../redux/reducers/advertising.reducer";
import { AuthState } from "../redux/reducers/auth.reducer";
import { RootState } from "../redux/reducers/root.reducer";

export const PlaintiffRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    plaintiffRegister: { state: registerState, error: emailExists },
  } = useSelector<RootState, AuthState>((state) => state.authReducer);

  const {
    handleSubmit,
    values,
    handleChange,
    touched,
    setFieldValue,
    handleBlur,
    errors,
  } = useFormik({
    initialValues: {
      Nombre: "",
      Apellido: "",
      Region: "",
      FechaNacimiento: "",
      Comuna: "",
      Email: "",
      Contrasena: "",
      RepetirContrasena: "",
      Direccion: "",
      Telefono: "",
      TerminosYCondiciones: null,
      PlaceId: "",
      Ubicacion: {},
    },
    onSubmit: (values) => {
      dispatch(
        registerPlaintiff({
          ...values,
          TerminosYCondiciones: Boolean(values.TerminosYCondiciones),
        } as any)
      );
    },
    validationSchema: yup.object({
      Nombre: yup.string().required("Nombre  es Requerido"),
      Apellido: yup.string().required("Apellido es Requerido"),
      Region: yup.string().required("Region es Requerida"),
      Comuna: yup.string().required("Comuna es Requerida"),
      Contrasena: yup
        .string()
        .min(6, "Contrseña demasiado corta")
        .required("Contraseña requerida"),
      RepetirContrasena: yup
        .string()
        .max(255)
        .min(6, "Contraseña demasiado corta")
        .oneOf([yup.ref("Contrasena"), null], "Contraseñas no coinciden")
        .required("Contraseña Requerida"),
      FechaNacimiento: yup
        .string()
        .required("Tu fecha de Nacimiento es Requerida"),
      Direccion: yup.string().required("Tu Dirección es Requerida"),
      Email: yup.string().email("Email no válido").required("Email requerido"),
      Telefono: yup
        .string()
        .required("Es requerido")
        .matches(
          new RegExp("[0-9]{9}"),
          "Debe ingresar un numero en formato 912345678"
        ),
      TerminosYCondiciones: yup
        .boolean()
        .nullable()
        .required("No ha aceptado los términos y condiciones"),
    }),
  });
  const [open, setOpen] = useState(false);

  const handleOnClose = () => {
    setOpen(false);
  };

  const [actualWidth, setActualWidth] = useState(window.innerWidth);

  const resizeHandler = () => {
    const width = window.innerWidth;

    setActualWidth(width);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
  }, []);

  useEffect(() => {
    dispatch(getAdvertising());
  }, []);

  const { state: advertisingState, advertisings } = useSelector<
    RootState,
    AdvertisingState
  >((state) => state.advertisingReducer);
  const AdvertisingSup = advertisings.filter(
    (x) => x.Vista === "Registro" && x.Posicion === "superior"
  );
  const AdvertisingBot = advertisings.filter(
    (x) => x.Vista === "Registro" && x.Posicion === "inferior"
  );

  useEffect(() => {
    if (registerState === FormState.Success) {
      setOpen(true);
    }
  }, [registerState]);

  useEffect(() => {
    if (registerState === FormState.Submitting) {
    }
  }, [registerState]);

  const handleShowTerms = () => {};

  return (
    <React.Fragment>
      <div className="h-14 w-full bg-primary fixed z-10 shadow-md ">
        <h1 className="text-white text-xl md:text-3xl font-semibold text-center mt-2">
          Registrate como demandante
        </h1>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="container mx-auto mt-5"
      >
        <div>
          <h1 className=" text-3xl font-bold text-deepBlue ">
            Registro Demandante
          </h1>
        </div>
        {AdvertisingSup.length === 1 && (
          <a href={AdvertisingSup[0].Link} target={"_blank"}>
            {actualWidth > 400 ? (
              <img
                src={AdvertisingSup[0].Imagen.URL}
                className="mx-auto rounded-md mt-10 object-cover"
                style={{ width: 1309, height: 150 }}
              ></img>
            ) : (
              <img
                src={AdvertisingSup[0].ImagenMovil.URL}
                className="mx-auto rounded-md mt-7 object-cover "
                style={{ width: 1309, height: 150 }}
              ></img>
            )}
          </a>
        )}
        <form onSubmit={handleSubmit} className="my-10">
          {touched.TerminosYCondiciones && errors.TerminosYCondiciones && (
            <AnimatePresence
              initial={false}
              exitBeforeEnter={true}
              onExitComplete={() => null}
            >
              <Alert severity="error" className="my-5">
                {"Debes aceptar los términos y condiciones"}
              </Alert>
            </AnimatePresence>
          )}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 justify-between px-6 md:px-0 mx-5">
            <TextField
              label="Nombre"
              placeholder="Ingresa tu Nombre"
              id="Nombre"
              value={values.Nombre}
              onChange={handleChange}
              helperText={touched.Nombre && errors.Nombre}
              error={touched.Nombre && Boolean(errors.Nombre)}
              required
            />
            <TextField
              label="Apellido"
              placeholder="Ingresa tu Apellido"
              id="Apellido"
              value={values.Apellido}
              onChange={handleChange}
              helperText={touched.Apellido && errors.Apellido}
              error={touched.Apellido && Boolean(errors.Apellido)}
              required
            />
            <TextField
              label="Fecha De Nacimiento"
              placeholder="Ingresa tu Fecha de Nacimiento"
              id="FechaNacimiento"
              type="date"
              value={values.FechaNacimiento}
              onChange={handleChange}
              helperText={touched.FechaNacimiento && errors.FechaNacimiento}
              error={touched.FechaNacimiento && Boolean(errors.FechaNacimiento)}
              required
            />
            <TextField
              id="Telefono"
              label="Teléfono"
              placeholder="Ingresa tu Numero de teléfono"
              value={values.Telefono}
              onChange={handleChange}
              maxLength={9}
              helperText={touched.Telefono && errors.Telefono}
              error={touched.Telefono && Boolean(errors.Telefono)}
              onBlur={handleBlur}
              required
            />
            <AutocompletePlaces
              label="Dirección"
              id="Direccion"
              disabled={false}
              helperText={touched.Direccion && errors.Direccion}
              error={touched.Direccion && Boolean(errors.Direccion)}
              onChange={(place) => {
                if (place) {
                  const address = place.formatted_address;
                  const placeId = place.place_id;
                  const location = {
                    lat: place.geometry?.location?.lat(),
                    lng: place.geometry?.location?.lng(),
                  };
                  let region = "";
                  let city = "";

                  place.address_components?.forEach((component) => {
                    // Region
                    if (
                      component.types.includes("administrative_area_level_1")
                    ) {
                      region = component.long_name;
                    }
                    // Ciudad
                    if (
                      component.types.includes("administrative_area_level_3")
                    ) {
                      city = component.long_name;
                    }
                  });

                  const findedRegion = regionesComunas.regiones.find((r) =>
                    r.region
                      .toLocaleLowerCase()
                      .match(region.toLocaleLowerCase())
                  );
                  const findedCity = findedRegion?.comunas.find((c) =>
                    c.toLocaleLowerCase().match(city.toLocaleLowerCase())
                  );

                  setFieldValue("Direccion", address, false);
                  setFieldValue("Ubicacion", location, false);
                  setFieldValue("PlaceId", placeId, false);
                  setFieldValue("Region", findedRegion?.region, false);
                  setTimeout(() => {
                    setFieldValue("Comuna", findedCity, false);
                  }, 100);
                }
              }}
            />
            <Select
              helperText={touched.Region && errors.Region}
              error={touched.Region && Boolean(errors.Region)}
              id="Region"
              fullWidth
              label="Región"
              placeholder="Seleccionar Región"
              value={values.Region}
              onChange={(value) => {
                setFieldValue("Region", value);
                setFieldValue("Comuna", "");
              }}
            >
              {regionesComunas.regiones.map((region, index) => {
                return (
                  <MenuItem
                    key={`region-${index}`}
                    value={region.region}
                    label={region.region}
                  />
                );
              })}
            </Select>
            <Select
              helperText={touched.Comuna && errors.Comuna}
              error={touched.Comuna && Boolean(errors.Comuna)}
              id="Comuna"
              fullWidth
              label="Comuna"
              placeholder="Seleccionar Comuna"
              value={values.Comuna}
              onChange={(value) => {
                setFieldValue("Comuna", value);
              }}
            >
              {values.Region &&
                regionesComunas.regiones
                  .find((x) => x.region === values.Region)
                  ?.comunas?.map((comuna, index) => (
                    <MenuItem
                      key={`comuna-${index}`}
                      value={comuna}
                      label={comuna}
                    />
                  ))}
            </Select>
            <div>
              <TextField
                label="Email"
                placeholder="Ingresa tu Email"
                id="Email"
                value={values.Email}
                onChange={handleChange}
                helperText={touched.Email && errors.Email}
                error={touched.Email && Boolean(errors.Email)}
                required
              />
              {emailExists !== undefined && (
                <span className="text-red-500 font-semibold text-left ml-3">
                  Este correo electrónico ya está registrado
                </span>
              )}
            </div>

            <TextField
              label="Contraseña"
              placeholder="Ingresa tu Contraseña"
              id="Contrasena"
              type="password"
              value={values.Contrasena}
              onChange={handleChange}
              helperText={touched.Contrasena && errors.Contrasena}
              error={touched.Contrasena && Boolean(errors.Contrasena)}
            />
            <TextField
              label="Repetir Contraseña"
              placeholder="Repetir Contraseña"
              id="RepetirContrasena"
              type="password"
              value={values.RepetirContrasena}
              onChange={handleChange}
              helperText={touched.RepetirContrasena && errors.RepetirContrasena}
              error={
                touched.RepetirContrasena && Boolean(errors.RepetirContrasena)
              }
            />

            {/* <Select
              id="CategoriaServivios"
              fullWidth
              label="Categoria Servicios"
              placeholder="Seleccionar Categoria"
              value={values.CategoriaServivios}
              helperText={touched.CategoriaServivios && errors.CategoriaServivios}
              error={touched.CategoriaServivios && Boolean(errors.CategoriaServivios)}
              divClassName="mb-4"
              onChange={(value) => {
                setFieldValue("Categoria", value);
              }}
            >
              {categories.map((category, index) => {
                return (
                  <MenuItem
                    value={category}
                    label={category}
                    key={`category-${index}`}
                  />
                );
              })}
            </Select> */}
            <div className="flex flex-col pb-10">
              <div>
                {/*TODO: Esto deberia ser un dato de la base de datos */}
                <label className="flex items-center " htmlFor="">
                  <input
                    id="TerminosYCondiciones"
                    className="h-5 w-5 mr-3 "
                    type="checkbox"
                    checked={Boolean(values.TerminosYCondiciones)}
                    onChange={(event) =>
                      setFieldValue(
                        "TerminosYCondiciones",
                        !values.TerminosYCondiciones
                      )
                    }
                  />
                  <p className="text-gray-400 font-semibold flex-1">
                    Acepto los
                    <a
                      href="https://firebasestorage.googleapis.com/v0/b/demandapp-4cc44.appspot.com/o/TerminosYCondiciones%2FT%C3%A9rminos_y_Condiciones.pdf?alt=media&token=1b4288ff-a523-4b12-9270-ea40c0a19d33"
                      target={"_blank"}
                    >
                      <span
                        className="text-primary cursor-pointer"
                        onClick={handleShowTerms}
                      >
                        &nbsp;Términos y Condiciones
                      </span>
                    </a>
                  </p>
                </label>
                <Button
                  type="submit"
                  text="Registrarse"
                  color="secondary"
                  fullWidth
                  className="mt-10"
                  disabled={registerState === FormState.Submitting}
                  isLoading={registerState === FormState.Submitting}
                />
              </div>
            </div>
          </div>
        </form>
        {AdvertisingBot.length === 1 && (
          <a href={AdvertisingBot[0].Link} target={"_blank"}>
            {actualWidth > 400 ? (
              <img
                src={AdvertisingBot[0].Imagen.URL}
                className="mx-auto rounded-md mb-5 object-cover"
                style={{ width: 1309, height: 150 }}
              ></img>
            ) : (
              <img
                src={AdvertisingBot[0].ImagenMovil.URL}
                className="mx-auto rounded-md mb-7 object-cover"
                style={{ width: 1309, height: 150 }}
              ></img>
            )}{" "}
          </a>
        )}
      </motion.div>
      <Modal isVisible={open} onClose={handleOnClose} isDismiseble>
        <div className="bg-white w-full rounded-lg text-center p-4">
          <p className="text-sm font-semibold">Registro Completo</p>
          <Button
            text={"Ok"}
            className="mt-3"
            paddingClassname="py-1 px-3"
            onClick={() => {
              navigate("/auth/validateMail");
              
            }}
            textColor="text-primary"
          />
        </div>
      </Modal>
    </React.Fragment>
  );
};

import clsx from "clsx";
import React, { useState } from "react";
import { PlusCircle as PlusCircleIcon } from "react-feather";
import { useLocation, useNavigate } from "react-router-dom";
import { FilterMenu } from "../components/FilterMenu";

export const PlaintiffHeader = () => {
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const handleCloseMenu = () => {
    setOpen(false);
  };
  const handleOpenMenu = () => {
    setOpen((toggle) => !toggle);
  };

  const navigation = useNavigate();
  return (
    <div
      className={clsx(
        "h-14 bg-primary flex flex-row sm:px-14 px-2 fixed w-full z-10 top-20",
        location.pathname !== "/demandante/solicitudesRealizadas" ? "justify-end" : "justify-between"
      )}
    >
      {location.pathname === "/demandante/solicitudesRealizadas" && (
        <CustomButtom
          title="Filtrar"
          icon={<PlusCircleIcon size={32} color="white" />}
          position="end"
          onClick={() => {
            if (!open) {
              handleOpenMenu();
            } else {
              handleCloseMenu();
            }
          }}
        />
      )}
      <CustomButtom
        title="Nueva solicitud"
        icon={<PlusCircleIcon size={32} color="white" />}
        onClick={() => {
          navigation("/demandante/crear");
        }}
      />
      <FilterMenu isVisible={open} onClose={handleCloseMenu} title="filtrar" />
    </div>
  );
};

interface ButtonProps {
  title: string;
  icon: any;
  position?: "start" | "end";
  onClick?: VoidFunction;
  style?: React.CSSProperties;
}

const CustomButtom: React.FC<ButtonProps> = ({
  icon,
  title,
  position = "start",
  style,
  onClick,
}) => {
  return (
    <button
      className={clsx(
        "flex items-center",
        position === "start" ? "flex-row" : "flex-row-reverse"
      )}
      onClick={onClick}
      style={style}
    >
      <span
        className={clsx(
          "text-white font-semibold text-sm",

          position === "start" ? "mr-2" : "ml-2"
        )}
      >
        {title}
      </span>
      {icon}
    </button>
  );
};

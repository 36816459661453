import clsx from "clsx";
import React, { CSSProperties, useRef } from "react";

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  helperText?: any;
  helperText2?: any;
  placeholder?: string;
  icon?: React.ReactNode;
  helperTextColor?: string;
  helperTextClassName?: string;
  divClassName?: string;
  divStyle?: CSSProperties;
  label?: string;
  error?: boolean;
  outlined?: boolean;
  multiline?: boolean;
  pattern?: string;
  maxLength?: number;
  minLength?: number;
  type?: string;
}

export const TextField: React.FC<Props> = ({
  helperText,
  helperText2,
  helperTextColor,
  icon,
  helperTextClassName,
  divStyle,
  divClassName,
  className,
  label,
  id,
  disabled,
  error,
  type = "text",
  outlined = true,
  multiline = false,
  ...others
}) => {
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement | null>(null);
  const handleOusideClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className={divClassName} style={divStyle}>
      {label && (
        <label
          className="pl-4 pb-2 font-semibold text-deepBlue text-lg "
          htmlFor={id ? id : `input-${label}`}
        >
          {label}
        </label>
      )}

      <div
        className={clsx(
          "flex rounded-full bg-white ",
          icon ? "flex-row items-center pl-3" : "flex-col",
          multiline ? "h-44" : "h-12",
          type === "file" ? "cursor-pointer" : "cursor-text"
        )}
        onClick={handleOusideClick}
      >
        {icon && <div className="mr-3">{icon}</div>}

        {!multiline && id !== "Telefono" ? (
          <input
            id={id ? id : `input-${label}`}
            ref={inputRef as React.MutableRefObject<HTMLInputElement>}
            className={clsx(
              `w-full px-4 ${icon ? "rounded-r-full" : "rounded-full"} 
            h-full  
            hover:bg-secondary-light hover:bg-opacity-10`,
              className,
              type === "file" ? "hidden" : "",
              outlined
                ? "border-solid border-1.5 border-primary outline-none"
                : "border-none border-0 outline-none"
            )}
            type={type}
            disabled={disabled}
            style={{ backgroundColor: disabled ? "#F2F2F2" : "#FFFFFF"}}
            {...others}
          />
        ) : !multiline && id === "Telefono" ? (
          <div
            className={clsx(
              "flex rounded-full bg-white ",
              icon ? "flex items-center pl-3" : "flex",
              multiline ? "h-44" : "h-12",
              type === "file" ? "cursor-pointer" : "cursor-text"
            )}
          >
            <span
              style={{ paddingTop: "11px" }}
              className=" px-2 py-3  text-   rounded-l-full border-r-0 border-1.5 border-primary self-center h-12 "
            >
              +56
            </span>

            <input
              id={id ? id : `input-${label}`}
              ref={inputRef as React.MutableRefObject<HTMLInputElement>}
              className={clsx(
                `w-full px-2 rounded-r-full
          h-full focus:border-secondary hover:border-secondary 
           
          hover:bg-secondary-light hover:bg-opacity-10`,
                className,
                type === "file" ? "hidden" : "",
                outlined
                  ? "border-solid border-1.5 border-primary outline-none"
                  : "border-none border-0 outline-none"
              )}
              type={type}
              {...others}
            />
          </div>
        ) : (
          <textarea
            id={id ? id : `input-${label}`}
            className={clsx(
              `w-full px-4 resize-none py-2 ${
                icon ? "rounded-r-full" : "rounded-xl"
              } h-full focus:border-secondary hover:border-secondary hover:bg-secondary-light hover:bg-opacity-10`,
              className,
              outlined
                ? "border-solid border-1.5 border-primary outline-none"
                : "border-none border-0 outline-none"
            )}
            ref={inputRef as any}
            {...(others as any)}
          />
        )}

        {type === "file" && (
          <div
            
            className="w-full h-full px-4 flex justify-between items-center border-solid border-1.5 border-primary rounded-full hover:border-secondary hover:bg-secondary-light hover:bg-opacity-10"
            onClick={() => inputRef.current?.click()}
            style={{ backgroundColor: disabled ? "#F2F2F2" : "#FFFFFF"}}
            
          >
            <span className="">Examinar...</span>
            <span className=" rounded-full flex items-center justify-center text-white bg-primary px-8 py-1 ">
              Adjuntar
            </span>
          </div>
        )}
      </div>

      {helperText && error && (
        <div className="px-5">
          <span
            className={clsx(
              "font-semibold text-left",
              helperTextClassName,
              error && "text-red-500"
            )}
            style={{ color: helperText }}
          >
            {helperText}
          </span>
        </div>
      )}




      {helperText2 && error && (
        <div className="px-5">
          <span
            className={clsx(
              "font-semibold text-left",
              helperTextClassName,
              error && "text-red-500"
            )}
            style={{ color: helperText2 }}
          >
            {helperText2}
          </span>
        </div>
      )}
    </div>
  );
};

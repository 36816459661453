import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import KeyOutlinedIcon from "../assets/icons/KeyOutlinedIcon";
import UserOutlinedIcon from "../assets/icons/UserOutlinedIcon";
import { FormState } from "../models/form_state";
import {
  resetContraseña, signInFromRequest
} from "../redux/actions/auth.actions";
import { AuthState } from "../redux/reducers/auth.reducer";
import { RootState } from "../redux/reducers/root.reducer";
import { Alert } from "./Alert";
import { Button } from "./Button";
import { TextField } from "./TextField";

interface Props {
  requestId: string;
}

export const LoginCard: React.FC<Props> = ({requestId}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    login: { state, error },
  } = useSelector<RootState, AuthState>((state) => state.authReducer);

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues: {
      Email: "",
      Password: "",
    },
    onSubmit: (values) => {
      dispatch(signInFromRequest(values, requestId));
    },
    validationSchema: yup.object().shape({
      Email: yup.string().required("Correo requerido").email("Correo no válido"),
      Password: yup.string().required("Contraseña requerida"),
    }),
  });

  return (
    <React.Fragment>
      {error && (
        <Alert severity="error" className="mb-5">
          {error}
        </Alert>
      )}
      <div className="mt-16">
        <h1 className="text-deepBlue text-2xl font-bold">Ingreso Usuario</h1>
        <div className="flex flex-col place-items-center mt-10 rounded-xl bg-primary-100 py-10 w-auto">
          <form onSubmit={handleSubmit} className="w-5/6 ">
            <TextField
              id="Email"
              placeholder="Correo"
              type="email"
              icon={<UserOutlinedIcon height={28} width={28} />}
              divClassName="mb-5 w-full"
              outlined={false}
              value={values.Email}
              onChange={handleChange}
              helperText={touched.Email && errors.Email}
              error={touched.Email && Boolean(errors.Email)}
            />
            <TextField
              id="Password"
              placeholder="Contraseña"
              icon={<KeyOutlinedIcon height={28} width={28} />}
              type="password"
              divClassName="w-full"
              outlined={false}
              value={values.Password}
              onChange={handleChange}
              helperText={touched.Password && errors.Password}
              error={touched.Password && Boolean(errors.Password)}
            />
            <Button
              text="Ingresar"
              color="secondary"
              className="w-full mt-5"
              fullWidth
              type="submit"
              isLoading={state === FormState.Submitting}
            />
          </form>

          <Button
            text="¿Olvidaste tu contraseña?"
            textColor="text-primary-dark font-semibold"
            className="mb-4 mt-1 text-lg w-1/2"
            insideClassName="py-2 px-5"
            onClick={() => dispatch(resetContraseña(values))}
          />
          <h4 className="text-lg font-semibold text-deepBlue ">
            ¿No tienes cuenta?
          </h4>
          <Button
            text="Regístrate Aquí"
            className="w-5/6 mt-3 bg-button"
            onClick={() => navigate("/auth/regitroOferente")}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

import React from "react";

export const OfferLoadingCard = () => {
  return (
    <div>
      <div className="rounded-xl p-8 py-10 mt-20 bg-gray-100">
        <div className="animate-pulse flex">
          <div className="flex-1 space-y-6">
            <div className="h-2 bg-gray-300 rounded "></div>
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-gray-300 rounded"></div>
                <div className="h-2 bg-gray-300 rounded col-span-2"></div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-gray-300 rounded"></div>
                <div className="h-2 bg-gray-300 rounded col-span-2"></div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-gray-300 rounded"></div>
                <div className="h-2 bg-gray-300 rounded col-span-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

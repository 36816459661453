import { Action } from "../../models/action";
import { FormState } from "../../models/form_states";
import * as types from "../types";

export interface TransactionState {
  transactions: any[];
  state: FormState;
  error?: string;
  lastDoc?: any;
  totalDocs: number;
  add: {
    state: FormState;
    error?: string;
  };
  //transacciones del oferente
  offererTransactions: {
    transactions?: any[];
    state: FormState;
    error?: string;
    totalDocsOfferes: number;
  };
}

const initialState: TransactionState = {
  transactions: [],

  state: FormState.Initial,
  totalDocs: 0,
  add: {
    state: FormState.Initial,
  },
  offererTransactions: {
    transactions: [],
    state: FormState.Initial,
    totalDocsOfferes: 0,
  },
};

export default function reducer(
  state = initialState,
  action: Action
): TransactionState {
  switch (action.type) {
    case types.TRANSACTION_GET_SUCCESS:
      return {
        ...state,
        transactions: action.payload.transaction,
        state: FormState.Success,
        lastDoc: action.payload.lastDoc,
        totalDocs: action.payload.totalDocs,
      };
    case types.TRANSACTION_GET_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };
    case types.TRANSACTION_GET_SUBMITTING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };

    case types.TRANSACTION_ADD_INITIAL:
      return {
        ...state,
        add: {
          state: FormState.Initial,
          error: undefined,
        },
      };
    case types.TRANSACTION_ADD_SUBMITTING:
      return {
        ...state,
        add: {
          state: FormState.Submitting,
          error: undefined,
        },
      };
    case types.TRANSACTION_ADD_SUCCESS:
      return {
        ...state,
        add: {
          state: FormState.Success,
        },
      };
    case types.TRANSACTION_ADD_FAILURE:
      return {
        ...state,
        add: {
          state: FormState.Failure,
          error: action.payload,
        },
      };

    case types.TRANSACTION_OFFERER_GET_SUCCESS:
      return {
        ...state,
        offererTransactions: {
          transactions: action.payload.transactions,
          totalDocsOfferes: action.payload.totalDocs,
          state: FormState.Success,
        },
      };
    case types.TRANSACTION_OFFERER_GET_FAILURE:
      return {
        ...state,
        offererTransactions: {
          state: FormState.Failure,
          error: action.payload,
          totalDocsOfferes: 0,
        },
      };
    case types.TRANSACTION_OFFERER_GET_SUBMITTING:
      return {
        ...state,
        offererTransactions: {
          state: FormState.Submitting,
          error: undefined,
          totalDocsOfferes: 0,
        },
      };
    default:
      return state;
  }
}

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter, Navigate, Route, Routes
} from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import { OffererLayout } from "../layouts/OffererLayout";
import { PlaintiffLayout } from "../layouts/PlantiffLayout";
import { PublicLayout } from "../layouts/PublicLayout";
import { FormState } from "../models/form_state";
import { LoadingPage } from "../pages/LoadingPage";
// import DashboardLayout from "../layouts/Dashboard";
import { Page404 } from "../pages/Page404";
import { validateSession } from "../redux/actions/auth.actions";
import { AuthState } from "../redux/reducers/auth.reducer";
import { RootState } from "../redux/reducers/root.reducer";
import { offerRoutes, plaintiffRoutesProtected, publicRoutes } from "./index";

// import { MainGuard } from "../components/MainGuard";

const childRoutes = (Layout: any, routes: any) =>
  routes.map(
    ({ component: Component, guard, children, path }: any, index: number) => {
      const Guard = guard || React.Fragment;

      return children ? (
        children
          .reduce(
            (acc: any, val: any) =>
              acc.concat(val.children ? val.children : val),
            []
          )
          .map((element: any, index: number) => {
            const Guard = element.guard || React.Fragment;
            const ElementComponent = element.component || React.Fragment;

            return (
              <Route
                key={index}
                path={element.path}
                element={
                  <Layout>
                    <Guard>
                      <ElementComponent />
                    </Guard>
                  </Layout>
                }
              />
            );
          })
      ) : Component ? (
        <Route
          key={index}
          path={path}
          element={
            <Layout>
              <Guard>
                <Component />
              </Guard>
            </Layout>
          }
        />
      ) : null;
    }
  );

const MainRoutes = () => {
  const dispatch = useDispatch();
  const { state } = useSelector<RootState, AuthState>(
    (state) => state.authReducer
  );

  useEffect(() => {
    dispatch(validateSession());
    // eslint-disable-next-line
  }, []);

  if (state === FormState.Submitting) {
    return <LoadingPage />;
  }

  return (
    <BrowserRouter>
    <ScrollToTop/>
      <Routes>
        {/* <Route exact path="/" component={MainGuard} /> */}
        {childRoutes(PlaintiffLayout, plaintiffRoutesProtected)}
        {childRoutes(PublicLayout, publicRoutes)}
        {childRoutes(OffererLayout, offerRoutes)}
        <Route element={<Page404 />} />

        <Route path="*" element={<Navigate to="/inicio" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRoutes;

import { AnimatePresence, motion, Variants } from "framer-motion";
import React, { useEffect } from "react";

interface Props {
  onClose: VoidFunction;
  isVisible: boolean;
  isDismiseble?: boolean;
}

const dropIn = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
      type: "spring",
      damping: 100,
      stiffness: 500,
    },
  },
  exit: { opacity: 0 },
} as Variants;

export const Modal: React.FC<Props> = ({
  children,
  onClose,
  isVisible,
  isDismiseble = false,
}) => {
  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isVisible]);

  return (
    <React.Fragment>
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {isVisible && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={isDismiseble ? onClose : undefined}
            className="fixed overflow-hidden z-40 top-0 right-0 w-full h-full bg-gray-500 bg-opacity-50 flex items-center justify-center"
          >
            <motion.div
              onClick={(e) => e.stopPropagation()}
              variants={dropIn}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="sm:max-w-xl m-auto rounded-lg z-40 flex flex-col items-center max-w-xs"
            >
              {children}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </React.Fragment>
  );
};

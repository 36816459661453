import { AuthGuard } from "../components/AuthGuard";
import { PublicGuard } from "../components/PublicGuard";
import { Contact } from "../pages/Contact";
import { MainPage } from "../pages/MainPage";
import { ManageAccount } from "../pages/ManageAccount";
import { OffererEditAlert } from "../pages/Offerer/components/OffererEditAlert";
import { DetailsRequestFromOfferList } from "../pages/Offerer/DetailRequestFromOfferList";
import { EditOffer } from "../pages/Offerer/EditOffer";
import { ManageAccountOfferer } from "../pages/Offerer/ManageAccountOfferer";
import { OffererAddAlert } from "../pages/Offerer/OffererAddAlert";
import { OffererAlerts } from "../pages/Offerer/OffererAlerts";
import { OffererDetailsRequest } from "../pages/Offerer/OffererDetailsRequest";
import { OffererGeolocation } from "../pages/Offerer/OffererGeolocation";
import { OffererListRequests } from "../pages/Offerer/OffererListRequests";
import { OffererRegister } from "../pages/Offerer/OffererRegister";
import { OffererTransactions } from "../pages/Offerer/OffererTransactions";
import { OfferList } from "../pages/Offerer/OfferList";
import { StatisticDashboard } from "../pages/Offerer/StatisticDashboard";
import { AddRequest } from "../pages/Plaintiff/AddRequest";
import { DetailsRequest } from "../pages/Plaintiff/DetailsRequest";
import { EditRequest } from "../pages/Plaintiff/EditRequest";
import { ListRequests } from "../pages/Plaintiff/List/ListRequests";
import { MyTransaction } from "../pages/Plaintiff/MyTransaction";
import { PlaintiffRegister } from "../pages/PlaintiffRegister";
import { RecoveryPassword } from "../pages/RecoveryPassword";
import { WhoWeAre } from "../pages/WhoWeAre";
import { OffererGuard } from "../components/OffererGuard";
import { PublicRequestGuard } from "../components/PublicRequestGuard";
import { CodeVerify } from "../pages/CodeVerify";

const mainRoutes = {
  id: "Main",
  path: "/inicio",
  icon: null,
  children: [
    {
      path: "/inicio",
      name: "Main Page",
      component: MainPage,
      guard: PublicGuard,
    },
    {
      path: "/quienesSomos",
      name: "Quienes Somos",
      component: WhoWeAre,
    },
    {
      path: "/recoveryPassword",
      name: "Recuperar Contraseña",
      component: RecoveryPassword,
      guard: PublicGuard,
    },
    {
      path: "/contact",
      name: "Contáctanos",
      component: Contact,
    }
  ],
  component: null,
};

const authRoutes = {
  id: "Auth",
  path: "/auth",
  icon: null,
  children: [
    {
      path: "/auth/regitroOferente",
      name: "Registro Oferente",
      component: OffererRegister,
      guard: PublicGuard,
    },
    {
      path: "/auth/regitroDemandante",
      name: "Registro Demandante",
      component: PlaintiffRegister,
      guard: PublicGuard,
    },
    {
      path: "/auth/validateMail",
      name: "Validar Mail",
      component: CodeVerify,
    },
  ],
  component: null,
};

// TODO: agregar Guard
const plaintiffRoutes = {
  id: "Plaintiff",
  path: "/demandante",
  icon: null,
  children: [
    {
      path: "/demandante/solicitudesRealizadas",
      name: "Solicitudes Realizadas",
      component: ListRequests,
      guard: AuthGuard,
    },
    {
      path: "/demandante/crear",
      name: "Crear Solicitud",
      component: AddRequest,
      guard: AuthGuard,
    },
    {
      path: "/demandante/editar/:id",
      name: "Editar Solicitud",
      component: EditRequest,
      guard: AuthGuard,
    },
    {
      path: "/demandante/detalle/:id",
      name: "Detalle Solicitud",
      component: DetailsRequest,
      guard: AuthGuard,
    },
    {
      path: "/demandante/transacciones",
      name: "Transacciones",
      component: MyTransaction,
      guard: AuthGuard,
    },
    {
      path: "/demandante/adminCuenta",
      name: "Administrar Cuenta",
      component: ManageAccount,
      guard: AuthGuard,
    },
  ],
  component: null,
};

const offererRoutes = {
  id: "offererRoutes",
  path: "/solicitudesActivas",
  icon: null,
  children: [
    {
      path: "/solicitudesActivas",
      name: "Solicitudes Activas",
      component: OffererListRequests,
      guard: PublicRequestGuard
    },
    {
      path: "/solicitud/:requestId/detalle/:offerId",
      name: "Detalle Solicitud",
      component: DetailsRequestFromOfferList,
      guard: OffererGuard,
    },
    {
      path: "/solicitud/:requestId/detalle",
      name: "Detalle Solicitud",
      component: OffererDetailsRequest,
      guard: PublicRequestGuard

    },
    {
      path: "/oferente/ofertasRealizadas",
      name: "Ofertas Realizadas",
      component: OfferList,
      guard: OffererGuard,

    },
    {
      path: "/oferente/editar/:id",
      name: "Editar Oferta",
      component: EditOffer,
      guard: OffererGuard,

    },
    {
      path: "/oferente/ofertasCercanas",
      name: "Ofertas Cercanas",
      component: OffererGeolocation,
    },
    {
      path: "/oferente/adminCuenta",
      name: "Administrar Cuenta",
      component: ManageAccountOfferer,
      guard: OffererGuard,

    },
    {
      path: "/oferente/transacciones",
      name: "Transacciones",
      component: OffererTransactions,
      guard: OffererGuard,

    },
    {
      path: "/oferente/estadisticas",
      name: "Estadísticas",
      component: StatisticDashboard,
      guard: OffererGuard,

    },
    {
      path: "/oferente/alertas",
      name: "Alertas Personalizadas",
      component: OffererAlerts,
      guard: OffererGuard,

    },
    {
      path: "/oferente/CrearAlerta",
      name: "Crear Alerta",
      component: OffererAddAlert,
      guard: OffererGuard,

    },
    {
      path: "/oferente/EditarAlerta",
      name: "Editar Alerta",
      component: OffererEditAlert,
      guard: OffererGuard,

    }
  ],
  component: null,
};

export const publicRoutes = [mainRoutes, authRoutes];

export const offerRoutes = [offererRoutes];

// Routes that are protected
export const plaintiffRoutesProtected = [plaintiffRoutes];

import * as React from "react"
import { SVGProps } from "react"

const YoutubeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={70.72}
    height={70.68}
    viewBox="0 0 70.72 70.68"
    {...props}
  >
    <ellipse
      data-name="Elipse 9 copia 9"
      cx={35.36}
      cy={35.34}
      rx={35.36}
      ry={35.34}
      style={{
        fill: "#d94f39",
      }}
    />
    <image
      data-name="Objeto inteligente vectorial"
      x={20.31}
      y={24.84}
      width={31}
      height={22}
      xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAWCAYAAAA4oUfxAAABcElEQVRIib3WsUtWURjH8Y+vBoqG1CAJrwoNQQ5BtYTwQoi5Vf4NNdTQmP+FTS619DfUGgRREE4KDQ4OQWrYZIiFQfleeeIoju859+V+4XCn3/O9z+Wec56BqqokFvEI13AZQxhLz1H5/MY//ErPfWzhNd79rxbyqqoeVM1yP7zR+SC+Yrqgu1K+4WoLCw2LgxncC3mnYfEpnZDfzAh8wKv4VfogvxXydkbgAE8xhy815e2QTxYE13Aby2lLlXCllfZyCbF3VzCLtwX5iyEfKZSfso2ltHYycsOtmuLzRPfX8QLHvQT6Kc9mqI+1HmIVU70GovOjmtI4Hd+k1bMYf0J+WCiNr/Ycm6nrXA6jwB4mMoN30kl3o/DFgx/R+feMwDhe4nNNcbAb8o2MwF08wUBNcbAe8o99KFTCpxgmWmmYmGlQfDZMdPGsQXEQvu75AXIej9NFcQkX0uAYY1bJ5RODYxyzcev9xc+0LWOAfA8nGnG9vIWUQDUAAAAASUVORK5CYII="
    />
  </svg>
)

export default YoutubeIcon
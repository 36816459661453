import * as React from "react"

function BarsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="prefix__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 52 52"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".prefix__st0{fill:#007ac9}"}</style>
      <path
        className="prefix__st0"
        d="M45.79 16.59H6.05c-.79 0-1.44-.65-1.44-1.44 0-.79.65-1.44 1.44-1.44h39.74c.79 0 1.44.65 1.44 1.44 0 .79-.65 1.44-1.44 1.44zM26.28 28.15H6.05c-.79 0-1.44-.65-1.44-1.44s.65-1.44 1.44-1.44h20.23c.79 0 1.44.65 1.44 1.44s-.64 1.44-1.44 1.44zM45.79 39.71H6.05c-.79 0-1.44-.65-1.44-1.44 0-.79.65-1.44 1.44-1.44h39.74c.79 0 1.44.65 1.44 1.44 0 .79-.65 1.44-1.44 1.44z"
      />
    </svg>
  )
}

export default BarsIcon

import * as types from "../types";
import { Action } from "../../models/action";
import { FormState } from "../../models/form_state";
import { Statistic } from "../../models/Statistic";

export interface StatisticState {
  statisticsCategories: Statistic;
  statisticsBudget: Statistic;
  state: FormState;
  stateForm: FormState;
  error?: string;
}

const initialState = {
  statisticsCategories: {},
  statisticsBudget: {},
  state: FormState.Initial,
  stateForm: FormState.Initial,
} as StatisticState;

export default function statisticReducer(
  state = initialState,
  action: Action
): StatisticState {
  switch (action.type) {
    case types.STATISTIC_GET_SUBMITTING:
      return {
        ...state,
        state: FormState.Submitting,
        error: undefined,
      };
    case types.STATISTIC_GET_FORM_SUBMITTING:
      return {
        ...state,
        stateForm: FormState.Submitting,
        error: undefined,
      };
    case types.STATISTIC_CATEGORIES_GET_SUCCESS:
      return {
        ...state,
        statisticsCategories: action.payload,
        stateForm: FormState.Success,
      };
    case types.STATISTIC_BUDGET_GET_SUCCESS:
      return {
        ...state,
        statisticsBudget: action.payload,
        state: FormState.Success,
      };
    case types.STATISTIC_GET_FAILURE:
      return {
        ...state,
        state: FormState.Failure,
        error: action.payload,
      };
    case types.STATISTIC_GET_INITIAL:
      return {
        ...state,
        state: initialState.state,
        statisticsCategories: initialState.statisticsCategories,
        statisticsBudget: initialState.statisticsBudget,
      };

    default:
      return state;
  }
}

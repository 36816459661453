import { useFormik } from "formik";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import regionesComunas from "../../assets/RegionesComunas.json";
import { AutocompletePlaces } from "../../components/AutocompletePlaces";
import { Button } from "../../components/Button";
import { EditEmail } from "../../components/EditEmail";
import { EditPassword } from "../../components/EditPassword";
import { FileSpace } from "../../components/FileSpace";
import { MenuItem } from "../../components/MenuItem";
import { ModalMessage } from "../../components/ModalMessage";
import { ModalMessageError } from "../../components/ModalMessageError";
import { Select } from "../../components/Select";
import { TextField } from "../../components/TextField";
import { FormState } from "../../models/form_state";
import { Docs } from "../../models/PlaintiffOffer";
import { getAdvertising } from "../../redux/actions/advertising.action";
import {
  updateOfferer,
  updatePlantiff,
} from "../../redux/actions/auth.actions";
import { AdvertisingState } from "../../redux/reducers/advertising.reducer";
import { AuthState } from "../../redux/reducers/auth.reducer";
import { RootState } from "../../redux/reducers/root.reducer";

export const ManageAccountOfferer = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const [showModalError, setShowModalError] = React.useState(false);
  const [editable, setEditable] = useState(false);
  const { user } = useSelector<RootState, AuthState>(
    (state) => state.authReducer
  );
  const [modal, setModal] = useState({
    open: false,
    text: "El archivo excede el tamaño maximo",
  });

  const openModal = (text: string) => {
    setModal({ open: true, text });
  };

  const handleCloseModal = () => {
    setModal({ open: false, text: "" });
  };

  useEffect(() => {
    dispatch(getAdvertising());
  }, []);

  const { state: advertisingState, advertisings } = useSelector<
    RootState,
    AdvertisingState
  >((state) => state.advertisingReducer);
  const AdvertisingSup = advertisings.filter(
    (x) => x.Vista === "Mi Cuenta" && x.Posicion === "superior"
  );
  const AdvertisingBot = advertisings.filter(
    (x) => x.Vista === "Mi Cuenta" && x.Posicion === "inferior"
  );

  const [actualWidth, setActualWidth] = useState(window.innerWidth);

  const resizeHandler = () => {
    const width = window.innerWidth;

    setActualWidth(width);
  };

  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
  }, []);

  const { handleSubmit, values, handleChange, touched, setFieldValue, errors } =
    useFormik({
      initialValues: {
        PlaceId: "",
        Ubicacion: {},
        Nombre: user?.Nombre,
        Apellido: user?.Apellido,
        Region: user?.Region,
        Comuna: user?.Comuna,
        FechaNacimiento: user?.FechaNacimiento,
        Direccion: user?.Direccion,
        Archivos: user?.Archivos,
      },
      onSubmit: (values) => {
        dispatch(updateOfferer(values));
      },
      validationSchema: yup.object({
        Nombre: yup.string().required("Nombre es requerido"),
        Apellido: yup.string().required("Apellido es requerido"),
        Region: yup.string().required("Region es requerido"),
        Comuna: yup.string().required("Comuna es requerido"),
        FechaNacimiento: yup
          .string()
          .required("Fecha de nacimineto es requerido"),
        Direccion: yup.string().required("Dirección es requerido"),
      }),
    });
  const { edit } = useSelector<RootState, AuthState>(
    (state) => state.authReducer
  );
  useEffect(() => {
    if (edit.state === FormState.Success) {
      setShowModal(true);
      setEditable(false);
      setTimeout(() => {
        setShowModal(false);
      }, 1000);
    }
    if (edit.state === FormState.Failure) {
      setShowModalError(true);
      setTimeout(() => {
        setShowModalError(false);
      }, 1000);
    }
    return () => {
      edit.state = FormState.Initial;
    };
    // eslint-disable-next-line
  }, [edit.state]);

  const handleChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;
    if (files && files.length > 0 && values.Archivos.length < 4) {
      const file = files.item(0)!;
      if (file?.size <= 10485760) {
        const newArray = [...values.Archivos];
        newArray.push(file);
        setFieldValue("Archivos", newArray);
      } else {
        // TODO: Debe saltar el dialogo
        openModal("El archivo excede el tamaño maximo");
      }
    } else {
      openModal("Se alcanzó la cantidad máxima de archivos");
    }
  };

  const handleDeleteFile = (index: number) => {
    const finded = values.Archivos[index];
    if (!finded) {
      return;
    }
    const newArray = values.Archivos.filter((item: any) => item !== finded);
    setFieldValue("Archivos", newArray, false);
  };

  return (
    <React.Fragment>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="lg:container lg:mx-auto mt-5 px-8"
      >
        <div className="flex flex-row justify-between align-center">
          <h1 className=" text-3xl font-bold text-deepBlue pt-8 ">
            Administrar Cuenta
          </h1>
          {editable === true ? (
            ""
          ) : (
            <Button
              onClick={() => setEditable(true)}
              text="Editar"
              color="secondary"
              className="mt-7 w-60 "
            />
          )}
        </div>
        {AdvertisingSup.length === 1 && (
          <a href={AdvertisingSup[0].Link} target={"_blank"}>
            {actualWidth > 400 ? (
              <img
                src={AdvertisingSup[0].Imagen.URL}
                className="mx-auto rounded-md mt-2 object-cover   md:visible lg:visible"
                style={{ width: 1309, height: 150 }}
              ></img>
            ) : (
              <img
                src={AdvertisingSup[0].ImagenMovil.URL}
                className="mx-auto rounded-md mt-7 object-cover visible sm:visible md:hidden lg:hidden"
                style={{ width: 1309, height: 150 }}
              ></img>
            )}
          </a>
        )}
        {/* <Banner /> */}
        <form onSubmit={handleSubmit} className="my-10">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 justify-between px-6 md:px-0 ">
            <TextField
              disabled={!editable}
              label="Nombre"
              placeholder="Ingresa tu Nombre"
              id="Nombre"
              value={values.Nombre}
              onChange={handleChange}
              helperText={touched.Nombre && errors.Nombre}
              error={touched.Nombre && Boolean(errors.Nombre)}
              required
            />
            <TextField
              disabled={!editable}
              label="Apellido"
              placeholder="Ingresa tu Apellido"
              id="Apellido"
              value={values.Apellido}
              onChange={handleChange}
              helperText={touched.Apellido && errors.Apellido}
              error={touched.Apellido && Boolean(errors.Apellido)}
              required
            />

            <TextField
              disabled={!editable}
              label="Fecha De Nacimiento"
              placeholder="Ingresa tu Fecha de Nacimiento"
              id="FechaNacimiento"
              type="date"
              value={values.FechaNacimiento}
              onChange={handleChange}
              helperText={touched.FechaNacimiento && errors.FechaNacimiento}
              error={touched.FechaNacimiento && Boolean(errors.FechaNacimiento)}
              required
            />
            <AutocompletePlaces
              disabled={!editable}
              label="Dirección"
              id="Direccion"
              helperText={touched.Direccion && errors.Direccion}
              error={touched.Direccion && Boolean(errors.Direccion)}
              value={values.Direccion}
              onChange={(place) => {
                if (place) {
                  const address = place.formatted_address;
                  const placeId = place.place_id;
                  const location = {
                    lat: place.geometry?.location?.lat(),
                    lng: place.geometry?.location?.lng(),
                  };
                  let region = "";
                  let city = "";

                  place.address_components?.forEach((component) => {
                    // Region
                    if (
                      component.types.includes("administrative_area_level_1")
                    ) {
                      region = component.long_name;
                    }
                    // Ciudad
                    if (
                      component.types.includes("administrative_area_level_3")
                    ) {
                      city = component.long_name;
                    }
                  });

                  const findedRegion = regionesComunas.regiones.find((r) =>
                    r.region
                      .toLocaleLowerCase()
                      .match(region.toLocaleLowerCase())
                  );
                  const findedCity = findedRegion?.comunas.find((c) =>
                    c.toLocaleLowerCase().match(city.toLocaleLowerCase())
                  );

                  setFieldValue("Direccion", address, false);
                  setFieldValue("Ubicacion", location, false);
                  setFieldValue("PlaceId", placeId, false);
                  setFieldValue("Region", findedRegion?.region, false);
                  setTimeout(() => {
                    setFieldValue("Comuna", findedCity, false);
                  }, 100);
                }
              }}
            />
            <Select
              disabled={!editable}
              id="Region"
              fullWidth
              label="Región"
              placeholder={values.Region}
              value={values.Region}
              onChange={(value) => {
                setFieldValue("Region", value);
                setFieldValue("Comuna", "");
              }}
            >
              {regionesComunas.regiones.map((region, index) => {
                return (
                  <MenuItem
                    key={`region-${index}`}
                    value={region.region}
                    label={region.region}
                  />
                );
              })}
            </Select>
            <Select
              disabled={!editable}
              id="Comuna"
              fullWidth
              label="Comuna"
              placeholder={values.Comuna}
              value={values.Comuna}
              onChange={(value) => {
                setFieldValue("Comuna", value);
              }}
            >
              {values.Region &&
                regionesComunas.regiones
                  .find((x) => x.region === values.Region)
                  ?.comunas?.map((comuna, index) => (
                    <MenuItem
                      key={`comuna-${index}`}
                      value={comuna}
                      label={comuna}
                    />
                  ))}
            </Select>
            <div>
              <TextField
                label="Adjuntar Certificaciones"
                type="file"
                disabled={!editable}
                accept=".png, .jpg, .jpeg,.pdf,.doc,.docx"
                onChange={handleChangeFiles}
              />
              <p className="text-gray-400 mt-2 mb-2">
                *Máximo 4 documento, Peso max 10mb.
              </p>
              <div className="flex">
                <FileSpace
                  files={values.Archivos}
                  disabled={!editable}
                  handleDeleteFile={handleDeleteFile}
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3  justify-between px-6 md:px-0 pb-8">
            <div></div>
            <div className="flex justify-end ">
              {editable === false ? (
                ""
              ) : (
                <>
                  <Button
                    style={{ marginRight: 3 }}
                    onClick={() => setEditable(!editable)}
                    text="Cancelar"
                    color="secondary"
                    className="mt-7 w-60 "
                  />
                  <Button
                    type="submit"
                    text="Editar"
                    color="secondary"
                    className="mt-7 w-60 "
                  />
                </>
              )}
            </div>
          </div>
        </form>
        <hr className="h-1 bg-blue-200" />
        <EditEmail />
        <hr className="h-1 bg-blue-200" />
        <EditPassword />
      </motion.div>
      <ModalMessage
        title="Datos actualizados Correctamente"
        open={showModal}
        onClose={() => setShowModal(false)}
      />
      <ModalMessageError
        title="Ha ocurrido un error al Actualizar sus datos"
        open={showModalError}
        onClose={() => setShowModalError(false)}
      />
      {AdvertisingBot.length === 1 && (
        <a href={AdvertisingBot[0].Link} target={"_blank"}>
          {actualWidth > 400 ? (
            <img
              src={AdvertisingBot[0].Imagen.URL}
              className="mx-auto rounded-md mt-2 mb-5 object-cover   md:visible lg:visible"
              style={{ width: 1309, height: 150 }}
            ></img>
          ) : (
            <img
              src={AdvertisingBot[0].ImagenMovil.URL}
              className="mx-auto rounded-md mt-7 mb-5 object-cover visible sm:visible md:hidden lg:hidden"
              style={{ width: 1309, height: 150 }}
            ></img>
          )}{" "}
        </a>
      )}
    </React.Fragment>
  );
};

import { isBefore } from "date-fns";
import React, { useState } from "react";
import { Circle, OverlayView } from "react-google-maps";
import LogoMarkerBlue from "../assets/img/demandapp_azul.svg";
import { PlaintiffRequest } from "../models/PlaintiffRequest";
import { MapRequestCard } from "../pages/Offerer/components/MapRequestCard";

interface OverlayProps {
  position: google.maps.LatLng;
  zoom: number;
  request: PlaintiffRequest;
}

export const CustomOverlayMap: React.FC<OverlayProps> = ({
  position,
  zoom,
  request,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      {request.FechaPremium !== null && isBefore(new Date(), request.FechaPremium.toDate())  ? (
        <Circle
          center={position}
          radius={zoom <= 14 ? 450 : 150}
          onClick={handleOpenModal}
          defaultOptions={{
            fillColor: "#14CA25",
            strokeColor: "#14CA25",
            strokeWeight: 1,
            clickable: true,
          }}
        />
      ) : (
        <Circle
          center={position}
          radius={150}
          onClick={handleOpenModal}
          defaultOptions={{
            fillColor: "#13309C",
            strokeColor: "#13309C",
            strokeWeight: 1,
            clickable: true,
          }}
        />
      )}
      <OverlayView
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={(h, w) => ({ x: -(w / 2), y: -(h / 2) })}
      >
        <div style={{ position: "relative" }}>
          <div
            className="flex justify-center items-center"
            onClick={handleOpenModal}
            style={{
              width: 0,
              height: 0,
            }}
          >
            <img
              src={LogoMarkerBlue}
              alt="current-position"
              height={zoom < 15 ? 18 : 80}
              width={zoom < 15 ? 18 : 80}
            />
          </div>
          {/* {open && (
            <div
              style={{
                height: 200,
                width: 120,
                backgroundColor: "blue",
                position: "absolute",
              }}
            ></div>
          )} */}
        </div>
      </OverlayView>
      <MapRequestCard
        request={request}
        open={open}
        handleOnClose={handleCloseModal}
      />
    </React.Fragment>
  );
};

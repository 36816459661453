import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { getMyAlerts } from "../../redux/actions/alert.action";
import { RootState } from "../../redux/reducers/root.reducer";
import { AlertState } from "../../redux/reducers/alert.reducer";
import { AlertCard } from "./components/AlertCard";
import { getAdvertising } from "../../redux/actions/advertising.action";
import { AdvertisingState } from "../../redux/reducers/advertising.reducer";
import { FormState } from "../../models/form_states";

export const OffererAlerts = () => {
  const dispatch = useDispatch();
  
  const { alerts, delete: {state: deleteState} } = useSelector<RootState, AlertState>(
    (state) => state.alertReducer
    );
    
    useEffect(() => {
      dispatch(getMyAlerts());
    }, [deleteState]);
  const [actualWidth, setActualWidth] = useState(window.innerWidth);

  const resizeHandler = () => {
    const width = window.innerWidth;

    setActualWidth(width);
  };

  const {
    edit:{selectedAlert, state:editState}} = useSelector<RootState, AlertState>(
    (state) => state.alertReducer
  )

  const {
    add: { state: addState, error },
  } = useSelector<RootState, AlertState>((state) => state.alertReducer);



  useEffect(() => {
    window.addEventListener("resize", resizeHandler);
  }, []);

  useEffect(() => {
    dispatch(getAdvertising());
  }, []);

  const { state: advertisingState, advertisings } = useSelector<
    RootState,
    AdvertisingState
  >((state) => state.advertisingReducer);
  const AdvertisingSup = advertisings.filter(
    (x) => x.Vista === "Mis Alertas" && x.Posicion === "superior"
  );
  const AdvertisingBot = advertisings.filter(
    (x) => x.Vista === "Mis Alertas" && x.Posicion === "inferior"
  );

  return (
    <main className="py-10 px-8 lg:container lg:mx-auto">
      <Helmet title="Mis Alertas" />
      <h1 className=" text-3xl font-bold text-deepBlue ">Mis Alertas</h1>
      <h3 className=" text-lg font-bold text-deepBlue ">Puede crear hasta 3 alertas</h3>
      {AdvertisingSup.length === 1 && (
        <a href={AdvertisingSup[0].Link} target={"_blank"}>
          {actualWidth > 400 ? (
            <img
              src={AdvertisingSup[0].Imagen.URL}
              className="mx-auto rounded-md mt-2 object-cover   md:visible lg:visible"
              style={{ width: 1309, height: 150 }}
            ></img>
          ) : (
            <img
              src={AdvertisingSup[0].ImagenMovil.URL}
              className="mx-auto rounded-md mt-7 object-cover visible sm:visible md:hidden lg:hidden"
              style={{ width: 1309, height: 150 }}
            ></img>
          )}
        </a>
      )}
        {alerts.length > 0 ? (
      <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-24 md:grid-cols-2  lg:grid-cols-3 px-5 mx-5 gap-4 mt-8 mr-5">
        <React.Fragment>
          {alerts.map((alert, index) => (
            <AlertCard alert={alert} key={`alert-${index}`} />
          ))}
        </React.Fragment>
      </div>
        ):(
          <div className="bg-blue-200 text-xl text-center mt-4 py-5 rounded-md w-1/2 mx-auto font-semibold text-blue-900">
            No se han encontrado alertas
          </div>
        )}
      {AdvertisingBot.length === 1 && (
        <a href={AdvertisingBot[0].Link} target={"_blank"}>
          {actualWidth > 400 ? (
            <img
              src={AdvertisingBot[0].Imagen.URL}
              className="mx-auto rounded-md mt-5 object-cover   md:visible lg:visible"
              style={{ width: 1309, height: 150 }}
            ></img>
          ) : (
            <img
              src={AdvertisingBot[0].ImagenMovil.URL}
              className="mx-auto rounded-md mt-7 object-cover visible sm:visible md:hidden lg:hidden"
              style={{ width: 1309, height: 150 }}
            ></img>
          )}{" "}
        </a>
      )}
    </main>
  );
};

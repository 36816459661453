import React, { useEffect, useState } from "react";
import { Star, XCircle } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StarRatingComponent from "react-star-rating-component";
import { FormState } from "../../models/form_states";
import { PlaintiffOffer } from "../../models/PlaintiffOffer";
import { PlaintiffRequest } from "../../models/PlaintiffRequest";
import { addCalificationOfferer } from "../../redux/actions/calification.action";
import {
  addAcceptedOffer,
  deleteOfferInitial,
  rejectedOffer,
} from "../../redux/actions/requests.actions";
import { OfferState } from "../../redux/reducers/offer.reducer";
import { RequestsState } from "../../redux/reducers/requests.reducer";
import { RootState } from "../../redux/reducers/root.reducer";
import { Button } from "../Button";
import { MenuItem } from "../MenuItem";
import { Modal } from "../Modal";
import { Select } from "../Select";
import { TextField } from "../TextField";
import { AcceptOfferCard } from "./AcceptOfferCard";
import { LoadingCard } from "./LoadingCard";
import { OfferCard } from "./OfferCard";

interface Props {
  request: PlaintiffRequest;
}

export const OfferSection: React.FC<Props> = ({ request }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const {
    offers,
    state: offersState,
    error,
  } = useSelector<RootState, OfferState>((state) => state.offerReducer);

  const {
    deleteOffer,
    acceptOffer,
    offer: getAceptOffer,
  } = useSelector<RootState, RequestsState>((state) => state.requestReducer);

  const [selected, setSelected] = useState<any>();
  const [show, setShow] = useState(false);
  const [visible, setVisible] = useState(false);
  const [acceptReason, setAcceptReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [showReject, setShowReject] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [calificationModal, setCalificationModal] = useState(false);
  const [calification, setCalification] = useState(0);

  const handleOnClose = () => {
    setShow(false);
    setShowReject(false);
    setCalificationModal(false);
  };

  const handleCloseErrorMessage = () => {
    setVisible(false);
    dispatch(deleteOfferInitial());
  };

  //CONTROL MODAL ACEPTAR OFERTA
  const handleAccept = (offer: any) => {
    setShow(true);
    setSelected(offer);
  };

  const handleAcceptOffer = (offer: any) => {
    if(acceptReason !== "Otros"){
      dispatch(addAcceptedOffer(offer, request, acceptReason));
      setShow(false);
    }else {
      dispatch(addAcceptedOffer(offer, request, otherReason));
      setShow(false);
    }
  };

  //CONTROL MODAL RECHAZAR OFERTA
  const handleReject = (offer: any) => {
    setShowReject(true);
    setSelected(offer);
  };
  const handleRejectOffer = (offer: any) => {
    if(rejectReason !== "Otros"){
      dispatch(rejectedOffer(offer, request, rejectReason));
      setShowReject(false);
    }else {
      dispatch(rejectedOffer(offer, request, otherReason));
      setShowReject(false);
    }
  };

  //CONTROL MODAL CALIFICAR USUARIO
  const handleRate = (offer: any) => {
    setCalificationModal(true);
    setSelected(offer);
  };

  const handleRateUser = (calification: number, offer: any, requestId: string) => {
    dispatch(addCalificationOfferer(calification, offer, requestId))
    setCalificationModal(false)
    navigate("/demandante/solicitudesRealizadas")
  }

  useEffect(() => {
    // colocar el initialstate del delete, accept
    return () => {
      dispatch(deleteOfferInitial());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (deleteOffer.state === FormState.Failure) {
      // Levantar modal
      setVisible(true);
    }
  }, [deleteOffer.state]);


  const Reasons = [
    "Precio accesible",
    "Experiencia en el trabajo",
    "Referencia",
    "Experiencia con el ofertante",
    "Otros",
  ];

  const rejectReasons = [
    "Precio equivocado",
    "Mal trabajo",
    "No hubo respuesta",
    "Actitudes sospechosas",
    "Otros",
  ];

  function onStarClick(nextValue: number, prevValue: number, name: string) {
    setCalification(nextValue);
  }

  return (
    <div>
      <div>
        {(offersState === FormState.Submitting ||
          getAceptOffer.state === FormState.Submitting ||
          deleteOffer.state === FormState.Submitting ||
          acceptOffer.state === FormState.Submitting) && (
          <React.Fragment>
            {new Array(3).fill(0).map((_, index) => (
              <LoadingCard key={`loading-offers-${index}`} />
            ))}
          </React.Fragment>
        )}
        {offersState === FormState.Success &&
          offers.length > 0 &&
          !request.OfertaAceptada && (
            <div>
              <h1 className="text-2xl text-deepBlue font-bold">Ofertas</h1>
              <div className="overflow-auto pt-5" style={{ maxHeight: 1100 }}>
                {offers.map((offer, index) => (
                  <OfferCard
                    offer={offer}
                    key={`offer-${index}`}
                    handleAccept={handleAccept}
                  />
                ))}
              </div>
            </div>
          )}
        {(getAceptOffer.state === FormState.Success ||
          getAceptOffer.state === FormState.Failure) &&
          (request.OfertaAceptada as PlaintiffOffer)?.Usuario && (
            <div>
              <h1 className="text-2xl text-deepBlue font-bold">
                Oferta Aceptada
              </h1>
              <div className="pt-5">
                <AcceptOfferCard
                  offer={request.OfertaAceptada as PlaintiffOffer}
                  handleReject={handleReject}
                  deleteOfferState={deleteOffer.state}
                  handleRate={handleRate}
                />
              </div>
            </div>
          )}

        {offersState === FormState.Success &&
          offers.length === 0 &&
          !request.OfertaAceptada && (
            <div>
              <h1 className="text-2xl text-deepBlue font-bold">Ofertas</h1>
              <div
                className="bg-primary-50 rounded-2xl p-10 flex flex-col place-items-center"
                style={{ height: 200 }}
              >
                <span className="text-2xl text-center text-gray-500 mb-7 mt-15">
                  No se han encontrado ofertas...{" "}
                </span>
                <XCircle size={40} className="text-gray-500" />
              </div>
            </div>
          )}

        {getAceptOffer.state === FormState.Failure && <p>{error}</p>}
        {offersState === FormState.Failure && <p>{error}</p>}
        {acceptOffer.state === FormState.Failure && <p>{error}</p>}
      </div>

      {/* Modal que maneja error de rechazo de ofert */}
      <Modal isVisible={visible} onClose={handleCloseErrorMessage} isDismiseble>
        <div className="bg-white rounded-lg h-full shadow-lg p-5 flex-col flex">
          <h1 className="text-lg text-center">
            {deleteOffer.error &&
            (deleteOffer.error as any).name === "RejectedError"
              ? `${(deleteOffer.error as any).message}`
              : "Error inesperado, intente mas tarde."}
          </h1>
          <Button
            text="Aceptar"
            paddingClassname="py-2 px-10"
            className="text-sm bg-primary-light mt-10 mx-auto"
            onClick={handleCloseErrorMessage}
          ></Button>
        </div>
      </Modal>
      {/* Modal Aceptar Oferta */}
      <div>
        <Modal isVisible={show} onClose={handleOnClose} isDismiseble>
          <div className="bg-white rounded-lg h-full shadow-lg p-5 content-between">
            <h1 className="text-lg mb-5 text-deepBlue font-bold">
              Debes indicar el motivo por el cual aceptas esta oferta
            </h1>
            <Select
              value={acceptReason}
              placeholder="Motivo para aceptar esta oferta"
              label="Motivo"
              onChange={(value) => {
                setAcceptReason(value);
              }}
            >
              {Reasons.map((razon, index) => {
                return (
                  <MenuItem
                    key={`razon-${index}`}
                    value={razon}
                    label={razon}
                  />
                );
              })}
            </Select>
            {acceptReason === "Otros" &&  (
              <TextField
                label="Indique el motivo"
                id="OtherReason"
                type="text"
                value={otherReason}
                onChange={(e) =>
                  setOtherReason(e.target.value)
                }
              />
            )}
            <div className="flex flex-row place-content-between mt-7">
              {acceptReason !== "" && (
                <Button
                  text="Aceptar"
                  paddingClassname="py-2 px-10"
                  className=" text-sm bg-primary-light"
                  onClick={() => handleAcceptOffer(selected)}
                />
              )}
              <Button
                text="Cancelar"
                paddingClassname="py-2 px-10"
                className="text-sm bg-redButton"
                onClick={handleOnClose}
              />
            </div>
          </div>
        </Modal>
      </div>
     {/*  Modal Rechazar Oferta */}
      <div>
        <Modal isVisible={showReject} onClose={handleOnClose} isDismiseble>
          <div className="bg-white rounded-lg h-full shadow-lg p-5 content-between">
            <h1 className="text-lg mb-5 text-deepBlue font-bold">
              Debes indicar el motivo por el cual rechazas esta oferta
            </h1>
            <Select
              value={rejectReason}
              placeholder="Motivo para rechazar esta oferta"
              label="Motivo"
              onChange={(value) => {
                setRejectReason(value);
              }}
            >
              {rejectReasons.map((razon, index) => {
                return (
                  <MenuItem
                    key={`razon-rechazo-${index}`}
                    value={razon}
                    label={razon}
                  />
                );
              })}
            </Select>
            {rejectReason === "Otros" &&  (
              <TextField
                label="Indique el motivo"
                id="OtherReason"
                type="text"
                value={otherReason}
                onChange={(e) =>
                  setOtherReason(e.target.value)
                }
              />
            )}
            <div className="flex flex-row place-content-between mt-7">
              {rejectReason !== "" && (
                <Button
                  text="Aceptar"
                  paddingClassname="py-2 px-10"
                  className=" text-sm bg-primary-light"
                  onClick={() => handleRejectOffer(selected)}
                />
              )}

              <Button
                text="Cancelar"
                paddingClassname="py-2 px-10"
                className="text-sm bg-redButton"
                onClick={handleOnClose}
              />
            </div>
          </div>
        </Modal>
      </div>
      {/* MODAL PARA CALIFICAR USUARIO */}
      <div>
        <Modal
          isVisible={calificationModal}
          onClose={handleOnClose}
          isDismiseble
        >
          <div className="bg-white rounded-lg h-full shadow-lg p-5 content-between">
            <h1 className="text-lg text-deepBlue font-bold">
              {`Califica tu experiencia con ${selected?.Usuario.Nombre} ${selected?.Usuario.Apellido}`}
            </h1>

            <div className="flex place-content-center" style={{ fontSize: 60 }}>
              <StarRatingComponent
                name="Calificar Usuario"
                starCount={5}
                starColor={"#009fda"}
                emptyStarColor={"#B6B6B6"}
                value={calification}
                editing
                onStarClick={onStarClick}
                onStarHover={onStarClick}
              />
            </div>
            <div className="flex place-content-center mt-2">
              <Button
                text="Calificar"
                className="text-sm bg-primary-light"
                onClick={() => handleRateUser(calification, selected, request.id)}
              />
            </div>
            {/* <div className="flex flex-row place-content-between mt-7">
              {rejectReason !== "" && (
                <Button
                  text="Aceptar"
                  paddingClassname="py-2 px-10"
                  className=" text-sm bg-primary-light"
                  onClick={() => handleRejectOffer(selected)}
                />
              )}

              <Button
                text="Cancelar"
                paddingClassname="py-2 px-10"
                className="text-sm bg-redButton"
                onClick={handleOnClose}
              />
            </div> */}
          </div>
        </Modal>
      </div>
    </div>
  );
};

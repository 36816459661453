import {
  addDoc,
  collection,
  doc,
  DocumentData,
  endAt,
  getDoc,
  getDocs,
  increment,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  serverTimestamp,
  startAt,
  updateDoc,
  limit,
  startAfter,
  endBefore,
} from "@firebase/firestore";
import Axios from "axios";
import {
  addHours,
  differenceInMinutes,
  isAfter,
  isBefore,
  minutesToHours,
} from "date-fns";
import { documentId, orderBy, where, writeBatch } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import {
  distanceBetween,
  geohashForLocation,
  geohashQueryBounds,
} from "geofire-common";
import { request } from "http";
import { BASE_URL } from "../../configs/constants";
import { firestore, storage } from "../../configs/firebase";
import { Action } from "../../models/action";
import { AppThunk } from "../../models/app-thunk";
import { Location } from "../../models/PlacesDetailsResponse";
import { PlaintiffOffer } from "../../models/PlaintiffOffer";
import { PlaintiffRequest } from "../../models/PlaintiffRequest";
import { RejectedError } from "../../models/RejectedError";
import * as types from "../types";

export function getRequests(): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.REQUESTS_SUBMMITING,
    });
    const user = getState().authReducer.user;

    try {
      const docRef = collection(firestore, "Solicitudes");
      const q = query(
        docRef,
        where("Archivada", "==", false),
        where("Eliminada", "==", false),
        where("Usuario.Id", "==", user?.id),
        limit(5),
        orderBy("FechaCreacion", "desc")
      );

      const [response, totalDocsResponse] = await Promise.all([
        getDocs(q),
        getDocs(q),
      ]);
      const requests = response.docs.map((x) => ({ ...x.data(), id: x.id }));

      dispatch({
        type: types.REQUESTS_SUCCESS,
        payload: {
          lastDoc: response.docs[response.docs.length - 1],
          requests,
          totalDocs: totalDocsResponse.size,
        },
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.REQUESTS_FAILURE,
        payload: error,
      });
    }
  };
}

export function getMoreRequests(total: number): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.REQUESTS_SUBMMITING,
    });
    const user = getState().authReducer.user;

    try {
      const docRef = collection(firestore, "Solicitudes");
      const q = query(
        docRef,
        where("Archivada", "==", false),
        where("Eliminada", "==", false),
        where("Usuario.Id", "==", user?.id),
        limit(total + 10),
        orderBy("FechaCreacion", "desc")
      );

      const [response, totalDocsResponse] = await Promise.all([
        getDocs(q),
        getDocs(q),
      ]);
      const requests = response.docs.map((x) => ({ ...x.data(), id: x.id }));

      dispatch({
        type: types.REQUESTS_SUCCESS,
        payload: {
          lastDoc: response.docs[response.docs.length - 1],
          requests,
          totalDocs: totalDocsResponse.size,
        },
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.REQUESTS_FAILURE,
        payload: error,
      });
    }
  };
}

export function getAllRequests(): AppThunk {
  return async (dispatch) => {
    dispatch({
      type: types.REQUESTS_SUBMMITING,
    });

    try {
      const docRef = collection(firestore, "Solicitudes");
      const q = query(
        docRef,
        where("OfertaAceptada", "==", null),
        where("Eliminada", "==", false),
        where("Bloqueado", "==", false),
        orderBy("FechaCreacion", "desc"),
        limit(20)
      );

      const [response, totalDocsResponse] = await Promise.all([
        getDocs(q),
        getDocs(q),
      ]);
      const requests = response.docs
        .map((x) => ({ ...(x.data() as PlaintiffRequest), id: x.id }))
        .filter(
          (x) =>
            x.FechaPremium === null ||
            isAfter(new Date(), x.FechaPremium.toDate())
        );
      /* .sort((a, b) => {
          if (a.FechaPremium && isBefore(new Date(), a.FechaPremium.toDate())) {
            return -1;
          } else {
            return 0;
          }
        }); */

      dispatch({
        type: types.REQUESTS_SUCCESS,
        payload: {
          lastDoc: response.docs[response.docs.length - 1],
          firstDoc: response.docs[0],
          requests,
          totalDocs: totalDocsResponse.size,
          order: "desc",
          orderBy: "FechaCreacion",
        },
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.REQUESTS_FAILURE,
        payload: error,
      });
    }
  };
}

interface OrderOptions {
  orderBy: string;
  order: any;
}

export function getAllRequestsOrder(options: OrderOptions): AppThunk {
  return async (dispatch) => {
    dispatch({
      type: types.REQUESTS_SUBMMITING,
    });

    try {
      const docRef = collection(firestore, "Solicitudes");
      const q = query(
        docRef,
        where("OfertaAceptada", "==", null),
        where("Eliminada", "==", false),
        where("Bloqueado", "==", false),
        orderBy(options.orderBy, options.order),
        limit(20)
      );

      const [response, totalDocsResponse] = await Promise.all([
        getDocs(q),
        getDocs(q),
      ]);
      const requests = response.docs
        .map((x) => ({ ...(x.data() as PlaintiffRequest), id: x.id }))
        .filter(
          (x) =>
            x.FechaPremium === null ||
            isAfter(new Date(), x.FechaPremium.toDate())
        );
      /* .sort((a, b) => {
          if (a.FechaPremium && isBefore(new Date(), a.FechaPremium.toDate())) {
            return -1;
          } else {
            return 0;
          }
        }); */

      dispatch({
        type: types.REQUESTS_SUCCESS,
        payload: {
          lastDoc: response.docs[response.docs.length - 1],
          firstDoc: response.docs[0],
          requests,
          totalDocs: totalDocsResponse.size,
          order: options.order,
          orderBy: options.orderBy,
        },
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.REQUESTS_FAILURE,
        payload: error,
      });
    }
  };
}


export function getPremiumRequests(): AppThunk {
  return async (dispatch) => {
    dispatch({
      type: types.REQUESTS_GET_PREMIUM_SUBMMITING,
    });

    try {
      const docRef = collection(firestore, "Solicitudes");
      const q = query(
        docRef,
        where("OfertaAceptada", "==", null),
        where("Eliminada", "==", false),
        where("Bloqueado", "==", false),
        where("FechaPremium", "!=", null),
        orderBy("FechaPremium", "desc")
      );

      const [response, totalDocsResponse] = await Promise.all([
        getDocs(q),
        getDocs(q),
      ]);

      const requestsPremium = response.docs
        .map((x) => ({
          ...(x.data() as PlaintiffRequest),
          id: x.id,
        }))
        .filter((x) => isBefore(new Date(), x.FechaPremium.toDate()));

      dispatch({
        type: types.REQUESTS_GET_PREMIUM_SUCCESS,
        payload: {
          lastDoc: response.docs[response.docs.length - 1],
          firstDoc: response.docs[0],
          requestsPremium,
        },
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.REQUESTS_GET_PREMIUM_FAILURE,
        payload: error,
      });
    }
  };
}

export function siguienteTodo(total: any, order:any, OrderBy:string): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.REQUESTS_SUBMMITING,
    });
    const lastDoc = getState().requestReducer.lastDoc || "";
    const firstDoc = getState().requestReducer.firstDoc || "";

    try {
      const docRef = collection(firestore, "Solicitudes");
      const q = query(
        docRef,
        where("OfertaAceptada", "==", null),
        where("Eliminada", "==", false),
        where("Bloqueado", "==", false),
        orderBy(OrderBy, order),
        /* startAfter(lastDoc), */
        limit(total + 10)
      );

      const [response, totalDocsResponse] = await Promise.all([
        getDocs(q),
        getDocs(q),
      ]);

      const requests = response.docs
        .map((x) => ({ ...(x.data() as PlaintiffRequest), id: x.id }))
        .filter(
          (x) =>
            x.FechaPremium === null ||
            isAfter(new Date(), x.FechaPremium.toDate())
        );

      dispatch({
        type: types.REQUESTS_SUCCESS,
        payload: {
          lastDoc: response.docs[response.docs.length - 1],
          firstDoc: response.docs[0],
          requests,
          totalDocs: totalDocsResponse.size,
        },
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.REQUESTS_FAILURE,
        payload: error,
      });
    }
  };
}

export function getAllRequestsByLocation(location: Location): AppThunk {
  return async (dispatch) => {
    dispatch({
      type: types.REQUESTS_SUBMMITING,
    });

    try {
      const center = [location.lat, location.lng];
      const radiusInM = 30 * 1000;

      const bounds = geohashQueryBounds(center, radiusInM);
      const promises: Promise<QuerySnapshot<DocumentData>>[] = [];
      for (const b of bounds) {
        const collectionRef = collection(firestore, "Solicitudes");
        const q = query(
          collectionRef,
          orderBy("Hash"),
          startAt(b[0]),
          endAt(b[1])
        );

        promises.push(getDocs(q));
      }

      const snapshots = await Promise.all(promises);
      const matchingDocs: QueryDocumentSnapshot<DocumentData>[] = [];
      for (const snap of snapshots) {
        for (const doc of snap.docs) {
          const lat = doc.data().Ubicacion.lat;
          const lng = doc.data().Ubicacion.lng;

          // We have to filter out a few false positives due to GeoHash
          // accuracy, but most will match
          const distanceInKm = distanceBetween([lat, lng], center);
          const distanceInM = distanceInKm * 1000;
          if (distanceInM <= radiusInM) {
            matchingDocs.push(doc);
          }
        }
      }

      const requests = matchingDocs
        .map((x) => ({ ...(x.data() as PlaintiffRequest), id: x.id }))
        .filter(
          (request) =>
            request.OfertaAceptada === null && request.Eliminada === false
        )
        .sort((a, b) => {
          if (a.FechaPremium && isBefore(new Date(), a.FechaPremium.toDate())) {
            return -1;
          } else {
            return 0;
          }
        });

      dispatch({
        type: types.REQUESTS_SUCCESS,
        payload: {
          lastDoc: undefined,
          requests,
          totalDocs: matchingDocs.length,
        },
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.REQUESTS_FAILURE,
        payload: error,
      });
    }
  };
}

export const getRequestFiltered = (
  filtro: any = {},
  order?: string,
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    const user = getState().authReducer.user;
    try {
      //["Region", "Comuna", "Categoria", "Fecha", "Precio"]
      if (
        filtro.filterBy === "Region" ||
        filtro.filterBy === "Comuna" ||
        filtro.filterBy === "Categoria"
      ) {
        let ref = collection(firestore, "Solicitudes");

        let q = query(
          ref,
          where(filtro.filterBy, "==", filtro.search.value),
          where("Usuario.Id", "==", user.id),
          where("Eliminada", "==", false)
        );
        const response = await getDocs(q);
        const rescRequest = response.docs.map((x: any) => ({
          ...x.data(),
          id: x.id,
        }));
        dispatch({
          type: types.REQUESTS_SUCCESS,
          payload: {
            requests: rescRequest,
            totalDocs: response.size,
            lastDoc: response.docs[response.docs.length - 1],
          },
        });
      } else if (filtro.filterBy === "Fecha") {
        let ref = collection(firestore, "Solicitudes");

        let q = query(
          ref,
          where("Usuario.Id", "==", user.id),
          orderBy("FechaCreacion")
        );
        if (filtro.FechaInicio) {
          const miliDate = Date.parse(filtro.FechaInicio);
          q = query(q, startAt(new Date(miliDate)));
        }
        if (filtro.FechaTermino) {
          const miliDate = Date.parse(filtro.FechaTermino);
          q = query(q, endAt(new Date(miliDate)));
        }
        const response = await getDocs(q);
        const rescRequest = response.docs.map((x: any) => ({
          ...x.data(),
          id: x.id,
        }));
        dispatch({
          type: types.REQUESTS_SUCCESS,
          payload: {
            requests: rescRequest,
            totalDocs: response.size,
            lastDoc: response.docs[response.docs.length - 1],
          },
        });
      } else if (filtro.filterBy === "Precio") {
        let ref = collection(firestore, "Solicitudes");

        let q = query(ref, where("Usuario.Id", "==", user.id));
        if (filtro.PrecioMin) {
          q = query(q, where("Precio", ">=", filtro.PrecioMin));
        }
        if (filtro.PrecioMax) {
          q = query(q, where("Precio", "<=", filtro.PrecioMax));
        }
        const response = await getDocs(q);
        const rescRequest = response.docs.map((x: any) => ({
          ...x.data(),
          id: x.id,
        }));
        dispatch({
          type: types.REQUESTS_SUCCESS,
          payload: {
            requests: rescRequest,
            totalDocs: response.size,
            lastDoc: response.docs[response.docs.length - 1],
          },
        });
      } else if (filtro.filterBy === "Archivadas") {
        let ref = collection(firestore, "Solicitudes");
        let q = query(
          ref,
          where("Archivada", "==", true),
          where("Usuario.Id", "==", user.id)
        );

        const response = await getDocs(q);
        const rescRequest = response.docs.map((x: any) => ({
          ...x.data(),
          id: x.id,
        }));
        dispatch({
          type: types.REQUESTS_SUCCESS,
          payload: {
            requests: rescRequest,
            totalDocs: response.size,
            lastDoc: response.docs[response.docs.length - 1],
          },
        });
      }
    } catch (error: any) {
      //console.log(error);
    } finally {
    }
  };
};

export const getRequestFilteredOfferer = (
  filtro: any = {},
  order?: string,
  limit: number = types.TABLE_LIMIT_DEFAULT
): AppThunk => {
  return async (dispatch, getState) => {
    try {
      //["Region", "Comuna", "Categoria", "Fecha", "Precio"]
      if (
        filtro.filterBy === "Region" ||
        filtro.filterBy === "Comuna" ||
        filtro.filterBy === "Categoria"
      ) {
        let ref = collection(firestore, "Solicitudes");

        let q = query(
          ref,
          where(filtro.filterBy, "==", filtro.search.value),
          where("OfertaAceptada", "==", null),
          where("Eliminada", "==", false)
        );
        const response = await getDocs(q);
        const rescRequest = response.docs.map((x: any) => ({
          ...x.data(),
          id: x.id,
        }));
        dispatch({
          type: types.REQUESTS_SUCCESS,
          payload: {
            requests: rescRequest,
            totalDocs: response.size,
            lastDoc: response.docs[response.docs.length - 1],
          },
        });
      } else if (filtro.filterBy === "Fecha") {
        let ref = collection(firestore, "Solicitudes");

        let q = query(
          ref,
          where("OfertaAceptada", "==", null),
          orderBy("FechaCreacion")
        );
        if (filtro.FechaInicio) {
          const miliDate = Date.parse(filtro.FechaInicio);
          q = query(q, startAt(new Date(miliDate)));
        }
        if (filtro.FechaTermino) {
          const miliDate = Date.parse(filtro.FechaTermino);
          q = query(q, endAt(new Date(miliDate)));
        }
        const response = await getDocs(q);
        const rescRequest = response.docs.map((x: any) => ({
          ...x.data(),
          id: x.id,
        }));
        dispatch({
          type: types.REQUESTS_SUCCESS,
          payload: {
            requests: rescRequest,
            totalDocs: response.size,
            lastDoc: response.docs[response.docs.length - 1],
          },
        });
      } else if (filtro.filterBy === "Precio") {
        let ref = collection(firestore, "Solicitudes");

        let q = query(ref);
        if (filtro.PrecioMin) {
          q = query(q, where("Precio", ">=", filtro.PrecioMin));
        }
        if (filtro.PrecioMax) {
          q = query(q, where("Precio", "<=", filtro.PrecioMax));
        }
        const response = await getDocs(q);
        const rescRequest = response.docs.map((x: any) => ({
          ...x.data(),
          id: x.id,
        }));
        dispatch({
          type: types.REQUESTS_SUCCESS,
          payload: {
            requests: rescRequest,
            totalDocs: response.size,
            lastDoc: response.docs[response.docs.length - 1],
          },
        });
      }
    } catch (error: any) {
      //console.log(error);
    } finally {
    }
  };
};

export function getOneRequest(requestId: string): AppThunk {
  return async (dispatch) => {
    dispatch({
      type: types.REQUESTS_GET_ONE_SUBMMITING,
    });

    try {
      const docRef = doc(firestore, "Solicitudes", requestId);

      const response = await getDoc(docRef);
      const request: any = { ...response.data(), id: response.id };
      dispatch({
        type: types.REQUESTS_GET_ONE_SUCCESS,
        payload: request,
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.REQUESTS_GET_ONE_FAILURE,
        payload: error,
      });
    }
  };
}

export function deleteImage(Images: Docs[]): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.REQUESTS_ADD_SUBMMITING,
    });

    const user = getState().authReducer.user;
    try {
      const docRef = collection(firestore, "Solicitudes");

      // eslint-disable-next-line
      const filesUrls: Docs[] = [];
      if (Images.length > 0) {
        for (let i = 0; i < Images.length; i++) {
          // eslint-disable-next-line
          const file = Images[i];

          // eslint-disable-next-line
          const storageRef = ref(
            storage,
            `${user.id}/requests/${docRef.id}/file_${Date.now()}`
          );
          //const uploadTask = await deleteObject(storageRef, file);
          //const publicUrl= await getDownloadURL(uploadTask.ref)
        }
      }
    } catch (error) {}
  };
}
interface Docs {
  URL: string;
  URI: string;
}
export function addRequest(request: any): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.REQUESTS_ADD_SUBMMITING,
    });

    const user = getState().authReducer.user;
    const lat = request.Ubicacion.lat;
    const lng = request.Ubicacion.lng;
    const geohash = geohashForLocation([lat, lng]);
    try {
      const docData = {
        ...request,
        Calificado: false,
        FechaCreacion: serverTimestamp(),
        Eliminada: false,
        Bloqueado: false,
        CantidadOfertas: 0,
        Archivada: false,
        OfertaAceptada: null,
        FechaPremium: null,
        Hash: geohash,
        Usuario: {
          Nombre: user.Nombre,
          Apellido: user.Apellido,
          Id: user.id,
        },
      };

      delete docData.Archivos;
      delete docData.Imagenes;
      delete docData.Destacar;
      const docRef = await addDoc(
        collection(firestore, "Solicitudes"),
        docData
      );

      const catRef = collection(firestore, "Categorias");
      const q = query(catRef, where("Nombre", "==", request.Categoria));
      const response = await getDocs(q);
      const cateId = response.docs.map((x: any) => ({ id: x.id }));
      const catContRef = doc(firestore, "Categorias", cateId[0].id);
      await updateDoc(catContRef, { CantidadSolicitudes: increment(1) });

      const filesUrls: Docs[] = [];
      if (request.Archivos.length > 0) {
        for (let i = 0; i < request.Archivos.length; i++) {
          const file = request.Archivos[i];

          const storageRef = ref(
            storage,
            `${user.id}/requests/${docRef.id}/file_${Date.now()}`
          );
          const uploadTask = await uploadBytes(storageRef, file);
          const publicUrl = await getDownloadURL(uploadTask.ref);

          filesUrls.push({
            URL: publicUrl,
            URI: storageRef.fullPath,
          });
        }
      }
      const imagesUrls: Docs[] = [];
      if (request.Imagenes.length > 0) {
        for (let i = 0; i < request.Imagenes.length; i++) {
          const file = request.Imagenes[i];

          const storageRef = ref(
            storage,
            `${user.id}/requests/${docRef.id}/imagen_${Date.now()}`
          );
          const uploadTask = await uploadBytes(storageRef, file);
          const publicUrl = await getDownloadURL(uploadTask.ref);

          imagesUrls.push({
            URL: publicUrl,
            URI: storageRef.fullPath,
          });
        }
      }

      const updateFileData: any = {};

      if (imagesUrls.length > 0) {
        updateFileData.Imagenes = imagesUrls;
      }
      if (filesUrls.length > 0) {
        updateFileData.Archivos = filesUrls;
      }

      updateDoc(docRef, updateFileData);

      dispatch({
        type: types.REQUESTS_ADD_SUCCESS,
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.REQUESTS_ADD_FAILURE,
        payload: error,
      });
    }
  };
}

export const requestAddInitial = (): Action => ({
  type: types.REQUESTS_ADD_INITIAL,
});

export const setSelectedRequest = (request: any): Action => ({
  type: types.REQUESTS_GET_ONE_SUCCESS,
  payload: request,
});

export function deleteRequest(solicitud: any): AppThunk {
  return async (dispatch) => {
    dispatch({
      type: types.REQUESTS_DELETE_SUBMMITING,
    });
    try {
      const requestRef = doc(firestore, "Solicitudes", solicitud.id);

      const batch = writeBatch(firestore);
      const collectionRef = collection(
        firestore,
        "Solicitudes",
        solicitud.id,
        "Ofertas"
      );
      const qu = query(collectionRef);
      const offerResponse = await getDocs(qu);
      offerResponse.forEach((doc) => {
        batch.update(doc.ref, { Eliminada: true });
      });
      const catRef = collection(firestore, "Categorias");
      const q = query(catRef, where("Nombre", "==", solicitud.Categoria));
      const response = await getDocs(q);
      const cateRef = response.docs[0].ref;
      await updateDoc(cateRef, {
        CantidadSolicitudes: increment(-1),
        CantidadOfertas:
          solicitud.CantidadOfertas > 0 &&
          increment(-solicitud.CantidadOfertas),
      });

      batch.update(requestRef, { Eliminada: true });
      await batch.commit();

      await Axios.post(
        BASE_URL + "app/solicitud/update",
        { requestId: solicitud.id, deleteOrUpdate: true },
        { headers: { "Content-Type": "application/json" } }
      );

      dispatch({
        type: types.REQUESTS_DELETE_SUCCESS,
        payload: solicitud,
      });
      dispatch(getRequests());
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.REQUESTS_DELETE_FAILURE,
        payload: error,
      });
    }
  };
}

export function getAcceptOffer(requestId: string, OfferId: any): AppThunk {
  return async (dispatch) => {
    dispatch({
      type: types.REQUESTS_GET_OFFER_SUBMMITING,
    });

    try {
      const requestRef = doc(
        firestore,
        "Solicitudes",
        requestId,
        "Ofertas",
        OfferId
      );

      const response = await getDoc(requestRef);

      const offer: PlaintiffOffer = {
        ...(response.data() as any),
        id: response.id,
      };
      const userResponse = await getDoc(
        doc(firestore, "Usuarios", offer.Usuario.Id)
      );
      const user: any = {
        ...userResponse.data(),
        Id: userResponse.id,
      };

      offer.Usuario = user;

      dispatch({
        type: types.REQUESTS_GET_OFFER_SUCCESS,
        payload: offer,
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.REQUESTS_GET_OFFER_FAILURE,
        payload: error,
      });
    }
  };
}

export function addAcceptedOffer(
  offer: PlaintiffOffer,
  request: PlaintiffRequest,
  reason: string
): AppThunk {
  return async (dispatch) => {
    dispatch({
      type: types.REQUEST_ACCEPT_OFFER_SUBMITTING,
    });

    try {
      const docData = {
        FechaTermino: offer.FechaTermino,
        FechaCreacion: serverTimestamp(),
        OfertaId: offer.id,
      };

      const docRef = doc(firestore, "Solicitudes", request.id);
      await updateDoc(docRef, { OfertaAceptada: docData });
      
      // Logica de bloqueo
      const batch = writeBatch(firestore);
      const collectionRef = collection(
        firestore,
        "Solicitudes",
        request.id,
        "Ofertas"
      );
      const q = query(collectionRef);
      const offerResponse = await getDocs(q);
      offerResponse.forEach((doc) => {
        if (doc.id === offer.id) {
          batch.update(doc.ref, { Aceptada: true });
        } else {
          batch.update(doc.ref, { Deshabilitado: true });
        }
      });
      await batch.commit();

      const userResponse = await getDoc(
        doc(firestore, "Usuarios", offer.Usuario.Id)
      );
      const user: any = {
        ...userResponse.data(),
        Id: userResponse.id,
      };

      offer.Usuario = user;
      // TODO: solo colocar los datos que se necesitan

      request.OfertaAceptada = offer;

      const catRef = collection(firestore, "Categorias");
      const qu = query(catRef, where("Nombre", "==", request.Categoria));
      const response = await getDocs(qu);
      const cateRef = response.docs[0].ref;
      await updateDoc(cateRef, {
        CantidadSolicitudes: increment(-1),
        CantidadOfertas:
          request.CantidadOfertas > 0 &&
          increment(-request.CantidadOfertas),
      });

      await Axios.post(
        BASE_URL + "app/oferta/accept",
        { requestId: request.id, userOfferId: offer.Usuario.Id, reason },
        { headers: { "Content-Type": "application/json" } }
      );
      dispatch({
        type: types.REQUEST_ACCEPT_OFFER_SUCCESS,
        payload: request,
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.REQUEST_ACCEPT_OFFER_FAILURE,
        payload: error,
      });
    }
  };
}

const rejectedTimes = [2, 5, 11, 25, 55, 122];
export function rejectedOffer(
  offer: PlaintiffOffer,
  request: PlaintiffRequest,
  rejectReason: string
): AppThunk {
  return async (dispatch) => {
    dispatch({
      type: types.REQUEST_REJECT_OFFER_SUBMITTING,
    });

    try {
      if (request.Rechazos) {
        const minutes = differenceInMinutes(
          new Date(),
          request.Rechazos.FechaRechazo.toDate()
        );
        const rejectedTimeIndex =
          request.Rechazos.Cantidad >= rejectedTimes.length
            ? rejectedTimes.length - 1
            : request.Rechazos.Cantidad - 1;

        if (minutesToHours(minutes) < rejectedTimes[rejectedTimeIndex]) {
          const timeLeft = differenceInMinutes(
            addHours(
              request.Rechazos.FechaRechazo.toDate(),
              rejectedTimes[rejectedTimeIndex]
            ),
            new Date()
          );
          const error = new RejectedError(
            request.Rechazos.Cantidad,
            timeLeft,
            `Oferta rechazada ${
              request.Rechazos.Cantidad
            } veces. Debe esperar ${
              timeLeft >= 60
                ? minutesToHours(timeLeft) + " horas"
                : timeLeft + " minutos"
            } para volver a rechazar.`
          );
          throw error;
        }
      }
      const docRef = doc(firestore, "Solicitudes", request.id);

      await updateDoc(docRef, {
        OfertaAceptada: null,
        "Rechazos.Cantidad": increment(1),
        "Rechazos.FechaRechazo": serverTimestamp(),
      });
      const requestResponse = await getDoc(docRef);

      const newRequest: any = {
        ...requestResponse.data(),
        id: requestResponse.id,
      };
      // Logica de bloqueo
      const batch = writeBatch(firestore);
      const collectionRef = collection(
        firestore,
        "Solicitudes",
        request.id,
        "Ofertas"
      );
      const q = query(collectionRef, where(documentId(), "==", offer.id));
      const offerResponse = await getDocs(q);
      offerResponse.forEach((element) => {
        batch.update(element.ref, { Deshabilitado: false, Aceptada: false });
      });
      await batch.commit();

      const catRef = collection(firestore, "Categorias");
      const qu = query(catRef, where("Nombre", "==", request.Categoria));
      const response = await getDocs(qu);
      const cateRef = response.docs[0].ref;
      await updateDoc(cateRef, {
        CantidadSolicitudes: increment(1),
        CantidadOfertas:
          request.CantidadOfertas > 0 &&
          increment(request.CantidadOfertas),
      });
      await Axios.post(
        BASE_URL + "app/oferta/reject",
        { requestId: request.id, userOfferId: offer.Usuario.Id, rejectReason },
        { headers: { "Content-Type": "application/json" } }
      );

      dispatch({
        type: types.REQUEST_REJECT_OFFER_SUCCESS,
        payload: newRequest,
      });
    } catch (error: any) {
      dispatch({
        type: types.REQUEST_REJECT_OFFER_FAILURE,
        payload: error,
      });
    }
  };
}

export const deleteOfferInitial = (): Action => ({
  type: types.REQUEST_REJECT_OFFER_INITIAL,
});

export function editRequest(request: any): AppThunk {
  return async (dispatch) => {
    dispatch({
      type: types.REQUEST_EDIT_SUBMITTING,
    });

    try {
      const requestRef = doc(firestore, "Solicitudes", request.id);
      const prevRequestSnap = (await getDoc(requestRef)).data();

      const docData = {
        Titulo: request.Titulo,
        Region: request.Region,
        Comuna: request.Comuna,
        Categoria: request.Categoria.value,
        Descripcion: request.Descripcion,
        Direccion: request.Direccion,
        Precio: request.Precio,
        Unidad: {
          Cantidad: request.Unidad.Cantidad,
          Tipo: request.Unidad.Tipo,
        },
        FechaInicio: request.FechaInicio,
        FechaTermino: request.FechaTermino,
        FechaActualizacion: serverTimestamp(),
      };

      updateDoc(requestRef, docData);

      const imagesUrls: Docs[] = [];
      if (request.Imagenes && Array.isArray(request.Imagenes)) {
        for (const img of request.Imagenes) {
          if (typeof img === "object") {
            if (img instanceof File) {
              const storageRef = ref(
                storage,
                `${request.Usuario.Id}/requests/${
                  request.id
                }/imagen_${Date.now()}`
              );
              const uploadTask = await uploadBytes(storageRef, img);
              const publicUrl = await getDownloadURL(uploadTask.ref);

              imagesUrls.push({
                URL: publicUrl,
                URI: storageRef.fullPath,
              });
            } else {
              imagesUrls.push(img);
            }
          }
        }
      }

      const updateFileData: any = {};

      if (imagesUrls.length > 0) {
        updateFileData.Imagenes = imagesUrls;
      }

      updateDoc(requestRef, updateFileData);

      await Axios.post(
        BASE_URL + "app/solicitud/update",
        { requestId: request.id, deleteOrUpdate: false },
        { headers: { "Content-Type": "application/json" } }
      );
      const batch = writeBatch(firestore);

      if (docData.Categoria !== prevRequestSnap?.Categoria) {
        const offerRef = collection(
          firestore,
          "Solicitudes",
          request.id,
          "Ofertas"
        );
        const offerResponse = await getDocs(offerRef);
        offerResponse.forEach((doc) => {
          batch.update(doc.ref, { Categoria: docData.Categoria });
        });
        await batch.commit();

        const catRef = collection(firestore, "Categorias");
        const q = query(catRef, where("Nombre", "==", docData.Categoria));
        const response = await getDocs(q);
        const cateRef = response.docs[0].ref;
        await updateDoc(cateRef, {
          CantidadSolicitudes: increment(1),
          CantidadOfertas: increment(offerResponse.size),
        });
        const queryNew = query(
          catRef,
          where("Nombre", "==", prevRequestSnap?.Categoria)
        );
        const newResponse = await getDocs(queryNew);
        const cateNewRef = newResponse.docs[0].ref;
        await updateDoc(cateNewRef, {
          CantidadSolicitudes: increment(-1),
          CantidadOfertas: increment(-offerResponse.size),
        });
      }

      dispatch({
        type: types.REQUEST_EDIT_SUCCESS,
        payload: {
          ...request,
        },
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.REQUEST_EDIT_FAILURE,
        payload: error,
      });
    }
  };
}

export const editRequestInitial = (): Action => ({
  type: types.REQUEST_EDIT_INITIAL,
});

export const isOffered = (requestId: string, user?: any): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.REQUEST_OFFERED_SUBMITTING,
    });
    let actualUser = user ? user : getState().authReducer.user;
    try {
      const offersRef = collection(
        firestore,
        "Solicitudes",
        requestId,
        "Ofertas"
      );
      const q = query(
        offersRef,
        where("Usuario.Id", "==", actualUser.id),
        where("Eliminada", "==", false)
      );

      const response = await getDocs(q);
      if (response.size !== 1) {
        dispatch({
          type: types.REQUEST_OFFERED_SUCCESS,
          payload: null,
        });
        return;
      } else {
        const offer = {
          ...response.docs[0].data(),
          id: response.docs[0].id,
          requestRef: response.docs[0].ref.parent.parent?.id,
          Usuario: {
            Nombre: actualUser.Nombre,
            Apellido: actualUser.Apellido,
            Id: actualUser.id,
            Comuna: actualUser.Comuna,
            Region: actualUser.Region,
            Direccion: actualUser.Direccion,
            Telefono: actualUser.Telefono,
            Email: actualUser.Email,
          },
        };
        dispatch({
          type: types.REQUEST_OFFERED_SUCCESS,
          payload: offer,
        });
        return;
      }
    } catch (error: any) {
      dispatch({
        type: types.REQUEST_OFFERED_FAILURE,
        payload: error,
      });
    }
  };
};

export const setSearchInitial = (): Action => ({
  type: types.REQUEST_OFFERED_INITIAL,
});

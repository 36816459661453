import { NONAME } from "dns";
import { useFormik } from "formik";
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select, { StylesConfig } from "react-select";
import * as yup from "yup";
import { CustomSelect } from "../../../components/CustomSelect";
import { TextField } from "../../../components/TextField";
import { addAlert, editAlert, formStateInitial, } from "../../../redux/actions/alert.action";
import { getCategories } from "../../../redux/actions/categories.action";
import { CategoriesState } from "../../../redux/reducers/categories.reducer";
import { RequestsState } from "../../../redux/reducers/requests.reducer";
import { RootState } from "../../../redux/reducers/root.reducer";
import regionesComunas from "../../../assets/RegionesComunas.json"
import { Button } from "../../../components/Button";
import { FormState } from "../../../models/form_state";
import { AlertState } from "../../../redux/reducers/alert.reducer";

export const OffererEditAlert = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getCategories());
    // eslint-disable-next-line
  }, []);

  const { categories } = useSelector<RootState, CategoriesState>(
    (state) => state.categoriesReducer
  );

  const {edit:{selectedAlert, state:editState}} = useSelector<RootState, AlertState>(
    (state) => state.alertReducer
  )


  

  const {
    add: { state },
  } = useSelector<RootState, RequestsState>((state) => state.requestReducer);

  useEffect(() => {
    if(editState === FormState.Success) {
      dispatch(formStateInitial())
      navigate("/oferente/alertas")
       /* window.location.reload() */
    }
  }, [editState])

 

  const { handleSubmit, values, handleChange, touched, setFieldValue, errors } =
    useFormik({
      initialValues: {
        Titulo: selectedAlert.Titulo,
        Region: selectedAlert.Region,
        Comuna: selectedAlert.Comuna,
        Categorias: "",
      },
      onSubmit: (values) => {
        
        dispatch(editAlert({...values,Id:selectedAlert.Id}));
      },
       validationSchema: yup.object({
        Titulo: yup.string().required("Debe ingresar titulo"),
        Region: yup.string().required("Debe ingresar región"),
        Comuna: yup.string().required("Debe ingresar comuna"),
        Categorias: yup.array().required("Debe ingresar categoria"),
      }), 
    });

    

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="py-10 px-8 xl:px-10 lg:container lg:mx-auto"
    >
      <Helmet title="Crear Alerta" />
      <h1 className=" text-3xl font-bold text-deepBlue mb-5 ">Editar Alerta</h1>
      <motion.div>
        <form
          className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full md:mx-5"
          onSubmit={handleSubmit}
        >
          <div className="">
            <TextField
              label="Titulo"
              placeholder="Titulo"
              id="Titulo"
              divClassName="mb-4"
              value={values.Titulo}
              onChange={handleChange}
              helperText={touched.Titulo && errors.Titulo}
              error={touched.Titulo && Boolean(errors.Titulo)}
            />

            <CustomSelect
              label="Categorias"
              id="Categorias"
              defaultInputValue={selectedAlert.Categorias}
              options={categories.map((categorie) => ({
                label: categorie.Nombre,
                value: categorie.Nombre,
              }))}
              isMulti
              placeholder="Selecciona 3 categorias max."
              onChange={(value) => {
                setFieldValue("Categorias", value);
              }}
              value={values.Categorias}
              isSearchable
              autoFocus
              isClearable
              isOptionDisabled={() => values.Categorias.length>=3 }
            />
          </div>
          <div>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <CustomSelect
                  id="Region"
                  label="Región"
                  defaultInputValue= {selectedAlert.Region}
                  placeholder="Seleccionar Región"
                  options={regionesComunas.regiones.map((region) => ({
                    label: region.region,
                    value: region.region,
                  }))}
                  isSearchable
                  onChange={(value: any) => {
                    setFieldValue("Region", value.value);
                  }}
                />
              </div>
              <div>
                <CustomSelect
                  id="Comuna"
                  label="Comuna"
                  className="mt-0"
                  placeholder="Seleccionar Comuna"
                  defaultInputValue={selectedAlert.Comuna}
                  isSearchable
                  options={
                    values.Region
                      ? regionesComunas.regiones
                          .find((x) => x.region === values.Region)
                          ?.comunas.map((comuna) => ({
                            label: comuna,
                            value: comuna,
                          }))
                      : []
                  }
                  onChange={(value: any) => {
                    setFieldValue("Comuna", value.value);
                  }}
                />
              </div>
              <div className="justify-center">
                <Button
                  type="submit"
                  text="Edita Alerta"
                  color="secondary"
                  fullWidth
                  className="mt-10"
                  isLoading={editState === FormState.Submitting}
                  disabled={state === FormState.Submitting}
                />
              </div>
            </div>
          </div>
        </form>
      </motion.div>
    </motion.main>
  );
};

import * as types from "../types";
import { AppThunk } from "../../models/app-thunk";
import { Action } from "../../models/action";
import { firestore } from "../../configs/firebase";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getDoc,
  query,
  serverTimestamp,
  updateDoc,
  where,
  increment,
  writeBatch,
} from "firebase/firestore";
import { PlaintiffOffer } from "../../models/PlaintiffOffer";

/**
 * Calificacion de demandante a oferente
 */
export function addCalificationOfferer(
  calification: number,
  offer: PlaintiffOffer,
  requestId: string
): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.CALIFICATION_ADD_SUBMITTING,
    });
    const actualUser = getState().authReducer.user;
    try {
      const myRateSnaps = await getDocs(
        query(
          collection(firestore, "Solicitudes", requestId, "Calificaciones"),
          where("Calificador.Id", "==", actualUser.id)
        )
      );

      if (!myRateSnaps.empty) {
        throw new Error("Ya existe calificación, para esta publicación.");
      }

      const docData = {
        Calificacion: calification,
        FechaCreacion: serverTimestamp(),
        Calificado: {
          Id: offer.Usuario.Id,
          Nombre: offer.Usuario.Nombre,
          Apellido: offer.Usuario.Apellido,
        },
        Calificador: {
          Id: actualUser.id,
          Nombre: actualUser.Nombre,
          Apellido: actualUser.Apellido,
        },
      };

      await addDoc(
        collection(firestore, "Solicitudes", requestId, "Calificaciones"),
        docData
      );
      const requestRef = doc(firestore, "Solicitudes", requestId);
      await updateDoc(requestRef, {
        Archivada: true,
        CantidadOfertas: increment(-1),
      });

      const offerRef = doc(
        firestore,
        "Solicitudes",
        requestId,
        "Ofertas",
        offer.id
      );

      await updateDoc(offerRef, {
        Calificado: true,
      });
      const ofertaSnap = await getDoc(offerRef);
      const ofertaData = ofertaSnap.data();
      const catRef = collection(firestore, "Categorias");
      const q = query(catRef, where("Nombre", "==", ofertaData?.Categoria));
      const response = await getDocs(q);
      const cateRef = response.docs[0].ref;
      await updateDoc(cateRef, { CantidadOfertas: increment(-1) });
      dispatch({
        type: types.CALIFICATION_ADD_SUCCESS,
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.CALIFICATION_ADD_FAILURE,
        payload: error,
      });
    }
  };
}

/**
 * Calificacion de oferente a demandante
 */
export function addCalificationPlaintiff(
  calification: number,
  userRequest: any,
  requestId: string,
  offerId: string
): AppThunk {
  return async (dispatch, getState) => {
    dispatch({
      type: types.CALIFICATION_ADD_SUBMITTING,
    });
    const actualUser = getState().authReducer.user;
    try {
      const myRateSnaps = await getDocs(
        query(
          collection(firestore, "Solicitudes", requestId, "Calificaciones"),
          where("Calificador.Id", "==", actualUser.id)
        )
      );

      if (!myRateSnaps.empty) {
        throw new Error("Ya existe calificación, para esta publicación.");
      }

      const docData = {
        Calificacion: calification,
        FechaCreacion: serverTimestamp(),
        Calificado: {
          Id: userRequest.Id,
          Nombre: userRequest.Nombre,
          Apellido: userRequest.Apellido,
        },
        Calificador: {
          Id: actualUser.id,
          Nombre: actualUser.Nombre,
          Apellido: actualUser.Apellido,
        },
      };

      await addDoc(
        collection(firestore, "Solicitudes", requestId, "Calificaciones"),
        docData
      );

      const requestRef = doc(firestore, "Solicitudes", requestId);
      await updateDoc(requestRef, {
        Calificado: true,
      });

      const offerRef = doc(
        firestore,
        "Solicitudes",
        requestId,
        "Ofertas",
        offerId
      );

      const solicitudSnap = await getDoc(requestRef);

      const solicitudData = solicitudSnap.data();

      const catRef = collection(firestore, "Categorias");
      const q = query(catRef, where("Nombre", "==", solicitudData?.Categoria));
      const response = await getDocs(q);
      const cateRef = response.docs[0].ref;
      await updateDoc(cateRef, {
        CantidadSolicitudes: increment(-1),
        CantidadOfertas:
          solicitudData?.CantidadOfertas > 0 &&
          increment(-solicitudData?.CantidadOfertas),
      });

      await updateDoc(offerRef, {
        Archivada: true,
      });

      dispatch({
        type: types.CALIFICATION_ADD_SUCCESS,
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.CALIFICATION_ADD_FAILURE,
        payload: error,
      });
    }
  };
}

export function calificationId(userId: string): AppThunk {
  return async (dispatch) => {
    try {
      const docRef = doc(firestore, "Usuarios", userId);

      const response = await getDoc(docRef);
      const calificacion = response.data()?.Calificacion;

      dispatch({
        type: types.CALIFICATION_ID_SUCCESS,
        payload: calificacion,
      });
    } catch (error: any) {
      //console.log(error);
      dispatch({
        type: types.CALIFICATION_ID_FAILURE,
        payload: error,
      });
    }
  };
}

import { addDays, format, isAfter } from "date-fns";
import React, { useEffect } from "react";
import { Search as SearchIcon } from "react-feather";
import { motion } from "framer-motion";
import { IconButton } from "../../../../components/IconButton";
import { PlaintiffTransaction } from "../../../../models/PlaintiffTransaction";

interface Props {
  transaction: PlaintiffTransaction;
  onSeeDetails: (transaction: PlaintiffTransaction) => void;
}

export const TransactionCard: React.FC<Props> = ({
  transaction,
  onSeeDetails,
}) => {
  useEffect(() => {}, [transaction]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={
        "h-80 bg-white shadow-md rounded-xl overflow-hidden hover:shadow-xl flex justify-between flex-col"
      }
      onMouseEnter={() => {}}
      onMouseLeave={() => {}}
    >
      <div className="w-full h-1/2 relative">
        <img
          src={
            transaction.Solicitud.Imagenes && transaction.Solicitud.Imagenes[0]
              ? transaction.Solicitud.Imagenes[0].URL
              : "/assets/img/sin_imagen.jpg"
          }
          className=" w-full h-full bg-gray-300 object-cover"
        />
      </div>
      <div className="w-full h-2/5 flex flex-col">
        <h6 className="text-lg text-deepBlue font-bold mx-4 pt-1">
          {transaction.Solicitud.Nombre}
        </h6>
        <div className="mx-4">
          <p
            className="font-bold text-secondary"
            style={{ fontSize: "0.875rem" }}
          >
            {/* {`Aviso #${transaction.Solicitud.Id}`} */}
            {`Estado: ${transaction.Estado}`}
            <p>{`Precio: $${transaction.Precio}`}</p>
            
            
          </p>
          {isAfter(
            transaction.FechaCreacion.toDate(),
            transaction.FechaPremium.toDate()
          ) ? (
            <p className="text-base text-red-600 py-1">
              {`Vigencia: ${format(
                transaction.FechaCreacion.toDate(),
                "dd/MM/yyyy"
              )} - ${format(transaction.FechaPremium.toDate(), "dd/MM/yyyy")}`}
              &nbsp;
              <span className="text-red-600 font-bold">(vencida)</span>
            </p>
          ) : (
            <p className="text-sm sm:invisible md:visible text-gray-400 py-1">
              {`Vigencia: ${format(
                transaction.FechaCreacion.toDate(),
                "dd/MM/yyyy"
              )} - ${format(transaction.FechaPremium.toDate(), "dd/MM/yyyy")}`}
            </p>
          )}
        </div>
      </div>
      <div className="h-1/6 w-full flex justify-around bg-primary bg-opacity-60 mt-auto">
        <IconButton onClick={() => onSeeDetails(transaction)}>
          <SearchIcon className="text-secondary" />
        </IconButton>
      </div>
    </motion.div>
  );
};

export const units = [
  "milímetro",
  "centímetro",
  "pulgada",
  "metro",
  "kilómetro",
  "metro cuadrado",
  "hectárea",
  "kilómetro cuadrado",
  "centímetro cúbico",
  "metro cúbico",
  "mililitro",
  "litro",
  "gramo",
  "kilogramo",
  "tonelada",
  "minuto",
  "hora",
  "kilovatio-hora",
  "día",
  "unidad",
  "mes",
  "año",
];

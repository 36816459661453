import * as React from "react"
import { SVGProps } from "react"

const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={70.68}
    height={70.68}
    viewBox="0 0 70.68 70.68"
    {...props}
  >
    <circle
      data-name="Elipse 9 copia 12"
      cx={35.34}
      cy={35.34}
      style={{
        fill: "#3578ea",
      }}
      r={35.34}
    />
    <image
      data-name="Objeto inteligente vectorial"
      x={29.09}
      y={19.84}
      width={15}
      height={29}
      xlinkHref="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAdCAYAAAB1yDbaAAAAxklEQVQ4je2STw7BQByFH5qoJYk1F3ARLuA6nMTWys4N3MBaLSwkNq2tpk9GCPl50040dv2SWfT1fdPOH5AsG1OSG5JnkgXfZCQTn9ghuWIFPnlZJTparmwYADgB6NkXlkhksxIxBZC9HpQ88YhzAOvPoC1KI5HtreiTuyK7qF9RcnBPhXnghI8NG5ssFr1Y9K7unL8OOpBF6JoVhzrysY6c/Lrmm9tE9+W+GVtR3pnOEEARPS+7ndWSi17wDZM0ciP/UQZwB0WQvpaHvmk7AAAAAElFTkSuQmCC"
    />
  </svg>
)

export default FacebookIcon
